import { gql } from "@apollo/client";
import {
  PaginatedListMetaNoNestingFragment,
  TaxGroupNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const QUERY_TAX_GROUP = gql`
  ${TaxGroupNoNestingFragment}
  query QueryTaxGroup($id: ID!) {
    taxGroup(id: $id) {
      ...TaxGroupNoNesting
    }
  }
`;

export const QUERY_TAX_GROUP_LIST = gql`
  ${PaginatedListMetaNoNestingFragment}
  ${TaxGroupNoNestingFragment}
  query QueryTaxGroupList($options: TaxGroupListOptions) {
    taxGroups(options: $options) {
      items {
        ...TaxGroupNoNesting
      }
      meta {
        ...PaginatedListMetaNoNesting
      }
    }
  }
`;

export const QUERY_TAX_GROUP_OPTIONS_LIST = gql`
  ${PaginatedListMetaNoNestingFragment}
  query QueryTaxGroupOptionsList($options: TaxGroupListOptions) {
    taxGroups(options: $options) {
      items {
        id
        name
      }
      meta {
        ...PaginatedListMetaNoNesting
      }
    }
  }
`;

export const MUTATION_UPDATE_TAX_GROUP = gql`
  ${TaxGroupNoNestingFragment}
  mutation UpdateTaxGroup($input: UpdateTaxGroupInput!) {
    updateTaxGroup(input: $input) {
      ...taxGroupNoNesting
    }
  }
`;
