import { gql } from "@apollo/client";
import {
  SupplierNoNestingFragment,
  PaginatedListMetaNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const QUERY_SUPPLIER = gql`
  ${SupplierNoNestingFragment}
  query QuerySupplier($id: ID!) {
    supplier(id: $id) {
      ...SupplierNoNesting
    }
  }
`;

export const QUERY_SUPPLIER_LIST = gql`
  ${PaginatedListMetaNoNestingFragment}
  ${SupplierNoNestingFragment}
  query QuerySupplierList($options: SupplierListOptions) {
    suppliers(options: $options) {
      items {
        ...SupplierNoNesting
      }
      meta {
        ...PaginatedListMetaNoNesting
      }
    }
  }
`;

export const MUTATION_UPDATE_SUPPLIER = gql`
  ${SupplierNoNestingFragment}
  mutation UpdateSupplier($input: UpdateSupplierInput!) {
    updateSupplier(input: $input) {
      ...SupplierNoNesting
    }
  }
`;
