import React from "react";
import { usePaginatedList } from "../../../../../utilities/paginated-list";
import { useSearchQueryProvider } from "../../../../../utilities/search/hooks";
import { useDynamicSort } from "../../../../../utilities/sort";
import { useScheduler } from "../../../../../utilities/use-scheduler";
import { QUERY_PRODUCT_LIST } from "../../../utils";
import {
  Product,
  ProductSortParameter,
} from "@towersystems/roam-common/lib/generated-types";
import { UseAllProductsControllerReturn } from "./types";

export const useAllProductsController = (): UseAllProductsControllerReturn => {
  const search = useSearchQueryProvider();
  const { schedule } = useScheduler(500);

  const { dynamicSort, toggleSort } = useDynamicSort<ProductSortParameter>();

  const paginatedList = usePaginatedList<Product>({
    query: QUERY_PRODUCT_LIST as any,
    resultKey: "products",
    variables: {
      sort: { updatedAt: "DESC", ...(dynamicSort || {}) },
      filter: { term: { contains: search.query } },
    },
  });

  const handleDynamicSortChange = React.useCallback(() => {
    paginatedList.setSort({
      updatedAt: "DESC",
      ...(dynamicSort || {}),
    });
  }, [dynamicSort]);

  React.useEffect(() => {
    handleOnTermInputChange();
  }, [search.query]);

  React.useEffect(() => {
    handleDynamicSortChange();
  }, [dynamicSort]);

  const handleOnTermInputChange = React.useCallback(() => {
    schedule(() =>
      paginatedList.setVariables({
        filter: { term: { contains: search.query } },
      })
    );
  }, [search.query]);

  return {
    paginatedList,
    dynamicSort,
    toggleSort,
  };
};
