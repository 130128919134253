import { experimental_extendTheme } from "@mui/material";

import { red } from "@mui/material/colors";
import GilmerRegular from "../../assets/fonts/Gilmer-Regular.ttf";
import { lightColors } from "@towersystems/roam-common/lib/tokens";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    tile: true;
    posTile: true;
  }
  interface ButtonPropsColorOverrides {
    destructive: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    subdued: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subdued?: React.CSSProperties;
  }
}
// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subdued: true;
  }
}

// MUI doesn't automatically pick custom palette light/dark variants so we have to do it manually
// better to do it once here than in every theme consumer
export const paletteExtendedLight = {
  subduedPaper: "hsl(237, 30%, 96%)", // grey saturated with primary colour
  dynamicBackground: "#f0f0f0",
  border: {
    standard: "#dadbdd",
    pronounced: "#dadbdd",
  },
};

export const paletteExtendedDark = {
  subduedPaper: "hsl(0, 0%, 16%)",
  dynamicBackground: "hsl(0, 0%, 13%)",
  border: {
    standard: "hsl(0, 0%, 12%)",
    pronounced: "hsl(0, 0%, 2%)",
  },
};

export const theme = experimental_extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          default: "#f0f0f0",
        },
        primary: {
          main: lightColors.primary,
        },
        secondary: {
          main: "#dadbdd",
        },
        error: {
          main: red.A400,
        },
        destructive: {
          main: red.A400,
          badge: "#f1c8c6",
        },
        success: {
          main: "#2F6246",
          badge: "#7ed5a0",
        },
        ...paletteExtendedLight,
      },
    },
  },
  typography: {
    fontFamily: `"Gilmer", "Roboto", "Helvetica", "Arial", "sans-serif"`,
    h1: {
      fontSize: "28px",
    },
    subdued: {
      color: "#6b6b6b",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Gilmer';
          src: url(${GilmerRegular}) format('truetype');
        }
      `,
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.MuiListItemButton-root.Mui-selected": {
            color: lightColors.primary,
          },
          "&.MuiListItemButton-root.Mui-selected .MuiListItemIcon-root": {
            color: lightColors.primary,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.CardSearchInput": {
            background: "transparent",
            borderWidth: 0,
            height: 40,
          },
          "& .MuiInputBase-root": {
            paddingLeft: 10,
          },
          "& .MuiInputBase-root::before": {
            borderColor: "#dadbdd",
          },
          "& .MuiInputBase-input": {
            height: 40,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: "80px !important",
        },
      },
      variants: [
        {
          props: { variant: "tile", color: "primary" },
          style: {
            background: "#dadbdd",
          },
        },
        {
          props: { variant: "posTile" },
          style: {
            background: "#dadbdd",
            height: 130,
            width: 230,
            borderRadius: 18,
            fontSize: 18,
          },
        },
      ],
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            border: "2px solid #dadbdd",
            borderRadius: 8,
            background: "white",
            padding: 0,
          },
        },
      ],
    },
  },
});
