// tslint:disable
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Currency: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  HexColorCode: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AccessTokenSession = EntityNode & {
  __typename?: 'AccessTokenSession';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  expires: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invalidated: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type AddItemToInventoryMovementInput = {
  counted?: InputMaybe<Scalars['Float']['input']>;
  productId: Scalars['ID']['input'];
  /** Manually transition inventory movement item to proccessed */
  status?: InputMaybe<InventoryMovementItemStatus>;
};

export type AddItemToStocktakeInput = {
  counted?: InputMaybe<Scalars['Float']['input']>;
  productId: Scalars['ID']['input'];
  /** Manually transition inventory movement item to proccessed */
  status?: InputMaybe<StocktakeItemStatus>;
};

export type AddToParentProductInput = {
  id: Scalars['ID']['input'];
  /** The options for the variant product, must match the parent product options */
  selectedOptions?: InputMaybe<Array<SelectedOptionInput>>;
  variantParentId: Scalars['ID']['input'];
};

export type AdjustStockItemInput = {
  adjustBy: Scalars['Int']['input'];
  productId: Scalars['ID']['input'];
};

export type AnalyticProductMetaDataItem = {
  __typename?: 'AnalyticProductMetaDataItem';
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type AuthenticationInput = {
  native?: InputMaybe<TalinkStaffAuthInputType>;
  talink?: InputMaybe<TalinkAuthInputType>;
  talink_token?: InputMaybe<TalinkTokenAuthInputType>;
};

export type AuthenticationMethod = EntityNode & {
  __typename?: 'AuthenticationMethod';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  strategy: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AuthenticationMfaInput = {
  mfaCode: Scalars['String']['input'];
  strategy: MfaCodeStrategy;
};

export type AuthenticationResult = {
  __typename?: 'AuthenticationResult';
  talinkId: Scalars['ID']['output'];
  token: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type AuthenticationResultV2 = CurrentUserAndTenant | InvalidCredentialsError | InvalidMfaCodeError | MfaRequiredError | NotVerifiedError;

export type BackgroundRemoverInsufficientCreditsError = ErrorResult & {
  __typename?: 'BackgroundRemoverInsufficientCreditsError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type BackgroundRemoverNotConfiguredError = ErrorResult & {
  __typename?: 'BackgroundRemoverNotConfiguredError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type BarcodeLookupInput = {
  barcode?: InputMaybe<Scalars['String']['input']>;
};

export type BarcodeLookupResult = InsufficientBarcodeLookupCreditsError | InvalidBarcodeLookupCredentialsError | ProductFoundResult | ProductNotFoundResult;

export type Base64ImageUploadInput = {
  base64: Scalars['String']['input'];
  imageName: Scalars['String']['input'];
};

export enum BgRemovalStrategy {
  RemovalAiStrategy = 'RemovalAiStrategy',
  RemovalBgStrategy = 'RemovalBgStrategy'
}

/** Operators for filtering on a list of Boolean fields */
export type BooleanListOperators = {
  inList: Array<InputMaybe<Scalars['Boolean']['input']>>;
};

export type BooleanOperators = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CashMovement = EntityNode & {
  __typename?: 'CashMovement';
  amount: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  openTerminalClosureId: Scalars['ID']['output'];
  type: CashMovementType;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
  userIdentifier?: Maybe<Scalars['String']['output']>;
};

export type CashMovementInput = {
  /** The amount of cash being added */
  amount: Scalars['Float']['input'];
  /** The date time of the movement. default to current time */
  date?: InputMaybe<Scalars['DateTime']['input']>;
  /** Optional, edit only */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Optional note for reason of movement */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Open terminal id to add a cash movement for */
  openTerminalClosureId: Scalars['ID']['input'];
  /** The type of cash movement */
  type: CashMovementType;
};

export enum CashMovementType {
  /** Cash in */
  CASH_IN = 'CASH_IN',
  /** Cash out */
  CASH_OUT = 'CASH_OUT',
  /** The closing float */
  CLOSING_FLOAT = 'CLOSING_FLOAT',
  /** Opening float */
  OPEN_FLOAT = 'OPEN_FLOAT',
  /** Petty cash in */
  PETTY_CASH_IN = 'PETTY_CASH_IN',
  /** Pretty cash out */
  PETTY_CASH_OUT = 'PETTY_CASH_OUT'
}

export type Category = EntityNode & {
  __typename?: 'Category';
  active: Scalars['Boolean']['output'];
  allowDiscount?: Maybe<Scalars['Boolean']['output']>;
  bannerImageUrl?: Maybe<Scalars['String']['output']>;
  catCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  departmentId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  markup?: Maybe<Array<Markup>>;
  name: Scalars['String']['output'];
  numberOfProducts: Scalars['Int']['output'];
  preferredTaxGroupId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CategoryFilterParameter = {
  active?: InputMaybe<BooleanOperators>;
  allowDiscount?: InputMaybe<BooleanOperators>;
  bannerImageUrl?: InputMaybe<StringOperators>;
  catCode?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  departmentId?: InputMaybe<StringOperators>;
  description?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  numberOfProducts?: InputMaybe<NumberOperators>;
  preferredTaxGroupId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type CategoryInput = {
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CategoryList = PaginatedList & {
  __typename?: 'CategoryList';
  items: Array<Category>;
  meta: PaginatedListMeta;
};

export type CategoryListOptions = {
  filter?: InputMaybe<CategoryFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CategorySortParameter>;
};

export type CategorySortParameter = {
  bannerImageUrl?: InputMaybe<SortOrder>;
  catCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  departmentId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  numberOfProducts?: InputMaybe<SortOrder>;
  preferredTaxGroupId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CloseTerminalInput = {
  /** Value will be used as the opening float next time opened */
  nextOpeningFloat?: InputMaybe<Scalars['Decimal']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  payments: Array<TerminalClosurePaymentTotalInput>;
  terminalId: Scalars['ID']['input'];
};

export type ConfigArg = {
  __typename?: 'ConfigArg';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ConfigArgDefinition = {
  __typename?: 'ConfigArgDefinition';
  defaultValue?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type ConfigurableOperation = {
  __typename?: 'ConfigurableOperation';
  args: Array<ConfigArg>;
  code: Scalars['String']['output'];
};

export type ConfigurableOperationDefinition = {
  __typename?: 'ConfigurableOperationDefinition';
  args: Array<ConfigArgDefinition>;
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export enum ContactPreference {
  /** Customer will receive both SMS and Email notifications */
  BOTH = 'BOTH',
  /** Customer will receive Email notifications */
  EMAIL = 'EMAIL',
  /** Customer will not be contacted */
  NONE = 'NONE',
  /** Customer will receive SMS notifications */
  SMS = 'SMS',
  /** Customer has not yet specified contact preference */
  UNSET = 'UNSET'
}

export type ConvertMainProductFromTemplateInput = {
  id: Scalars['ID']['input'];
  optionGroupTemplateId: Scalars['ID']['input'];
  /** The options for the variant product, must match the template options */
  selectedOptions?: InputMaybe<Array<SelectedOptionInput>>;
};

export type CountStockCountItemInput = {
  barcode: Scalars['String']['input'];
  /** Append the count to an existing stock count line */
  counted: Scalars['Float']['input'];
  /** Optional, current datetime will be used if not provided */
  countedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /**
   * Optional, the ID of the stock count to add the item to.
   * Its possible to count items that dont exist in the system yet.
   */
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type CountStockItemInput = {
  counted: Scalars['Float']['input'];
  productId: Scalars['ID']['input'];
};

export type CreateAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCategoryInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  bannerImage?: InputMaybe<Scalars['Upload']['input']>;
  catCode?: InputMaybe<Scalars['String']['input']>;
  departmentId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  markup?: InputMaybe<Array<MarkupInput>>;
  name: Scalars['String']['input'];
  preferredTaxGroupCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDepartmentInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  bannerImage?: InputMaybe<Scalars['Upload']['input']>;
  deptCode?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  markup?: InputMaybe<Array<MarkupInput>>;
  name: Scalars['String']['input'];
  preferredTaxGroupCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDocketTemplateInput = {
  automaticallyPrint?: InputMaybe<Scalars['Boolean']['input']>;
  /** Default to true */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<Array<DocketFilterInput>>;
  name: Scalars['String']['input'];
  /** Will default to the first docket template type if not provided */
  templateTypeId?: InputMaybe<Scalars['String']['input']>;
  terminalIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CreateImageResult = MimeTypeError | ProductImage;

export type CreateInvoiceArrivalInput = {
  /** Date the stock is being arrived */
  arrivalDate?: InputMaybe<Scalars['DateTime']['input']>;
  /**
   * the price includes GST
   * defaults to true
   * * retailer apparently needs this
   */
  costPriceIncGST?: InputMaybe<Scalars['Boolean']['input']>;
  /** Date the payment for the invoice is due */
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Value of freight component of the invoice */
  freightCost?: InputMaybe<Scalars['Float']['input']>;
  /** Date on the suppliers invoice */
  invoiceDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The invoice number from the supplier */
  invoiceNumber: Scalars['String']['input'];
  /** The location the stock is being delivered to */
  locationId: Scalars['ID']['input'];
  /** The order number from the supplier */
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  /** The id of the supplier */
  supplierId: Scalars['ID']['input'];
};

export type CreateInvoiceArrivalLineItemInput = {
  /** Required if productId is not provided */
  barcode?: InputMaybe<Scalars['String']['input']>;
  /**
   * The cost price of the product, required if productId is not provided.
   * ~ Prefilled if productId is provided
   */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** Optional, however if not provided, then must provide a barcode */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** The expected quantity being ordered */
  quantity: Scalars['Float']['input'];
  /** The amount of the product that has been received */
  received?: InputMaybe<Scalars['Float']['input']>;
  /**
   * The price price of the product, required if productId is not provided.
   * ~ Prefilled if productId is provided
   */
  retailPrice?: InputMaybe<Scalars['Float']['input']>;
  /**
   * The serial numbers of the products being received.
   * Required if product has been flagged as requiring serial numbers
   * The amount should be equal to the received amount
   */
  serialNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * The price price of the product, required if productId is not provided.
   * ~ Prefilled if productId is provided
   */
  tradePrice?: InputMaybe<Scalars['Float']['input']>;
  /**
   * The price price of the product, required if productId is not provided.
   * ~ Prefilled if productId is provided
   */
  webPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateLocationInput = {
  name: Scalars['String']['input'];
  retailerLocationKey: Scalars['String']['input'];
};

export type CreatePaymentMethodInput = {
  configuration?: InputMaybe<PaymentMethodConfigurationInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  payTypeId: Scalars['String']['input'];
  /** @deprecated use retailerPayTypeId */
  retailerPayCode?: InputMaybe<Scalars['String']['input']>;
  retailerPayTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateProductImageBase64Input = {
  base64: Scalars['String']['input'];
  imageName: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  sendRetailer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateProductImageInput = {
  file: Scalars['Upload']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['ID']['input'];
  sendRetailer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateProductInput = {
  UOM?: InputMaybe<Uom>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  locationInventoryPricing?: InputMaybe<Array<InventoryPricingInput>>;
  loyaltyPointEarnValue?: InputMaybe<Scalars['Int']['input']>;
  loyaltyPointRedeemValue?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  /** @deprecated Use productOptionGroupTemplateId instead */
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  priceLookup?: InputMaybe<Scalars['String']['input']>;
  /**
   * The ID of the product option group template to use for this product.
   * Replaces the deprecated options field.
   */
  productOptionGroupTemplateId?: InputMaybe<Scalars['ID']['input']>;
  /** Must match the options of the parent product option group template */
  selectedOptions?: InputMaybe<Array<SelectedOptionInput>>;
  serialNumbersArr?: InputMaybe<Scalars['Boolean']['input']>;
  serialNumbersSale?: InputMaybe<Scalars['Boolean']['input']>;
  stockCode?: InputMaybe<Scalars['String']['input']>;
  stockNumber: Scalars['String']['input'];
  supplierId?: InputMaybe<Scalars['ID']['input']>;
  supplierStockCode?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  variantParentId?: InputMaybe<Scalars['ID']['input']>;
  webDescription?: InputMaybe<Scalars['String']['input']>;
  webPrice?: InputMaybe<Scalars['Float']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateProductOptionGroupTemplateInput = {
  /**
   * The identifier is used to reference the template in the product group.
   * Should be the same as the Retailer ID
   */
  identifier: Scalars['String']['input'];
  name: Scalars['String']['input'];
  options: Array<ProductOptionInput>;
};

export type CreateRetailerPayTypeInput = {
  payCode: Scalars['String']['input'];
  payDescription?: InputMaybe<Scalars['String']['input']>;
  /** defaults to false */
  payOver?: InputMaybe<Scalars['Boolean']['input']>;
  payType?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSaleInput = {
  /** Valid id of the customer accociated with the sale */
  customerId?: InputMaybe<Scalars['ID']['input']>;
  /** Optional invoice number */
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  /** Sets the invoice sequence */
  invoiceSequence?: InputMaybe<Scalars['Int']['input']>;
  lineItems: Array<SaleLineItemInput>;
  /** Valid id of location to assign sale to */
  locationId: Scalars['ID']['input'];
  /** Optional: Store any metadata relevent to the sale */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  /** Optional note to add to the sale */
  note?: InputMaybe<Scalars['String']['input']>;
  /** An arra yof payments */
  payments: Array<RegisterSalePaymentInput>;
  /** Optional purchase order number */
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
  /** Required when the sale is a quote */
  quoteAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  /** optional, the the receipt template to use when sending email */
  receiptId?: InputMaybe<Scalars['ID']['input']>;
  /** optional, send a receipt to customer */
  receiptTo?: InputMaybe<Scalars['String']['input']>;
  /** Time of sale, by default current time will be assigned */
  saleDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Valid id of the retailer staff who created this sale. */
  staffId?: InputMaybe<Scalars['ID']['input']>;
  /** One of: OPEN, SAVED, CLOSED, LAYBY, LAYBY_CLOSED, VOIDED */
  state: Scalars['String']['input'];
  /** Valid id of terminal to assign sale to */
  terminalId: Scalars['ID']['input'];
};

export type CreateSerialNumberInput = {
  value: Scalars['String']['input'];
};

export type CreateStaffInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fullName: Scalars['String']['input'];
  /** Must be unique */
  initials: Scalars['String']['input'];
};

export type CreateStaffUserAccountInput = {
  /** Optional, required for 2fa */
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  staffId: Scalars['ID']['input'];
  /** unique identifier for the user account */
  username: Scalars['String']['input'];
};

export type CreateStockCountInput = {
  /** The location to create the stock count for. must be provided if no location key */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  retailerLocationKey?: InputMaybe<Scalars['String']['input']>;
  /** Name TBD, required if source is 'RETAILER' */
  retailerStocktakeId?: InputMaybe<Scalars['String']['input']>;
  /** The source of the stock count. Retailer or internal */
  source: StockCountSource;
};

export type CreateStockOrderInput = {
  /** The location the stock is being delivered to */
  locationId: Scalars['ID']['input'];
  /**
   * Optional. The date the order was created. Will default to the current
   * date if not provided
   */
  orderCreatedDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Optional order number, one will be generated if not provided */
  orderNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateStockOrderLineItemInput = {
  /**
   * Required if productId is not provided. Will be ignored
   * if productId is provided
   */
  barcode?: InputMaybe<Scalars['String']['input']>;
  /** Optional, however if not provided, then must provide a barcode */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** The expected quantity being ordered */
  quantity: Scalars['Float']['input'];
  /**
   * Optionally if product has been provided, and the supplier has been set,
   * then set the supplier code of the product
   */
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  /**
   * Optionally if product has been provided, then set
   * the supplier of the product
   */
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateStripeExpressAccountInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSupplierInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  bannerImage?: InputMaybe<Scalars['Upload']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  suppCode: Scalars['String']['input'];
};

export type CreateTaxGroupInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  arrivePercentage?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  salePercentage?: InputMaybe<Scalars['Int']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  taxGroupID: Scalars['String']['input'];
};

export type CreateTerminalInput = {
  defaultReceiptTemplateId?: InputMaybe<Scalars['ID']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceSequence?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  paymentMethodIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  salePrefix?: InputMaybe<Scalars['String']['input']>;
  saleSufix?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVariantForProductInput = {
  /** the id of the location to base the inventory pricing on */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  /** The options for the variant product, must match the parent product template options */
  selectedOptions?: InputMaybe<Array<SelectedOptionInput>>;
  stockNumber: Scalars['String']['input'];
  /** the id of the product to create the variant for */
  variantParentId: Scalars['ID']['input'];
};

export type CurrencyInput = {
  code: Scalars['Currency']['input'];
  symbol: Scalars['String']['input'];
};

export type CurrencyType = {
  __typename?: 'CurrencyType';
  code: Scalars['Currency']['output'];
  symbol?: Maybe<Scalars['String']['output']>;
};

export type CurrentUserAndTenant = {
  __typename?: 'CurrentUserAndTenant';
  currentUser: CurrentUserV2;
  tenant: Tenant;
};

export type CurrentUserV2 = {
  __typename?: 'CurrentUserV2';
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  permissions: Array<Permission>;
  restrictedTerminalIds: Array<Scalars['ID']['output']>;
  token: Scalars['String']['output'];
};

export type Customer = EntityNode & {
  __typename?: 'Customer';
  addresses?: Maybe<Array<CustomerAddress>>;
  amountSpend: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  invoicedAmount: Scalars['Float']['output'];
  isRetailerCusAccount: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  loyaltyPoints: Scalars['Int']['output'];
  mobileNumber?: Maybe<Scalars['String']['output']>;
  numberOfOrders: Scalars['Int']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  retailerId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CustomerAddress = EntityNode & {
  __typename?: 'CustomerAddress';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  postcode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerAddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerAddressToAddInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  postcode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type CustomerFilterParameter = {
  amountSpend?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  emailAddress?: InputMaybe<StringOperators>;
  firstName?: InputMaybe<StringOperators>;
  gender?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  invoicedAmount?: InputMaybe<NumberOperators>;
  isRetailerCusAccount?: InputMaybe<BooleanOperators>;
  lastName?: InputMaybe<StringOperators>;
  loyaltyPoints?: InputMaybe<NumberOperators>;
  mobileNumber?: InputMaybe<StringOperators>;
  numberOfOrders?: InputMaybe<NumberOperators>;
  phoneNumber?: InputMaybe<StringOperators>;
  retailerId?: InputMaybe<StringOperators>;
  term?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type CustomerInput = {
  addressesToAdd?: InputMaybe<Array<InputMaybe<CustomerAddressToAddInput>>>;
  addressesToRemove?: InputMaybe<Array<Scalars['ID']['input']>>;
  contactPreference?: InputMaybe<ContactPreference>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isRetailerCusAccount?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  loyaltyPoints?: InputMaybe<Scalars['Int']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  retailerId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerList = PaginatedList & {
  __typename?: 'CustomerList';
  items: Array<Customer>;
  meta: PaginatedListMeta;
};

export type CustomerListExtendedFilterOptions = {
  fullName?: InputMaybe<StringOperators>;
};

export type CustomerListOptions = {
  extendedFilter?: InputMaybe<CustomerListExtendedFilterOptions>;
  filter?: InputMaybe<CustomerFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CustomerSortParameter>;
};

export type CustomerSortParameter = {
  amountSpend?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  emailAddress?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoicedAmount?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  loyaltyPoints?: InputMaybe<SortOrder>;
  mobileNumber?: InputMaybe<SortOrder>;
  numberOfOrders?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  retailerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Operators for filtering on a list of Date fields */
export type DateListOperators = {
  inList: Array<InputMaybe<Scalars['DateTime']['input']>>;
};

export type DateOperators = {
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
  between?: InputMaybe<DateRange>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateRange = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type Deleted = {
  __typename?: 'Deleted';
  result?: Maybe<DeletionResult>;
};

export type DeletionResponse = {
  __typename?: 'DeletionResponse';
  reason?: Maybe<Scalars['String']['output']>;
  result: DeletionResult;
};

export enum DeletionResult {
  /** The entity was successfully deleted */
  DELETED = 'DELETED',
  /** Deletion did not take place, reason given in message */
  NOT_DELETED = 'NOT_DELETED'
}

export type Department = EntityNode & {
  __typename?: 'Department';
  active: Scalars['Boolean']['output'];
  allowDiscount?: Maybe<Scalars['Boolean']['output']>;
  bannerImageUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deptCode?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  markup?: Maybe<Array<Markup>>;
  name: Scalars['String']['output'];
  numberOfCategories: Scalars['Int']['output'];
  numberOfProducts: Scalars['Int']['output'];
  preferredTaxGroupId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DepartmentFilterParameter = {
  active?: InputMaybe<BooleanOperators>;
  allowDiscount?: InputMaybe<BooleanOperators>;
  bannerImageUrl?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  deptCode?: InputMaybe<StringOperators>;
  description?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  numberOfCategories?: InputMaybe<NumberOperators>;
  numberOfProducts?: InputMaybe<NumberOperators>;
  preferredTaxGroupId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type DepartmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type DepartmentList = PaginatedList & {
  __typename?: 'DepartmentList';
  items: Array<Department>;
  meta: PaginatedListMeta;
};

export type DepartmentListOptions = {
  filter?: InputMaybe<DepartmentFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DepartmentSortParameter>;
};

export type DepartmentSortParameter = {
  bannerImageUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  deptCode?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  numberOfCategories?: InputMaybe<SortOrder>;
  numberOfProducts?: InputMaybe<SortOrder>;
  preferredTaxGroupId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DocketFilter = {
  __typename?: 'DocketFilter';
  type: DocketFilterType;
  values: Array<Scalars['String']['output']>;
};

export type DocketFilterInput = {
  type: DocketFilterType;
  values: Array<Scalars['String']['input']>;
};

export enum DocketFilterType {
  CATEGORY = 'CATEGORY',
  DEPARTMENT = 'DEPARTMENT',
  TAG = 'TAG'
}

export type DocketTemplate = EntityNode & {
  __typename?: 'DocketTemplate';
  automaticallyPrint: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  defaultTemplateTypeId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  enabled: Scalars['Boolean']['output'];
  filters: Array<DocketFilter>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  terminalIds?: Maybe<Array<Scalars['ID']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DocketTemplateFilterParameter = {
  automaticallyPrint?: InputMaybe<BooleanOperators>;
  createdAt?: InputMaybe<DateOperators>;
  defaultTemplateTypeId?: InputMaybe<StringOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  terminalIds?: InputMaybe<IdListOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type DocketTemplateList = PaginatedList & {
  __typename?: 'DocketTemplateList';
  items: Array<DocketTemplate>;
  meta: PaginatedListMeta;
};

export type DocketTemplateListOptions = {
  filter?: InputMaybe<DocketTemplateFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DocketTemplateSortParameter>;
};

export type DocketTemplateSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  defaultTemplateTypeId?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DocketTemplateType = {
  __typename?: 'DocketTemplateType';
  createdAt: Scalars['DateTime']['output'];
  css: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['Int']['output'];
};

export type DocketTemplateTypeListFilterOptions = {
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DownloadReceiptPdfBase64StringResponse = {
  __typename?: 'DownloadReceiptPdfBase64StringResponse';
  data: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
};

export type DownloadStockCountCsvBase64StringResponse = {
  __typename?: 'DownloadStockCountCsvBase64StringResponse';
  data: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
};

export type DownloadStocktakeCsvBase64StringResponse = {
  __typename?: 'DownloadStocktakeCsvBase64StringResponse';
  data: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
};

export type DuplicateProductInput = {
  /** defaults to true */
  copyPricing?: InputMaybe<Scalars['Boolean']['input']>;
  /** defaults to false */
  copyProductTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  copySupplier?: InputMaybe<Scalars['Boolean']['input']>;
  /** Barcode will be prefixed by 'DUP' if not provided */
  newBarcode?: InputMaybe<Scalars['String']['input']>;
  /** Defauult to '{product name}' if not provided */
  newTitle?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

/** Returned when attempting to create a Customer with an email address already registered to an existing User. */
export type EmailAddressConflictError = ErrorResult & {
  __typename?: 'EmailAddressConflictError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type EmailClosureSummaryInput = {
  closureId: Scalars['ID']['input'];
  to: Scalars['String']['input'];
};

export type EntityNode = {
  id: Scalars['ID']['output'];
};

export enum ErrorCode {
  BACKGROUND_REMOVER_INSUFFICIENT_CREDITS_ERROR = 'BACKGROUND_REMOVER_INSUFFICIENT_CREDITS_ERROR',
  BACKGROUND_REMOVER_NOT_CONFIGURED_ERROR = 'BACKGROUND_REMOVER_NOT_CONFIGURED_ERROR',
  EMAIL_ADDRESS_CONFLICT_ERROR = 'EMAIL_ADDRESS_CONFLICT_ERROR',
  INSUFFICIENT_BARCODE_LOOKUP_CREDITS_ERROR = 'INSUFFICIENT_BARCODE_LOOKUP_CREDITS_ERROR',
  INSUFFICIENT_STOCK_ERROR = 'INSUFFICIENT_STOCK_ERROR',
  INVALID_BARCODE_LOOKUP_CREDENTIALS_ERROR = 'INVALID_BARCODE_LOOKUP_CREDENTIALS_ERROR',
  INVALID_CREDENTIALS_ERROR = 'INVALID_CREDENTIALS_ERROR',
  INVALID_LINKLY_CREDENTIALS_ERROR = 'INVALID_LINKLY_CREDENTIALS_ERROR',
  INVALID_MFA_CODE_ERROR = 'INVALID_MFA_CODE_ERROR',
  INVALID_MFA_IDENTIFIER_ERROR = 'INVALID_MFA_IDENTIFIER_ERROR',
  INVALID_MFA_STRATEGY_ERROR = 'INVALID_MFA_STRATEGY_ERROR',
  LINKLY_ERROR = 'LINKLY_ERROR',
  MFA_ALREADY_SETUP_ERROR = 'MFA_ALREADY_SETUP_ERROR',
  MFA_REQUIRED_ERROR = 'MFA_REQUIRED_ERROR',
  MIME_TYPE_ERROR = 'MIME_TYPE_ERROR',
  MISSING_PASSWORD_ERROR = 'MISSING_PASSWORD_ERROR',
  NATIVE_AUTH_STRATEGY_ERROR = 'NATIVE_AUTH_STRATEGY_ERROR',
  NEGATIVE_QUANTITY_ERROR = 'NEGATIVE_QUANTITY_ERROR',
  NOT_VERIFIED_ERROR = 'NOT_VERIFIED_ERROR',
  PASSWORD_ALREADY_SET_ERROR = 'PASSWORD_ALREADY_SET_ERROR',
  PASSWORD_VALIDATION_ERROR = 'PASSWORD_VALIDATION_ERROR',
  SALE_STATE_TRANSITION_ERROR = 'SALE_STATE_TRANSITION_ERROR',
  STOCK_NUMBER_NOT_FOUND_ERROR = 'STOCK_NUMBER_NOT_FOUND_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export type ErrorResult = {
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type ExportRetailerSaleOptions = {
  /** The date to export sales after */
  after?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum ExportSaleType {
  /** WEBLINK */
  WEBLINK = 'WEBLINK'
}

export type ExportStockCountItemResult = {
  __typename?: 'ExportStockCountItemResult';
  stockCountItem: StockCountItem;
  success: Scalars['Boolean']['output'];
};

export type FeaturesList = {
  __typename?: 'FeaturesList';
  pointOfSale: Scalars['Boolean']['output'];
  selfCheckout: Scalars['Boolean']['output'];
  stocktake: Scalars['Boolean']['output'];
  utilities: Scalars['Boolean']['output'];
};

export type FilterValueMeta = {
  __typename?: 'FilterValueMeta';
  label: Scalars['String']['output'];
  type: FilterValueMetaType;
  value: Scalars['String']['output'];
};

export type FilterValueMetaInput = {
  type: FilterValueMetaType;
  /** max 100 at a time to stop overload server */
  value: Array<Scalars['String']['input']>;
};

export enum FilterValueMetaType {
  CATEGORY_ID = 'CATEGORY_ID',
  DEPARTMENT_ID = 'DEPARTMENT_ID'
}

export enum Gender {
  /** female */
  FEMALE = 'FEMALE',
  /** male */
  MALE = 'MALE',
  /** other */
  OTHER = 'OTHER',
  /** unknown */
  UNKNOWN = 'UNKNOWN'
}

export type GenerateAccessTokenInput = {
  name: Scalars['String']['input'];
};

export type GlobalSettings = {
  __typename?: 'GlobalSettings';
  abn?: Maybe<Scalars['String']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  automaticBackgroundRemoval: Scalars['Boolean']['output'];
  automaticOpenTerminalClosure: Scalars['Boolean']['output'];
  automaticallyEmailStockTake: Scalars['Boolean']['output'];
  automaticallyEmailTerminalClosureSummary: Scalars['Boolean']['output'];
  bgRemovalStrategy?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  cashManagedPaymentMethodId?: Maybe<Scalars['ID']['output']>;
  countryCode: Scalars['String']['output'];
  currency: CurrencyType;
  defaultLaybyDepositPercentage: Scalars['Int']['output'];
  defaultTerminalClosureFloat: Scalars['Float']['output'];
  email?: Maybe<Scalars['String']['output']>;
  laybyEnabled: Scalars['Boolean']['output'];
  minLaybySpend: Scalars['Float']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  productSurchargeId: Scalars['ID']['output'];
  removalAiDetails?: Maybe<RemovalAiDetails>;
  removalBgDetails?: Maybe<RemovalBgDetails>;
  retailerInBoundEnabled: Scalars['Boolean']['output'];
  showExpectedClosureCounts: Scalars['Boolean']['output'];
  stocktakeSendToAddress?: Maybe<Scalars['String']['output']>;
  talinkId: Scalars['String']['output'];
  taxPercentage: Scalars['Int']['output'];
  terminalClosureSendToAddress?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

/** Operators for filtering on a list of ID fields */
export type IdListOperators = {
  inList: Array<InputMaybe<Scalars['ID']['input']>>;
};

/** Operators for filtering on an ID field */
export type IdOperators = {
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEq?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** The barcode lookup credits were insufficient */
export type InsufficientBarcodeLookupCreditsError = ErrorResult & {
  __typename?: 'InsufficientBarcodeLookupCreditsError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

/** Returned when attempting to set a SaleItem quantity greater than the available stock. */
export type InsufficientStockError = ErrorResult & {
  __typename?: 'InsufficientStockError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
  quantityAvailable: Scalars['Int']['output'];
  sale: Sale;
};

/** The barcode lookup key was invalid */
export type InvalidBarcodeLookupCredentialsError = ErrorResult & {
  __typename?: 'InvalidBarcodeLookupCredentialsError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type InvalidCredentialsError = ErrorResult & {
  __typename?: 'InvalidCredentialsError';
  authenticationError: Scalars['String']['output'];
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

/** The username, password, or paircode was invalid. */
export type InvalidLinklyCredentialsError = ErrorResult & {
  __typename?: 'InvalidLinklyCredentialsError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

/** Retured if MFA code is invalid for authentication strategy */
export type InvalidMfaCodeError = ErrorResult & {
  __typename?: 'InvalidMfaCodeError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type InvalidMfaIdentifierError = ErrorResult & {
  __typename?: 'InvalidMfaIdentifierError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type InvalidMfaStrategyError = ErrorResult & {
  __typename?: 'InvalidMfaStrategyError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type InventoryAdjustQuantityInput = {
  available: Scalars['Int']['input'];
  inventoryLevelId: Scalars['ID']['input'];
  unitsPerPack?: InputMaybe<Scalars['Int']['input']>;
};

export type InventoryAdjustQuantityInputV2 = {
  available: Scalars['Int']['input'];
  inventoryItemId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};

export type InventoryItem = EntityNode & {
  __typename?: 'InventoryItem';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inventoryLevels: Array<InventoryLevel>;
  productId: Scalars['ID']['output'];
  tracked: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InventoryItemFilterParameter = {
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  productId?: InputMaybe<IdOperators>;
  tracked?: InputMaybe<BooleanOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type InventoryItemInput = {
  tracked?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InventoryItemList = PaginatedList & {
  __typename?: 'InventoryItemList';
  items: Array<InventoryItem>;
  meta: PaginatedListMeta;
};

export type InventoryItemListOptions = {
  filter?: InputMaybe<InventoryItemFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InventoryItemSortParameter>;
};

export type InventoryItemSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InventoryLevel = EntityNode & {
  __typename?: 'InventoryLevel';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inventoryItemId: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  onHand: Scalars['Int']['output'];
  unitsPerPack: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InventoryLevelFilterParameter = {
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  inventoryItemId?: InputMaybe<IdOperators>;
  locationId?: InputMaybe<IdOperators>;
  onHand?: InputMaybe<NumberOperators>;
  unitsPerPack?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type InventoryLevelInput = {
  availableQuantity: Scalars['Int']['input'];
  locationId: Scalars['ID']['input'];
  unitsPerPack?: InputMaybe<Scalars['Int']['input']>;
};

export type InventoryLevelList = PaginatedList & {
  __typename?: 'InventoryLevelList';
  items: Array<InventoryLevel>;
  meta: PaginatedListMeta;
};

export type InventoryLevelListOptions = {
  filter?: InputMaybe<InventoryLevelFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InventoryLevelSortParameter>;
};

export type InventoryLevelMovement = EntityNode & {
  __typename?: 'InventoryLevelMovement';
  adjustment: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inventoryLevelId?: Maybe<Scalars['ID']['output']>;
  quantity: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type InventoryLevelMovementFilterParameter = {
  adjustment?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  inventoryLevelId?: InputMaybe<IdOperators>;
  locationId?: InputMaybe<IdOperators>;
  productId?: InputMaybe<IdOperators>;
  productVariantId?: InputMaybe<IdOperators>;
  quantity?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  userId?: InputMaybe<IdOperators>;
};

export type InventoryLevelMovementList = PaginatedList & {
  __typename?: 'InventoryLevelMovementList';
  items: Array<InventoryLevelMovement>;
  meta: PaginatedListMeta;
};

export type InventoryLevelMovementListOptions = {
  filter?: InputMaybe<InventoryLevelMovementFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InventoryLevelMovementSortParameter>;
};

export type InventoryLevelMovementSortParameter = {
  adjustment?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inventoryLevelId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type InventoryLevelSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inventoryItemId?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  onHand?: InputMaybe<SortOrder>;
  unitsPerPack?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InventoryMovement = EntityNode & {
  __typename?: 'InventoryMovement';
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  dispatchedAt?: Maybe<Scalars['DateTime']['output']>;
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  filters?: Maybe<Array<InventoryMovementFilter>>;
  id: Scalars['ID']['output'];
  inventoryMovementDate: Scalars['DateTime']['output'];
  isFullStocktake: Scalars['Boolean']['output'];
  location?: Maybe<Location>;
  locationId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  receivedAt?: Maybe<Scalars['DateTime']['output']>;
  referenceNo?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  status: InventoryMovementStatus;
  targetLocationId?: Maybe<Scalars['ID']['output']>;
  totals?: Maybe<InventoryMovementTotals>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type InventoryMovementFilter = {
  __typename?: 'InventoryMovementFilter';
  type: InventoryMovementFilterType;
  value: Scalars['String']['output'];
};

export type InventoryMovementFilterInput = {
  type: InventoryMovementFilterType;
  value: Scalars['String']['input'];
};

export type InventoryMovementFilterParameter = {
  cancelledAt?: InputMaybe<DateOperators>;
  completedAt?: InputMaybe<DateOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  dispatchedAt?: InputMaybe<DateOperators>;
  dueAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  inventoryMovementDate?: InputMaybe<DateOperators>;
  isFullStocktake?: InputMaybe<BooleanOperators>;
  locationId?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  receivedAt?: InputMaybe<DateOperators>;
  referenceNo?: InputMaybe<StringOperators>;
  sentAt?: InputMaybe<DateOperators>;
  status?: InputMaybe<StringOperators>;
  targetLocationId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  userId?: InputMaybe<IdOperators>;
};

export enum InventoryMovementFilterType {
  CATEGORY = 'CATEGORY',
  DEPARTMENT = 'DEPARTMENT',
  PRODUCT = 'PRODUCT',
  PRODUCT_VARIANT = 'PRODUCT_VARIANT'
}

export type InventoryMovementItem = EntityNode & {
  __typename?: 'InventoryMovementItem';
  cost?: Maybe<Scalars['Float']['output']>;
  counted?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  expected?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  initialExpected?: Maybe<Scalars['Int']['output']>;
  inventoryMovementId: Scalars['ID']['output'];
  manual: Scalars['Boolean']['output'];
  productId: Scalars['ID']['output'];
  status?: Maybe<InventoryMovementItemStatus>;
  stockCode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type InventoryMovementItemFilterParameter = {
  cost?: InputMaybe<NumberOperators>;
  counted?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  expected?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  initialExpected?: InputMaybe<NumberOperators>;
  inventoryMovementId?: InputMaybe<IdOperators>;
  manual?: InputMaybe<BooleanOperators>;
  productId?: InputMaybe<IdOperators>;
  status?: InputMaybe<StringOperators>;
  stockCode?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type InventoryMovementItemList = PaginatedList & {
  __typename?: 'InventoryMovementItemList';
  items: Array<InventoryMovementItem>;
  meta: PaginatedListMeta;
};

export type InventoryMovementItemListOptions = {
  filter?: InputMaybe<InventoryMovementItemFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InventoryMovementItemSortParameter>;
};

export type InventoryMovementItemSortParameter = {
  cost?: InputMaybe<SortOrder>;
  counted?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  expected?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  initialExpected?: InputMaybe<SortOrder>;
  inventoryMovementId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  stockCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum InventoryMovementItemStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS'
}

export type InventoryMovementItemSummaryTotals = {
  __typename?: 'InventoryMovementItemSummaryTotals';
  counted: Scalars['Int']['output'];
  inventoryMovementItemId: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  productId?: Maybe<Scalars['ID']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  received: Scalars['Int']['output'];
  stockNumber?: Maybe<Scalars['String']['output']>;
  totalCost: Scalars['Float']['output'];
};

export type InventoryMovementList = PaginatedList & {
  __typename?: 'InventoryMovementList';
  items: Array<InventoryMovement>;
  meta: PaginatedListMeta;
};

export type InventoryMovementListExtendedFilterOptions = {
  productId?: InputMaybe<IdListOperators>;
};

export type InventoryMovementListOptions = {
  extendedFilter?: InputMaybe<InventoryMovementListExtendedFilterOptions>;
  filter?: InputMaybe<InventoryMovementFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InventoryMovementSortParameter>;
};

export type InventoryMovementSortParameter = {
  cancelledAt?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  dispatchedAt?: InputMaybe<SortOrder>;
  dueAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inventoryMovementDate?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  receivedAt?: InputMaybe<SortOrder>;
  referenceNo?: InputMaybe<SortOrder>;
  sentAt?: InputMaybe<SortOrder>;
  targetLocationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum InventoryMovementStatus {
  CANCELLED = 'CANCELLED',
  /** RESERVED FOR INITIAL TRANSITION STATE. DO NOT SEND TO SERVER */
  CREATED = 'CREATED',
  STOCKTAKE_COMPLETE = 'STOCKTAKE_COMPLETE',
  STOCKTAKE_PLANNED = 'STOCKTAKE_PLANNED',
  STOCKTAKE_UNDERWAY = 'STOCKTAKE_UNDERWAY',
  STOCKTAKE_UNDERWAY_PROCESSED = 'STOCKTAKE_UNDERWAY_PROCESSED',
  STOCKTAKE_UNDERWAY_PROCESSING = 'STOCKTAKE_UNDERWAY_PROCESSING',
  STOCKTAKE_UNDERWAY_PROCESSING_ERROR = 'STOCKTAKE_UNDERWAY_PROCESSING_ERROR'
}

export type InventoryMovementSummaryTotal = {
  __typename?: 'InventoryMovementSummaryTotal';
  cost: Scalars['Float']['output'];
  counted: Scalars['Int']['output'];
  received: Scalars['Int']['output'];
};

export type InventoryMovementSummaryTotals = {
  __typename?: 'InventoryMovementSummaryTotals';
  items: Array<InventoryMovementItemSummaryTotals>;
  totals: InventoryMovementSummaryTotal;
};

export type InventoryMovementTotals = {
  __typename?: 'InventoryMovementTotals';
  totalCostGain?: Maybe<Scalars['Float']['output']>;
  totalCostLoss?: Maybe<Scalars['Float']['output']>;
  totalCountGain?: Maybe<Scalars['Float']['output']>;
  totalCountLoss?: Maybe<Scalars['Float']['output']>;
};

export type InventoryPricingInput = {
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** Required (or locationId) when updating */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Required if not using retailerLocationKey */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  retailPrice?: InputMaybe<Scalars['Float']['input']>;
  /** Required when creating only and when locationId not set */
  retailerLocationKey?: InputMaybe<Scalars['String']['input']>;
  rrPrice?: InputMaybe<Scalars['Float']['input']>;
  serialNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  stockOnHand?: InputMaybe<Scalars['Float']['input']>;
  taxGroupId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use taxGroupId instead */
  taxRatePercent?: InputMaybe<Scalars['Int']['input']>;
  tradePrice?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceArrival = EntityNode & {
  __typename?: 'InvoiceArrival';
  arrivalDate?: Maybe<Scalars['DateTime']['output']>;
  /** Do the cost prices include GST */
  costPriceIncGST: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** The formatted total GST component of the invoice */
  formattedGSTCredit: Scalars['String']['output'];
  freightCost: Scalars['Float']['output'];
  /** The total GST component of the invoice */
  gstCredit: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber: Scalars['String']['output'];
  items: Array<InvoiceArrivalItem>;
  location?: Maybe<Location>;
  locationId: Scalars['ID']['output'];
  orderNumber?: Maybe<Scalars['String']['output']>;
  status: InvoiceArrivalStatus;
  supplier?: Maybe<Supplier>;
  supplierId: Scalars['ID']['output'];
  /** The raw total cost of the invoice not including or excluding GST */
  totalCost: Scalars['Float']['output'];
  /** The total cost of the invoice excluding GST */
  totalCostExGST: Scalars['Float']['output'];
  /** The total cost of the invoice including GST */
  totalCostIncGST: Scalars['Float']['output'];
  totalQuantity: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceArrivalFilterParameter = {
  arrivalDate?: InputMaybe<DateOperators>;
  costPriceIncGST?: InputMaybe<BooleanOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  dueDate?: InputMaybe<DateOperators>;
  formattedGSTCredit?: InputMaybe<StringOperators>;
  freightCost?: InputMaybe<NumberOperators>;
  gstCredit?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  invoiceDate?: InputMaybe<DateOperators>;
  invoiceNumber?: InputMaybe<StringOperators>;
  locationId?: InputMaybe<IdOperators>;
  orderNumber?: InputMaybe<StringOperators>;
  productId?: InputMaybe<StringOperators>;
  status?: InputMaybe<StringOperators>;
  supplierId?: InputMaybe<IdOperators>;
  totalCost?: InputMaybe<NumberOperators>;
  totalCostExGST?: InputMaybe<NumberOperators>;
  totalCostIncGST?: InputMaybe<NumberOperators>;
  totalQuantity?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type InvoiceArrivalItem = EntityNode & {
  __typename?: 'InvoiceArrivalItem';
  barcode?: Maybe<Scalars['String']['output']>;
  costPrice?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The formatted total GST component of the invoice */
  formattedGSTCredit: Scalars['String']['output'];
  /**
   * The total GST component of the item. Determined by the
   * costPriceIncGST flag on the invoice arrival
   */
  gstCredit: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  invoiceArrivalId: Scalars['ID']['output'];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['ID']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  received?: Maybe<Scalars['Float']['output']>;
  retailPrice?: Maybe<Scalars['Float']['output']>;
  serialNumbers?: Maybe<Array<Scalars['String']['output']>>;
  /** The base total cost of the item including & exclusing GST */
  totalCost: Scalars['Float']['output'];
  /** The total cost of the item excluding GST */
  totalCostExGST: Scalars['Float']['output'];
  /** The total cost of the item including GST */
  totalCostIncGST: Scalars['Float']['output'];
  tradePrice?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  webPrice?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceArrivalList = PaginatedList & {
  __typename?: 'InvoiceArrivalList';
  items: Array<InvoiceArrival>;
  meta: PaginatedListMeta;
};

export type InvoiceArrivalListOptions = {
  filter?: InputMaybe<InvoiceArrivalFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InvoiceArrivalSortParameter>;
};

export type InvoiceArrivalSortParameter = {
  arrivalDate?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  dueDate?: InputMaybe<SortOrder>;
  formattedGSTCredit?: InputMaybe<SortOrder>;
  freightCost?: InputMaybe<SortOrder>;
  gstCredit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceDate?: InputMaybe<SortOrder>;
  invoiceNumber?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  orderNumber?: InputMaybe<SortOrder>;
  supplierId?: InputMaybe<SortOrder>;
  totalCost?: InputMaybe<SortOrder>;
  totalCostExGST?: InputMaybe<SortOrder>;
  totalCostIncGST?: InputMaybe<SortOrder>;
  totalQuantity?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum InvoiceArrivalStatus {
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  PENDING = 'PENDING'
}

export type Layout = EntityNode & {
  __typename?: 'Layout';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  nodes: Array<LayoutNode>;
  terminalId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LayoutFilterParameter = {
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  terminalId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type LayoutInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  /** ID of terminal to associate with */
  terminalId?: InputMaybe<Scalars['ID']['input']>;
};

export type LayoutList = PaginatedList & {
  __typename?: 'LayoutList';
  items: Array<Layout>;
  meta: PaginatedListMeta;
};

export type LayoutListOptions = {
  filter?: InputMaybe<LayoutFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LayoutSortParameter>;
};

export type LayoutNode = EntityNode & {
  __typename?: 'LayoutNode';
  action?: Maybe<LayoutNodeActionType>;
  color?: Maybe<Scalars['HexColorCode']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  filter?: Maybe<LayoutNodeFilter>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  layoutId: Scalars['ID']['output'];
  link?: Maybe<LayoutNodeLink>;
  position: Scalars['Int']['output'];
  productId?: Maybe<Scalars['ID']['output']>;
  showImage?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<LayoutNodeType>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum LayoutNodeActionType {
  /** OPEN ACTION TO ADD OR REPLACE CUSTOMER */
  ADD_CUSTOMER = 'ADD_CUSTOMER',
  /** ADD NOTE TO SALE */
  ADD_NOTE_TO_SALE = 'ADD_NOTE_TO_SALE',
  /** APPLY_CUSTOMER_LOYALTY_POINTS */
  APPLY_CUSTOMER_LOYALTY_POINTS = 'APPLY_CUSTOMER_LOYALTY_POINTS',
  /** OPEN CANCEL SALE MODAL */
  CANCEL_SALE = 'CANCEL_SALE',
  /** CUSTOMER ACCOUNT PAYMENT */
  CUSTOMER_ACCOUNT_PAYMENT = 'CUSTOMER_ACCOUNT_PAYMENT',
  /** OPEN DISCOUNT SALE MODAL */
  DISCOUNT_SALE = 'DISCOUNT_SALE',
  /** OPEN PARK SALE MODAL */
  PARK_SALE = 'PARK_SALE',
  /** SALES HISTORY */
  SALES_HISTORY = 'SALES_HISTORY',
  /** OPEN SWITCH SALE MODAL */
  SWITCH_SALE = 'SWITCH_SALE'
}

export type LayoutNodeFilter = {
  __typename?: 'LayoutNodeFilter';
  type: LayoutNodeFilterType;
  values: Array<Scalars['String']['output']>;
};

export type LayoutNodeFilterInput = {
  type?: InputMaybe<LayoutNodeFilterType>;
  values: Array<Scalars['String']['input']>;
};

export type LayoutNodeFilterParameter = {
  action?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  label?: InputMaybe<StringOperators>;
  layoutId?: InputMaybe<IdOperators>;
  position?: InputMaybe<NumberOperators>;
  productId?: InputMaybe<IdOperators>;
  showImage?: InputMaybe<BooleanOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export enum LayoutNodeFilterType {
  CATEGORY = 'CATEGORY',
  DEPARTMENT = 'DEPARTMENT'
}

export type LayoutNodeInput = {
  /** required when type is ACTION */
  action?: InputMaybe<LayoutNodeActionType>;
  /** Background color of node */
  color?: InputMaybe<Scalars['HexColorCode']['input']>;
  /** filter for products */
  filter?: InputMaybe<LayoutNodeFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** manually set the lable */
  label?: InputMaybe<Scalars['String']['input']>;
  /** required when creating */
  layoutId?: InputMaybe<Scalars['ID']['input']>;
  /** required when action is CUSTOM_URL */
  link?: InputMaybe<LayoutNodeLinkInput>;
  /** required when type PRODUCT */
  position?: InputMaybe<Scalars['Int']['input']>;
  /** required when type PRODUCT */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** manually show image */
  showImage?: InputMaybe<Scalars['Boolean']['input']>;
  /** required when creating. cant be changed once saved */
  type?: InputMaybe<LayoutNodeType>;
};

export type LayoutNodeLink = {
  __typename?: 'LayoutNodeLink';
  newTab: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export type LayoutNodeLinkInput = {
  newTab: Scalars['Boolean']['input'];
  url: Scalars['String']['input'];
};

export type LayoutNodeList = PaginatedList & {
  __typename?: 'LayoutNodeList';
  items: Array<LayoutNode>;
  meta: PaginatedListMeta;
};

export type LayoutNodeListExtendedFilterOptions = {
  value?: InputMaybe<StringOperators>;
};

export type LayoutNodeListOptions = {
  extendedFilter?: InputMaybe<LayoutNodeListExtendedFilterOptions>;
  filter?: InputMaybe<LayoutNodeFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LayoutNodeSortParameter>;
};

export type LayoutNodeSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  layoutId?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum LayoutNodeType {
  /** ACTION */
  ACTION = 'ACTION',
  /** FILTER */
  FILTER = 'FILTER',
  /** PRODUCT */
  PRODUCT = 'PRODUCT'
}

export type LayoutSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  terminalId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LinklyConfiguration = {
  __typename?: 'LinklyConfiguration';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  pairedPinpads: Array<LinklyPairedPinpad>;
  paymentMethodId: Scalars['String']['output'];
  receiptAutoPrint: ReceiptAutoPrintType;
  updatedAt: Scalars['DateTime']['output'];
};

export type LinklyConfigurationInput = {
  receiptAutoPrint?: InputMaybe<ReceiptAutoPrintType>;
};

/** The POS should allow the operator to try the request again. */
export type LinklyError = ErrorResult & {
  __typename?: 'LinklyError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type LinklyPairPinpadInput = {
  name: Scalars['String']['input'];
  pairCode: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type LinklyPairedPinpad = {
  __typename?: 'LinklyPairedPinpad';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  linklyConfigurationId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  secret: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Location = EntityNode & {
  __typename?: 'Location';
  businessName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  retailerLocationKey: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LocationFilterParameter = {
  businessName?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  retailerLocationKey?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type LocationList = PaginatedList & {
  __typename?: 'LocationList';
  items: Array<Location>;
  meta: PaginatedListMeta;
};

export type LocationListOptions = {
  filter?: InputMaybe<LocationFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LocationSortParameter>;
};

export type LocationSortParameter = {
  businessName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  retailerLocationKey?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR'
}

export type Markup = {
  __typename?: 'Markup';
  markupPercentage: Scalars['Float']['output'];
  markupStyle: MarkupStyle;
  markupType: MarkupType;
};

export type MarkupInput = {
  markupPercentage: Scalars['Float']['input'];
  markupStyle: MarkupStyle;
  markupType: MarkupType;
};

export enum MarkupStyle {
  ABOVE_COST = 'ABOVE_COST',
  OFF_RETAIL = 'OFF_RETAIL'
}

export enum MarkupType {
  RETAIL = 'RETAIL',
  TRADE = 'TRADE',
  WEB = 'WEB'
}

export type MfaAlreadySetupError = ErrorResult & {
  __typename?: 'MfaAlreadySetupError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export enum MfaCodeStrategy {
  /** The code is sent to the user via email */
  EMAIL = 'EMAIL',
  /** The code is sent to the user via SMS */
  SMS = 'SMS'
}

export type MfaMethod = EntityNode & {
  __typename?: 'MfaMethod';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  strategy: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  verified: Scalars['Boolean']['output'];
};

/** Valid AUTH, but MFA code is required for this user for authentication strategy */
export type MfaRequiredError = ErrorResult & {
  __typename?: 'MfaRequiredError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
  /**
   * The obscured MFA strategy that is required for this user.
   * Can be used to determine which MFA strategy to use to prompt the user.
   */
  strategies?: Maybe<Array<MfaCodeStrategy>>;
};

export type MimeTypeError = ErrorResult & {
  __typename?: 'MimeTypeError';
  errorCode: ErrorCode;
  fileName: Scalars['String']['output'];
  message: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
};

/** Returned when attempting to register or verify a customer account without a password, when one is required. */
export type MissingPasswordError = ErrorResult & {
  __typename?: 'MissingPasswordError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type MovementItemProductSummary = {
  __typename?: 'MovementItemProductSummary';
  onHand?: Maybe<Scalars['Int']['output']>;
  productId: Scalars['ID']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  stockCode?: Maybe<Scalars['String']['output']>;
  stockNumber?: Maybe<Scalars['String']['output']>;
};

export type MovementItemProductSummaryOptions = {
  locationId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add or update an exisitng inventory movement item */
  addItemToInventoryMovement: InventoryMovementItem;
  /** Add or update an exisitng inventory movement item */
  addItemToStocktake: StocktakeItem;
  addPaymentMethodToTerminal: Terminal;
  /** Add a product to a parent product as a variant */
  addToParentProduct: Product;
  /** Create or update a stock count for this stocktake */
  adjustStockItem: InventoryMovementItem;
  applyDefaultLayoutConfiguration: Layout;
  /** Authenticates the user (store owner, or staff) using a named authentication strategy */
  authenticate: AuthenticationResultV2;
  /** Bulk add or update (up to 250) inventory movement item */
  bulkAddItemToInventoryMovement: Success;
  /** Bulk add or update (up to 250) inventory movement item */
  bulkAddItemToStocktake: Success;
  /** Bulk update existing serial numbers for a product */
  bulkUpdateSerialNumbersForProduct: Array<Maybe<SerialNumber>>;
  /** Cancel an existing invoice arrival */
  cancelInvoiceArrival: InvoiceArrival;
  /** Cancel a sale */
  cancelSale: Sale;
  /** Cancel an existing stock order */
  cancelStockOrder: StockOrder;
  /** Transition stocktake to STOCKTAKE_CANCELLED */
  cancelStocktake: InventoryMovement;
  /** Close a single terminal */
  closeTerminal: TerminalClosure;
  /** Close terminals linked together by a Drawer */
  closeTerminals: Array<Maybe<TerminalClosure>>;
  /**
   * Complete the invoice arrival (STATE === COMPLETE), this will lock the invoice, and sent to retailer for
   * processing.
   */
  completeInvoiceArrival: InvoiceArrival;
  /**
   * Complete a stock take. Only valid for source = INTERNAL
   * cannot be modified once completed
   */
  completeStockCount: StockCount;
  /**
   * Complete the stock order, it will lock the order, and send to
   * retailer for processing.
   */
  completeStockOrder: StockOrder;
  /** Transition stocktake to COMPLETE */
  completeStocktake: InventoryMovement;
  /** Convert a product to a variant using a given template */
  convertMainProductFromTemplate: Product;
  /** Adds a new stock count item to a stock count */
  countStockCountItem: StockCountItem;
  /** Bulk count stocktake items, up to 25 at a time */
  countStockCountItems: Success;
  /** Create or update a stock count for this stocktake */
  countStockItem: InventoryMovementItem;
  /** Count item and immediately stock take it */
  countStockItemAndQuickStocktake: InventoryMovementItem;
  /** Create a cash movement for a terminal */
  createCashMovement: CashMovement;
  /** Create a new Category */
  createCategory: Category;
  /** Create a new Customer */
  createCustomer: Customer;
  /** Create a new Department */
  createDepartment: Department;
  /** Create a docket template */
  createDocketTemplate: DocketTemplate;
  createExpressAccountAndRequestAccountLinkUrl?: Maybe<Scalars['String']['output']>;
  /** Create a new invoice arrival */
  createInvoiceArrival: InvoiceArrival;
  createLayout: Layout;
  createLayoutNode: LayoutNode;
  createLocation: Location;
  createLoginLink?: Maybe<Scalars['String']['output']>;
  createNormalAccountAndRequestAccountLinkUrl?: Maybe<Scalars['String']['output']>;
  /** Create a new payment method */
  createPaymentMethod: PaymentMethod;
  /** Create a new Product */
  createProduct: Product;
  /** Upload (and replace ) a product image */
  createProductImage: CreateImageResult;
  createProductImageBase64: CreateImageResult;
  /** Creates a product group template */
  createProductOptionGroupTemplate: ProductOptionGroupTemplate;
  createReceipt: Receipt;
  createRetailerPayType: RetailerPayType;
  /** Create a new sale from a terminal */
  createSale: Sale;
  /** Create a new SerialNumber */
  createSerialNumber: SerialNumber;
  createStaff: Staff;
  /** Create a user account for a staff member. The user account will be able to login with their own credentials. */
  createStaffUserAccount: Staff;
  /** Creates a new stock count using information from retailer */
  createStockCount: StockCount;
  /** Create a new stock order */
  createStockOrder: StockOrder;
  /** Create a new stocktake in STOCKTAKE_PLANNED */
  createStocktake: InventoryMovement;
  /** Create a new stocktake in STOCKTAKE_UNDERWAY AND then begin transition to STOCKTAKE_UNDERWAY => STOCKTAKE_UNDERWAY_PROCESSED */
  createStocktakeUnderway: InventoryMovement;
  createSupplier: Supplier;
  /** Create a new Tax Group */
  createTaxGroup: TaxGroup;
  createTerminal: Terminal;
  /**
   * Create a new variant for a product
   * using the parents product's details,  group template & the given options
   */
  createVariantForProduct: Product;
  /** Delete */
  deleteAccessToken: Deleted;
  /** Delete a Category */
  deleteCategory: DeletionResponse;
  /** Delete a Product */
  deleteCustomer: DeletionResponse;
  /** Delete a Department */
  deleteDepartment: DeletionResponse;
  /** Delete a docket template */
  deleteDocketTemplate: DeletionResponse;
  /** Delete an existing CANCELLED invoice arrival */
  deleteInvoiceArrival: DeletionResponse;
  deleteLayout: DeletionResponse;
  deleteLayoutNode: DeletionResponse;
  /** Delete an existing payment method */
  deletePaymentMethod: Deleted;
  deleteProductImage: DeletionResponse;
  deleteReceipt: Deleted;
  deleteRetailerPayType: RetailerPayType;
  deleteSelfCheckoutLocationSettings: Success;
  /** Delete an existing serial number */
  deleteSerialNumber: DeletionResponse;
  /** Delete a user account for a staff member. The staff member will no longer be able to login with their own credentials.  */
  deleteStaffUserAccount: Success;
  /** Delete a canceled stock order */
  deleteStockOrder: DeletionResponse;
  deleteSupplier: Success;
  /** Delete a Tax Group */
  deleteTaxGroup: DeletionResponse;
  downloadReceiptPdfBase64String: DownloadReceiptPdfBase64StringResponse;
  /** Download the stock count as a CSV file */
  downloadStockCountCsvBase64String: DownloadStocktakeCsvBase64StringResponse;
  downloadStocktakeCsvBase64String: DownloadStocktakeCsvBase64StringResponse;
  /** Duplicate an existing product to create a new Product */
  duplicateProduct: Product;
  /** Send a summary of a terminal closure via email */
  emailClosureSummary: Success;
  /**
   * export all pending stocktake counts to retailer for a given stocktake
   * note: Runs asynchronously
   *
   * can only be used when stocktake source is RETAILER
   */
  exportPendingStocktakeCountItems: Success;
  exportSale: Success;
  /** export a single stocktake count to retailer for a given stocktake */
  exportStockCountItemSyncronously: ExportStockCountItemResult;
  /**
   * export a maximum of 25 of stocktake counts to retailer for a given stocktake
   * note: Runs asynchronously
   */
  exportStockCountItems: Success;
  /** Generate a long lasting access token  */
  generateAccessToken: AccessTokenSession;
  generateProductBarcode: Scalars['String']['output'];
  /** Clone a sale, and return the new sale */
  initiateReturnSale: Sale;
  /** Adjust the inventory */
  inventoryAdjustQuantity: InventoryLevel;
  /** Login with a valid talink token */
  loginWithTaLinkToken: NativeAuthenticationResult;
  /** Open a single terminal */
  openTerminal: TerminalClosure;
  /** Create a new Linkly configuration for a given (linkly) payment method ID. */
  pairPinpad: PairLinklyPinpadResult;
  /** Preview the result of removing the background from an image */
  previewRemoveBackgroundBase64: NativeRemoveBackgroundBase64Result;
  /** Immedietly stocktake item. Can no longer be modified once done */
  quickStocktakeStockItem: InventoryMovementItem;
  /**
   * Remove the given 2fa strategy from the current user.
   * A code will be sent to the strategy to confirm the removal
   * by calling requestMfaCode with the user's credentials
   */
  remove2faStrategy: Remove2faStrategyResult;
  /** Remove the given 2fa strategy from the given user. (Only available to owner) */
  remove2faStrategyForStaff: Success;
  removeBackgroundBase64: NativeRemoveBackgroundBase64Result;
  /** Removes the specific prodct variant from the inventory movement */
  removeItemFromInventoryMovement: DeletionResponse;
  /** Removes the specific prodct variant from the inventory movement */
  removeItemFromStocktake: DeletionResponse;
  /** Remove a Linkly configuration for a given (linkly) payment method ID. */
  removePinpad: Success;
  /** Remove a product from a parent product */
  removeProductFromParent: Product;
  /** Deletes a product option group template by its ID. */
  removeProductOptionGroupTemplate: DeletionResponse;
  /** Remove item from stocktake */
  removeStocktakeItem: DeletionResponse;
  /**
   * Request a new MFA code to be sent to the user's preferred MFA method.
   * Credentials are required, as the user is not authenticated yet as
   * no sesion is granted untill the MFA code is validated.
   */
  requestMfaCode: Success;
  saleTransferBase64String: Scalars['String']['output'];
  /** Send a COMPLETE invoice arrival to the retailer for processing */
  sendInvoiceArrivalInboundRetailer: Success;
  /** Send formatted product request the retailer for processing */
  sendProductInboundRetailer: Success;
  sendReceipt: Success;
  /** Send a COMPLETE stock count to the retailer for processing */
  sendStockOrderInboundRetailer: Success;
  sendStocktake: Success;
  /**
   * Request to setup 2fa for the current authenticated user
   * using the given strategy
   *
   * Depending on the strategy, this may send an email, or sms for native 2fa
   */
  setup2faStrategy: Setup2faStrategyResult;
  shiftLayoutNodes: Array<LayoutNode>;
  /** soft delete a product */
  softDeleteProduct: DeletionResponse;
  /** Transition stocktake to STOCKTAKE_UNDERWAY then automatically begin transition to STOCKTAKE_UNDERWAY_PROCESSED */
  stocktakeUnderway: InventoryMovement;
  swapLayoutNodes: SwapLayoutNodesResult;
  /**
   * Once authenticated, can switch to a different user (staff).
   * MFA is still required if switching to owner (or admin) account.
   */
  switchUser: SwitchUserResult;
  syncTaLinkLocations: Array<Location>;
  transferWebSale: Sale;
  unlinkStripeAccount: Success;
  /** Update access token */
  updateAccessToken: AccessTokenSession;
  /** Update an existing Category */
  updateCategory: Category;
  /** Update an existing Customer */
  updateCustomer: Customer;
  /** Update an existing Department */
  updateDepartment: Department;
  /** Update a docket template */
  updateDocketTemplate: DocketTemplate;
  updateGlobalSettings: GlobalSettings;
  /** Update an existing invoice arrival */
  updateInvoiceArrival: InvoiceArrival;
  /** Update the line items of an existing invoice arrival */
  updateInvoiceArrivalLineItems: InvoiceArrival;
  updateLayout: Layout;
  updateLayoutNode: LayoutNode;
  /**
   * Update the positions of layout nodes. The input array can contain partial layout.
   * There must be no duplicates in positions or gaps in the both the combined input and stored result.
   */
  updateLayoutNodePositions: Array<LayoutNode>;
  updateLinklyConfiguration: LinklyConfiguration;
  updateLocation: Location;
  /** Update the current authenticated users details. (Only available to owner rn) */
  updateMe: UserV2;
  /** Update an existing payment method */
  updatePaymentMethod: PaymentMethod;
  /** Update a existing product */
  updateProduct: Product;
  /** Update the position of a product image */
  updateProductImagePosition: Array<ProductImage>;
  /** Updates a product option group template. */
  updateProductOptionGroupTemplate: ProductOptionGroupTemplate;
  updateReceipt: Receipt;
  updateRetailerPayType: RetailerPayType;
  /** Create a new sale from a terminal */
  updateSale: Sale;
  updateSelfCheckoutLocationSettings: SelfCheckoutLocationSettings;
  updateSelfCheckoutSettings: SelfCheckoutSettings;
  /** Update existing Serial Number */
  updateSerialNumber: SerialNumber;
  updateStaff: Staff;
  /** Update user account for a staff member */
  updateStaffUserAccount: Staff;
  updateStockOrderLineItems: StockOrder;
  /** Update the existing details of a Stocktake at any point before CANCELLED or COMPLETE */
  updateStocktakeDetails: InventoryMovement;
  updateSupplier: Supplier;
  /** Update an existing Tax Group */
  updateTaxGroup: TaxGroup;
  updateTerminal: Terminal;
  uploadImageToRetailerBySKU: Success;
  uploadImagesToRetailerByImageId: Success;
  /**
   * Verify the given 2fa strategy and code.
   * 2fa will not be enabled until this is called.
   */
  verify2faStrategy: Verify2faStrategyResult;
  /** Void a sale */
  voidSale: Sale;
};


export type MutationAddItemToInventoryMovementArgs = {
  input: AddItemToInventoryMovementInput;
  inventoryMovementId: Scalars['ID']['input'];
};


export type MutationAddItemToStocktakeArgs = {
  input: AddItemToStocktakeInput;
  stocktakeId: Scalars['ID']['input'];
};


export type MutationAddPaymentMethodToTerminalArgs = {
  paymentMethodId: Scalars['ID']['input'];
  terminalId: Scalars['ID']['input'];
};


export type MutationAddToParentProductArgs = {
  input: AddToParentProductInput;
};


export type MutationAdjustStockItemArgs = {
  input: AdjustStockItemInput;
  stocktakeId: Scalars['ID']['input'];
};


export type MutationApplyDefaultLayoutConfigurationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationAuthenticateArgs = {
  input: AuthenticationInput;
  mfa?: InputMaybe<AuthenticationMfaInput>;
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationBulkAddItemToInventoryMovementArgs = {
  input: Array<InputMaybe<AddItemToInventoryMovementInput>>;
  inventoryMovementId: Scalars['ID']['input'];
};


export type MutationBulkAddItemToStocktakeArgs = {
  input: Array<InputMaybe<AddItemToStocktakeInput>>;
  stocktakeId: Scalars['ID']['input'];
};


export type MutationBulkUpdateSerialNumbersForProductArgs = {
  productLocationInventoryPricingId: Scalars['ID']['input'];
  serialNumbersToCreate?: InputMaybe<Array<InputMaybe<CreateSerialNumberInput>>>;
  serialNumbersToRemove?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  serialNumbersToUpdate?: InputMaybe<Array<InputMaybe<UpdateSerialNumberInput>>>;
};


export type MutationCancelInvoiceArrivalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCancelSaleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCancelStockOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCancelStocktakeArgs = {
  stocktakeId: Scalars['ID']['input'];
};


export type MutationCloseTerminalArgs = {
  input: CloseTerminalInput;
};


export type MutationCloseTerminalsArgs = {
  input: Array<InputMaybe<CloseTerminalInput>>;
};


export type MutationCompleteInvoiceArrivalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCompleteStockCountArgs = {
  stockCountId: Scalars['ID']['input'];
};


export type MutationCompleteStockOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCompleteStocktakeArgs = {
  stocktakeId: Scalars['ID']['input'];
};


export type MutationConvertMainProductFromTemplateArgs = {
  input: ConvertMainProductFromTemplateInput;
};


export type MutationCountStockCountItemArgs = {
  input: CountStockCountItemInput;
  stockCountId: Scalars['ID']['input'];
};


export type MutationCountStockCountItemsArgs = {
  input: Array<CountStockCountItemInput>;
  stockCountId: Scalars['ID']['input'];
};


export type MutationCountStockItemArgs = {
  input: CountStockItemInput;
  stocktakeId: Scalars['ID']['input'];
};


export type MutationCountStockItemAndQuickStocktakeArgs = {
  input: CountStockItemInput;
  stocktakeId: Scalars['ID']['input'];
};


export type MutationCreateCashMovementArgs = {
  input: CashMovementInput;
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationCreateCustomerArgs = {
  input: CustomerInput;
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};


export type MutationCreateDocketTemplateArgs = {
  input: CreateDocketTemplateInput;
};


export type MutationCreateExpressAccountAndRequestAccountLinkUrlArgs = {
  input?: InputMaybe<CreateStripeExpressAccountInput>;
};


export type MutationCreateInvoiceArrivalArgs = {
  input: CreateInvoiceArrivalInput;
};


export type MutationCreateLayoutArgs = {
  input: LayoutInput;
};


export type MutationCreateLayoutNodeArgs = {
  input: LayoutNodeInput;
};


export type MutationCreateLocationArgs = {
  input?: InputMaybe<CreateLocationInput>;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationCreateProductImageArgs = {
  input: CreateProductImageInput;
};


export type MutationCreateProductImageBase64Args = {
  input: CreateProductImageBase64Input;
  productId: Scalars['ID']['input'];
};


export type MutationCreateProductOptionGroupTemplateArgs = {
  input: CreateProductOptionGroupTemplateInput;
};


export type MutationCreateReceiptArgs = {
  input: ReceiptInput;
};


export type MutationCreateRetailerPayTypeArgs = {
  input: CreateRetailerPayTypeInput;
};


export type MutationCreateSaleArgs = {
  input: CreateSaleInput;
};


export type MutationCreateSerialNumberArgs = {
  input: CreateSerialNumberInput;
  productLocationInventoryPricingId: Scalars['ID']['input'];
};


export type MutationCreateStaffArgs = {
  input: CreateStaffInput;
};


export type MutationCreateStaffUserAccountArgs = {
  input: CreateStaffUserAccountInput;
};


export type MutationCreateStockCountArgs = {
  input: CreateStockCountInput;
};


export type MutationCreateStockOrderArgs = {
  input: CreateStockOrderInput;
};


export type MutationCreateStocktakeArgs = {
  input: StocktakeCreateInput;
};


export type MutationCreateStocktakeUnderwayArgs = {
  input: StocktakeCreateInput;
};


export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};


export type MutationCreateTaxGroupArgs = {
  input: CreateTaxGroupInput;
};


export type MutationCreateTerminalArgs = {
  input?: InputMaybe<CreateTerminalInput>;
};


export type MutationCreateVariantForProductArgs = {
  input: CreateVariantForProductInput;
};


export type MutationDeleteAccessTokenArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDepartmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDocketTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInvoiceArrivalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLayoutArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLayoutNodeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePaymentMethodArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProductImageArgs = {
  imageId: Scalars['ID']['input'];
};


export type MutationDeleteReceiptArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRetailerPayTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSelfCheckoutLocationSettingsArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationDeleteSerialNumberArgs = {
  serialNumberId: Scalars['ID']['input'];
};


export type MutationDeleteStaffUserAccountArgs = {
  staffId: Scalars['ID']['input'];
};


export type MutationDeleteStockOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSupplierArgs = {
  supplierId: Scalars['ID']['input'];
};


export type MutationDeleteTaxGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDownloadReceiptPdfBase64StringArgs = {
  receiptId?: InputMaybe<Scalars['ID']['input']>;
  saleId: Scalars['ID']['input'];
};


export type MutationDownloadStockCountCsvBase64StringArgs = {
  stockCountId: Scalars['ID']['input'];
};


export type MutationDownloadStocktakeCsvBase64StringArgs = {
  stocktakeId: Scalars['ID']['input'];
};


export type MutationDuplicateProductArgs = {
  input: DuplicateProductInput;
};


export type MutationEmailClosureSummaryArgs = {
  input: EmailClosureSummaryInput;
};


export type MutationExportPendingStocktakeCountItemsArgs = {
  stockCountId: Scalars['ID']['input'];
};


export type MutationExportSaleArgs = {
  exportType: ExportSaleType;
  saleId: Scalars['ID']['input'];
};


export type MutationExportStockCountItemSyncronouslyArgs = {
  stockCountId: Scalars['ID']['input'];
  stockCountItemId: Scalars['ID']['input'];
};


export type MutationExportStockCountItemsArgs = {
  stocktakeCountIds: Array<Scalars['ID']['input']>;
};


export type MutationGenerateAccessTokenArgs = {
  input: GenerateAccessTokenInput;
};


export type MutationInitiateReturnSaleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationInventoryAdjustQuantityArgs = {
  input: InventoryAdjustQuantityInput;
};


export type MutationLoginWithTaLinkTokenArgs = {
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['String']['input'];
};


export type MutationOpenTerminalArgs = {
  input: OpenTerminalInput;
};


export type MutationPairPinpadArgs = {
  input?: InputMaybe<LinklyPairPinpadInput>;
  paymentMethodId: Scalars['ID']['input'];
};


export type MutationPreviewRemoveBackgroundBase64Args = {
  base64: Scalars['String']['input'];
};


export type MutationQuickStocktakeStockItemArgs = {
  productId: Scalars['ID']['input'];
  stocktakeId: Scalars['ID']['input'];
};


export type MutationRemove2faStrategyArgs = {
  code: Scalars['String']['input'];
  strategy: MfaCodeStrategy;
};


export type MutationRemove2faStrategyForStaffArgs = {
  strategy: MfaCodeStrategy;
  userId: Scalars['ID']['input'];
};


export type MutationRemoveBackgroundBase64Args = {
  base64: Scalars['String']['input'];
};


export type MutationRemoveItemFromInventoryMovementArgs = {
  inventoryMovementId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};


export type MutationRemoveItemFromStocktakeArgs = {
  productId: Scalars['ID']['input'];
  stocktakeId: Scalars['ID']['input'];
};


export type MutationRemovePinpadArgs = {
  pinpadId: Scalars['ID']['input'];
};


export type MutationRemoveProductFromParentArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationRemoveProductOptionGroupTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveStocktakeItemArgs = {
  productId: Scalars['ID']['input'];
  stocktakeId: Scalars['ID']['input'];
};


export type MutationRequestMfaCodeArgs = {
  input: AuthenticationInput;
  mfaStrategy: MfaCodeStrategy;
};


export type MutationSaleTransferBase64StringArgs = {
  saleId: Scalars['ID']['input'];
};


export type MutationSendInvoiceArrivalInboundRetailerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSendProductInboundRetailerArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationSendReceiptArgs = {
  input?: InputMaybe<SendReceiptInput>;
};


export type MutationSendStockOrderInboundRetailerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSendStocktakeArgs = {
  input?: InputMaybe<SendStocktakeInput>;
};


export type MutationSetup2faStrategyArgs = {
  input: Setup2faStrategyInput;
};


export type MutationShiftLayoutNodesArgs = {
  input: ShiftLayoutNodesInput;
};


export type MutationSoftDeleteProductArgs = {
  id: Scalars['ID']['input'];
};


export type MutationStocktakeUnderwayArgs = {
  stocktakeId: Scalars['ID']['input'];
};


export type MutationSwapLayoutNodesArgs = {
  input: SwapLayoutNodesInput;
};


export type MutationSwitchUserArgs = {
  input: AuthenticationInput;
  mfa?: InputMaybe<AuthenticationMfaInput>;
};


export type MutationTransferWebSaleArgs = {
  saleId: Scalars['ID']['input'];
};


export type MutationUpdateAccessTokenArgs = {
  input: UpdateAccessTokenInput;
};


export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};


export type MutationUpdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};


export type MutationUpdateDocketTemplateArgs = {
  input: UpdateDocketTemplateInput;
};


export type MutationUpdateGlobalSettingsArgs = {
  input: UpdateGlobalSettingsInput;
};


export type MutationUpdateInvoiceArrivalArgs = {
  input: UpdateInvoiceArrivalInput;
};


export type MutationUpdateInvoiceArrivalLineItemsArgs = {
  invoiceArrivalId: Scalars['ID']['input'];
  itemsToCreate?: InputMaybe<Array<CreateInvoiceArrivalLineItemInput>>;
  itemsToRemove?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemsToUpdate?: InputMaybe<Array<UpdateInvoiceArrivalLineItemInput>>;
};


export type MutationUpdateLayoutArgs = {
  input: LayoutInput;
};


export type MutationUpdateLayoutNodeArgs = {
  input: LayoutNodeInput;
};


export type MutationUpdateLayoutNodePositionsArgs = {
  input: Array<UpdateLayoutNodeInput>;
  layoutId: Scalars['ID']['input'];
};


export type MutationUpdateLinklyConfigurationArgs = {
  input: LinklyConfigurationInput;
  paymentMethodId: Scalars['ID']['input'];
};


export type MutationUpdateLocationArgs = {
  input?: InputMaybe<UpdateLocationInput>;
};


export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


export type MutationUpdateProductImagePositionArgs = {
  input: UpdateProductImageInput;
};


export type MutationUpdateProductOptionGroupTemplateArgs = {
  input: UpdateProductOptionGroupTemplateInput;
};


export type MutationUpdateReceiptArgs = {
  input: ReceiptInput;
};


export type MutationUpdateRetailerPayTypeArgs = {
  input: UpdateRetailerPayTypeInput;
};


export type MutationUpdateSaleArgs = {
  input: UpdateSaleInput;
};


export type MutationUpdateSelfCheckoutLocationSettingsArgs = {
  input: UpdateSelfCheckoutLocationSettingsInput;
};


export type MutationUpdateSelfCheckoutSettingsArgs = {
  input: UpdateSelfCheckoutSettingsInput;
};


export type MutationUpdateSerialNumberArgs = {
  input: UpdateSerialNumberInput;
};


export type MutationUpdateStaffArgs = {
  input: UpdateStaffInput;
};


export type MutationUpdateStaffUserAccountArgs = {
  input: UpdateStaffUserAccountInput;
};


export type MutationUpdateStockOrderLineItemsArgs = {
  itemsToCreate?: InputMaybe<Array<CreateStockOrderLineItemInput>>;
  itemsToRemove?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemsToUpdate?: InputMaybe<Array<UpdateStockOrderLineItemInput>>;
  stockOrderId: Scalars['ID']['input'];
};


export type MutationUpdateStocktakeDetailsArgs = {
  input: StocktakeDetailsUpdateInput;
  stocktakeId: Scalars['ID']['input'];
};


export type MutationUpdateSupplierArgs = {
  input: UpdateSupplierInput;
};


export type MutationUpdateTaxGroupArgs = {
  input: UpdateTaxGroupInput;
};


export type MutationUpdateTerminalArgs = {
  input?: InputMaybe<UpdateTerminalInput>;
};


export type MutationUploadImageToRetailerBySkuArgs = {
  input: UploadImageToRetailerBySkuInput;
};


export type MutationUploadImagesToRetailerByImageIdArgs = {
  imageIds: Array<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
};


export type MutationVerify2faStrategyArgs = {
  code: Scalars['String']['input'];
  credentials: AuthenticationInput;
  strategy: MfaCodeStrategy;
};


export type MutationVoidSaleArgs = {
  id: Scalars['ID']['input'];
};

export type NativeAuthStrategyError = ErrorResult & {
  __typename?: 'NativeAuthStrategyError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type NativeAuthenticationResult = AuthenticationResult | InvalidCredentialsError | NativeAuthStrategyError;

export type NativeRemoveBackgroundBase64Result = BackgroundRemoverInsufficientCreditsError | BackgroundRemoverNotConfiguredError | RemoveBackgroundBase64Result;

/** Returned when attempting to set a SaleItem quantity to a negative value. */
export type NegativeQuantityError = ErrorResult & {
  __typename?: 'NegativeQuantityError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

/**
 * Returned if `authOptions.requireVerification` is set to `true`
 * and an unverified user attempts to authenticate.
 */
export type NotVerifiedError = ErrorResult & {
  __typename?: 'NotVerifiedError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

/** Operators for filtering on a list of Number fields */
export type NumberListOperators = {
  inList: Array<InputMaybe<Scalars['Float']['input']>>;
};

export type NumberOperators = {
  between?: InputMaybe<NumberRange>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type NumberRange = {
  end: Scalars['Float']['input'];
  start: Scalars['Float']['input'];
};

export type OpenTerminalInput = {
  /** Optional open float amount, will override any other default float values */
  openFloatAmount?: InputMaybe<Scalars['Decimal']['input']>;
  terminalId: Scalars['ID']['input'];
};

export type PaginatedList = {
  items: Array<EntityNode>;
  meta: PaginatedListMeta;
};

export type PaginatedListMeta = PaginatedListMetaInterface & {
  __typename?: 'PaginatedListMeta';
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemCount?: Maybe<Scalars['Int']['output']>;
  totalItems?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedListMetaInterface = {
  currentPage?: Maybe<Scalars['Int']['output']>;
  itemCount?: Maybe<Scalars['Int']['output']>;
  totalItems?: Maybe<Scalars['Int']['output']>;
};

export type PairLinklyPinpadResult = InvalidLinklyCredentialsError | LinklyError | LinklyPairedPinpad;

/** Returned when attempting to verify a customer account with a password, when a password has already been set. */
export type PasswordAlreadySetError = ErrorResult & {
  __typename?: 'PasswordAlreadySetError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

/** Returned when attempting to register or verify a customer account where the given password fails password validation. */
export type PasswordValidationError = ErrorResult & {
  __typename?: 'PasswordValidationError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
  validationErrorMessage: Scalars['String']['output'];
};

export type PayType = {
  __typename?: 'PayType';
  available: Scalars['Boolean']['output'];
  channels: Array<Scalars['String']['output']>;
  configurationUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  defaultGatewayUrl?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gateway: Scalars['Boolean']['output'];
  gatewayEditable: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  internal: Scalars['Boolean']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  onlineOnly: Scalars['Boolean']['output'];
  platforms: Array<Scalars['String']['output']>;
  /** Can only have 1 of this type */
  restricted: Scalars['Boolean']['output'];
  subtext?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['Int']['output'];
};

export type PayTypeGatewayConfig = {
  __typename?: 'PayTypeGatewayConfig';
  url: Scalars['String']['output'];
};

export type PayTypeListFilterOptions = {
  channels?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PayTypeRoundingConfig = {
  __typename?: 'PayTypeRoundingConfig';
  rounding: Scalars['Decimal']['output'];
};

export type Payment = EntityNode & {
  __typename?: 'Payment';
  amount: Scalars['Float']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  paymentDate: Scalars['DateTime']['output'];
  paymentMethodId: Scalars['ID']['output'];
  paymentTypeId: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethod = EntityNode & {
  __typename?: 'PaymentMethod';
  configuration?: Maybe<PaymentMethodConfiguration>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  payTypeId: Scalars['String']['output'];
  paytype: PayType;
  /** Deprecated, (but backwards compatible) use retailer pay code Id */
  retailerPayCode?: Maybe<Scalars['String']['output']>;
  retailerPayTypeId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentMethodConfiguration = PayTypeGatewayConfig | PayTypeRoundingConfig;

export type PaymentMethodConfigurationInput = {
  rounding?: InputMaybe<Scalars['Decimal']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentMethodFilterParameter = {
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  payTypeId?: InputMaybe<StringOperators>;
  retailerPayCode?: InputMaybe<StringOperators>;
  retailerPayTypeId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type PaymentMethodList = PaginatedList & {
  __typename?: 'PaymentMethodList';
  items: Array<PaymentMethod>;
  meta: PaginatedListMeta;
};

export type PaymentMethodListOptions = {
  filter?: InputMaybe<PaymentMethodFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<PaymentMethodSortParameter>;
};

export type PaymentMethodSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  payTypeId?: InputMaybe<SortOrder>;
  retailerPayCode?: InputMaybe<SortOrder>;
  retailerPayTypeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum Permission {
  /** Authenticated means that the user is logged in as a (Store) Administrator */
  AdministratorAccess = 'AdministratorAccess',
  /** Alias for Administrator. Authenticated means that the user is logged in as a Administrator */
  AuthenticatedAccess = 'AuthenticatedAccess',
  /** AuthenticatedAccessTokenAccess means that the user is logged in as a Administrator */
  AuthenticatedAccessToken = 'AuthenticatedAccessToken',
  /** Public means any unauthenticated user may perform the operation */
  CustomerAuthenticatedAccess = 'CustomerAuthenticatedAccess',
  /** Grants permission to Manage Customers */
  ManageCustomers = 'ManageCustomers',
  /** Grants permission to Manage Departments */
  ManageDepartments = 'ManageDepartments',
  /** Grants permission to Manage Products */
  ManageProducts = 'ManageProducts',
  /** Grants permission to Manage Sales */
  ManageSales = 'ManageSales',
  /** Grants permission to Manage settings */
  ManageSettings = 'ManageSettings',
  /** Owner means the user owns this entity, e.g. a Customer's own Order */
  Owner = 'Owner',
  /** Grants access to Point of Sale App */
  PointOfSale = 'PointOfSale',
  /** Public means any unauthenticated user may perform the operation */
  PublicAccess = 'PublicAccess',
  /** Grants permission to view Analytics */
  ReadAnalytics = 'ReadAnalytics',
  /** StaffAuthenticatedAccess means that the user is logged in as a (Store) Staff Member */
  StaffAuthenticatedAccess = 'StaffAuthenticatedAccess',
  /** Grants access to Stock take App */
  Stocktake = 'Stocktake',
  /** SuperPlatformAdmin has access to private operations. They are an employee of the Platform, not a Store */
  SuperPlatformAdmin = 'SuperPlatformAdmin',
  /** Grants access to Utilties App */
  Utilities = 'Utilities'
}

export type Product = EntityNode & {
  __typename?: 'Product';
  UOM: Uom;
  active: Scalars['Boolean']['output'];
  adjustable: Scalars['Boolean']['output'];
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  department?: Maybe<Department>;
  departmentId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountable: Scalars['Boolean']['output'];
  /** Product name & Variant name combined */
  displayName: Scalars['String']['output'];
  hasVariants: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Returns the default image for the product */
  image?: Maybe<ProductImage>;
  images: Array<ProductImage>;
  internal: Scalars['Boolean']['output'];
  locationInventoryPricing: Array<ProductLocationInventoryPricing>;
  loyaltyPointEarnValue: Scalars['Int']['output'];
  loyaltyPointRedeemValue: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  options: Array<ProductOption>;
  /** Determined based on location context. null if not applicable */
  price?: Maybe<Scalars['Float']['output']>;
  /** Determined based on location context. null if not applicable */
  priceExcludingTax?: Maybe<Scalars['Float']['output']>;
  priceLookup?: Maybe<Scalars['String']['output']>;
  productOptionGroupTemplate?: Maybe<ProductOptionGroupTemplate>;
  /** The options template to use for this product */
  productOptionGroupTemplateId?: Maybe<Scalars['ID']['output']>;
  selectedOptions: Array<SelectedOption>;
  /** Serial numbers will be required on arrival of stock */
  serialNumbersArr: Scalars['Boolean']['output'];
  /** Serial numbers will be required on sale */
  serialNumbersSale: Scalars['Boolean']['output'];
  stockCode?: Maybe<Scalars['String']['output']>;
  stockNumber: Scalars['String']['output'];
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['String']['output']>;
  supplierStockCode?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  taxGroup?: Maybe<TaxGroup>;
  taxGroupId?: Maybe<Scalars['String']['output']>;
  taxable: Scalars['Boolean']['output'];
  /** Total salable inventory accross all locations */
  totalInventory: Scalars['Float']['output'];
  /** Number of variants (excluding self) */
  totalVariants: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variantParent?: Maybe<Product>;
  variantParentId?: Maybe<Scalars['ID']['output']>;
  variants?: Maybe<Array<Product>>;
  webDescription?: Maybe<Scalars['String']['output']>;
  webPrice?: Maybe<Scalars['Float']['output']>;
  weight: Scalars['Float']['output'];
};

export type ProductFilterParameter = {
  UOM?: InputMaybe<StringOperators>;
  active?: InputMaybe<BooleanOperators>;
  adjustable?: InputMaybe<BooleanOperators>;
  categoryId?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  departmentId?: InputMaybe<StringOperators>;
  description?: InputMaybe<StringOperators>;
  discountable?: InputMaybe<BooleanOperators>;
  displayName?: InputMaybe<StringOperators>;
  hasVariants?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  internal?: InputMaybe<BooleanOperators>;
  loyaltyPointEarnValue?: InputMaybe<NumberOperators>;
  loyaltyPointRedeemValue?: InputMaybe<NumberOperators>;
  mainProduct?: InputMaybe<BooleanOperators>;
  name?: InputMaybe<StringOperators>;
  price?: InputMaybe<NumberOperators>;
  priceExcludingTax?: InputMaybe<NumberOperators>;
  priceLookup?: InputMaybe<StringOperators>;
  productOptionGroupTemplateId?: InputMaybe<IdOperators>;
  serialNumbersArr?: InputMaybe<BooleanOperators>;
  serialNumbersSale?: InputMaybe<BooleanOperators>;
  stockCode?: InputMaybe<StringOperators>;
  stockNumber?: InputMaybe<StringOperators>;
  supplierId?: InputMaybe<StringOperators>;
  supplierStockCode?: InputMaybe<StringOperators>;
  tags?: InputMaybe<StringListOperators>;
  taxGroupId?: InputMaybe<StringOperators>;
  taxable?: InputMaybe<BooleanOperators>;
  term?: InputMaybe<StringOperators>;
  totalInventory?: InputMaybe<NumberOperators>;
  totalVariants?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  variantParentId?: InputMaybe<IdOperators>;
  webDescription?: InputMaybe<StringOperators>;
  webPrice?: InputMaybe<NumberOperators>;
  weight?: InputMaybe<NumberOperators>;
};

export type ProductFoundResult = {
  __typename?: 'ProductFoundResult';
  product?: Maybe<ProductInfo>;
  result?: Maybe<ResultStatus>;
};

export type ProductIdByStockNumberResult = ProductIdResult | StockNumberNotFoundError;

export type ProductIdResult = {
  __typename?: 'ProductIdResult';
  productId: Scalars['String']['output'];
};

export type ProductImage = {
  __typename?: 'ProductImage';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  mineType?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  productId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  width: Scalars['Int']['output'];
};

export type ProductInfo = {
  __typename?: 'ProductInfo';
  description?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  weight?: Maybe<Scalars['String']['output']>;
};

export type ProductList = PaginatedList & {
  __typename?: 'ProductList';
  items: Array<Product>;
  meta: PaginatedListMeta;
};

export type ProductListOptions = {
  filter?: InputMaybe<ProductFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ProductSortParameter>;
};

export type ProductLocationInventoryPricing = {
  __typename?: 'ProductLocationInventoryPricing';
  costPrice: Scalars['Float']['output'];
  costPriceExclTax: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  locationId: Scalars['ID']['output'];
  productId: Scalars['ID']['output'];
  retailPrice: Scalars['Float']['output'];
  rrPrice: Scalars['Float']['output'];
  serialNumbers?: Maybe<Array<Scalars['String']['output']>>;
  stockOnHand: Scalars['Float']['output'];
  taxGroupId?: Maybe<Scalars['ID']['output']>;
  taxRatePercent: Scalars['Int']['output'];
  tradePrice: Scalars['Float']['output'];
};

export type ProductNotFoundResult = {
  __typename?: 'ProductNotFoundResult';
  result?: Maybe<ResultStatus>;
};

export type ProductOption = {
  __typename?: 'ProductOption';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  values: Array<Scalars['String']['output']>;
};

export type ProductOptionGroupTemplate = EntityNode & {
  __typename?: 'ProductOptionGroupTemplate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** External identifier (Retailer id) */
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  options: Array<ProductOption>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductOptionGroupTemplateFilterParameter = {
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  identifier?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ProductOptionGroupTemplateList = PaginatedList & {
  __typename?: 'ProductOptionGroupTemplateList';
  items: Array<ProductOptionGroupTemplate>;
  meta: PaginatedListMeta;
};

export type ProductOptionGroupTemplateListOptions = {
  filter?: InputMaybe<ProductOptionGroupTemplateFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ProductOptionGroupTemplateSortParameter>;
};

export type ProductOptionGroupTemplateSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identifier?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductOptionInput = {
  /**
   * The identifier is used to reference the template in the product group.
   * Should be the same as the Retailer ID
   */
  identifier: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ProductSortParameter = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  departmentId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  loyaltyPointEarnValue?: InputMaybe<SortOrder>;
  loyaltyPointRedeemValue?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceExcludingTax?: InputMaybe<SortOrder>;
  priceLookup?: InputMaybe<SortOrder>;
  productOptionGroupTemplateId?: InputMaybe<SortOrder>;
  stockCode?: InputMaybe<SortOrder>;
  stockNumber?: InputMaybe<SortOrder>;
  supplierId?: InputMaybe<SortOrder>;
  supplierStockCode?: InputMaybe<SortOrder>;
  taxGroupId?: InputMaybe<SortOrder>;
  totalInventory?: InputMaybe<SortOrder>;
  totalVariants?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  variantParentId?: InputMaybe<SortOrder>;
  webDescription?: InputMaybe<SortOrder>;
  webPrice?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

/** These will all get replaced by CubeJS if more complicated queries are needed */
export type Query = {
  __typename?: 'Query';
  /** Return all access tokens */
  accessTokens: Array<AccessTokenSession>;
  /**
   * Return full details of the current user,
   * including the user's roles and permissions.
   */
  activeUser?: Maybe<UserV2>;
  /** List Categories */
  categories: CategoryList;
  /** Find a category by id */
  category?: Maybe<Category>;
  /** Get Category by catCode */
  categoryByCatCode: Category;
  /**
   * Return the current Vendor. Vendor is an alias for the User (v1)
   * object
   */
  currentVendor: Vendor;
  /** Find a product by id */
  customer?: Maybe<Customer>;
  /** List Customers */
  customers: CustomerList;
  /** Find a department by id */
  department?: Maybe<Department>;
  /** Get Department by deptCode */
  departmentByDeptCode: Department;
  /** List Departments */
  departments: DepartmentList;
  /** Get a docket template by id */
  docketTemplate: DocketTemplate;
  /** Get a list of docket template types */
  docketTemplateTypes: Array<DocketTemplateType>;
  /** Get a list of docket templates */
  docketTemplates: DocketTemplateList;
  /** An estimate of current closure float including payments and cash movements */
  estimateFloat: Scalars['Decimal']['output'];
  /** Estimate number of stock that will be selected from filters */
  estimateNoMatchingStockFromStocktakeFilters: Scalars['Int']['output'];
  exportRawSaleJSON: Scalars['JSON']['output'];
  /** Export a single *CLOSED* sale, formatted for retailer */
  exportRetailerSale: RetailerExportSale;
  /** Export *CLOSED* sales, formatted for retailer */
  exportRetailerSales: Array<RetailerExportSale>;
  features?: Maybe<FeaturesList>;
  getCountItemsSoldAnalytics: Scalars['Float']['output'];
  getCountTotalSalesAnalytics: Scalars['Int']['output'];
  /** Get the specified resource by ID and type */
  getFormattedInboundRetailerDataForResource: Scalars['JSON']['output'];
  /** Get a recent sales by product */
  getRecentSalesByProductAnalytics: Array<SaleSummaryProductResult>;
  getSumRevenueTotalSalesAnalytics: Scalars['Float']['output'];
  getTop10ProductsBySoldAnalytics: Array<AnalyticProductMetaDataItem>;
  globalSettings: GlobalSettings;
  /** List inventory items */
  inventoryItems: InventoryItemList;
  /** List all inventory movements */
  inventoryLevelMovements: InventoryLevelMovementList;
  /** List inventory levels */
  inventoryLevels: InventoryLevelList;
  /** List inventory movement items */
  inventoryMovementItems: InventoryMovementItemList;
  /** Get summary of items. Not available to stocktake. */
  inventoryMovementItemsMeta: Array<Maybe<MovementItemProductSummary>>;
  /** Get a single invoice arrival */
  invoiceArrival: InvoiceArrival;
  /** Get a list of invoice arrivals */
  invoiceArrivals: InvoiceArrivalList;
  /** Get the count of invoice arrivals */
  invoiceArrivalsCount: Scalars['Int']['output'];
  /** Find a layout by id */
  layout: Layout;
  /** get layout by terminal id */
  layoutByTerminalId: Layout;
  /** list all nodes */
  layoutNodes: LayoutNodeList;
  /** List all layouts */
  layouts: LayoutList;
  /**
   * Get the Linkly configuration for a given (linkly) payment method ID.
   * Will generate a new configuration if one does not already exist.
   */
  linklyConfiguration: LinklyConfiguration;
  location: Location;
  locations: LocationList;
  /** Search with common barcode lookup parameters set by `BarcodeLookupInput` */
  lookupBarcode?: Maybe<BarcodeLookupResult>;
  me?: Maybe<CurrentUserAndTenant>;
  /** Get product summary details */
  movementItemProductSummary: MovementItemProductSummary;
  onBoardingComplete: Scalars['Boolean']['output'];
  payTypes: Array<PayType>;
  /** Find a payment method by id */
  paymentMethod: PaymentMethod;
  /** List all payment methods */
  paymentMethods: PaymentMethodList;
  /** Get a product */
  product: Product;
  /** Get product by stock number */
  productByStockNumber: Product;
  productIdByStockNumber: ProductIdByStockNumberResult;
  productImages: Array<ProductImage>;
  /** Returns a product option group template by its ID. */
  productOptionGroupTemplate: ProductOptionGroupTemplate;
  /** Returns a product option group template by its identifier. */
  productOptionGroupTemplateByIdentifier: ProductOptionGroupTemplate;
  /** Returns all product option group templates. */
  productOptionGroupTemplates: ProductOptionGroupTemplateList;
  /** Get all variants of a product */
  productVariants: Array<Product>;
  /** List all products */
  products: ProductList;
  receipt: Receipt;
  receiptTemplateTypes: Array<ReceiptTemplateType>;
  receipts?: Maybe<ReceiptList>;
  retailerPayType: RetailerPayType;
  retailerPayTypes: RetailerPayTypeList;
  sale: Sale;
  /** List all sale history entries */
  saleHistories: Array<SaleHistoryEntry>;
  /** List all changes for a Sale */
  saleHistory: Array<SaleHistoryEntry>;
  sales: SaleList;
  salesCount: Scalars['Int']['output'];
  salesWithHistoryEntries?: Maybe<SaleWithHistoryEntriesList>;
  /** Search entity type based on the criteria set by the `SearchInput` */
  search: SearchResponse;
  selfCheckoutLocationSettings: SelfCheckoutLocationSettings;
  selfCheckoutLocationURL: Scalars['String']['output'];
  selfCheckoutSettings: SelfCheckoutSettings;
  /** Find a SerialNumber by id */
  serialNumber: SerialNumber;
  /** List all SerialNumbers */
  serialNumbers: SerialNumberList;
  /** List all serial numbers for a product */
  serialNumbersForProduct: SerialNumberList;
  serverConfig: ServerConfig;
  staff: Staff;
  staffs: StaffList;
  /** Get stock count by id */
  stockCount: StockCount;
  /** Get stock count by retailer id */
  stockCountByRetailerStocktakeId: StockCount;
  /** Get stock count lines by stock count id */
  stockCountLines: StockCountLineList;
  /** Get stock counts */
  stockCounts: StockCountList;
  /** Get a single stock order */
  stockOrder: StockOrder;
  /** Get a list of stock orders */
  stockOrders: StockOrderList;
  /** Get the count of stock orders */
  stockOrdersCount: Scalars['Int']['output'];
  /** List a single inventory movement */
  stocktake: InventoryMovement;
  /** Get product summary details */
  stocktakeItemProductSummary: MovementItemProductSummary;
  /** List inventory movement items */
  stocktakeItems: StocktakeItemList;
  /** Get summary of items. Not available to stocktake. */
  stocktakeItemsMeta: Array<Maybe<MovementItemProductSummary>>;
  /** List inventory movements */
  stocktakes: InventoryMovementList;
  supplier: Supplier;
  /** Get Supplier by suppCode */
  supplierBySuppCode: Supplier;
  suppliers: SupplierList;
  /** Find a tax group by id */
  taxGroup?: Maybe<TaxGroup>;
  /** Get Tax Group by retailer id */
  taxGroupByTaxGroupID: TaxGroup;
  /** List Tax Groups */
  taxGroups: TaxGroupList;
  terminal: Terminal;
  /** Find a terminal closure by id */
  terminalClosure: TerminalClosure;
  /** A list of all cash movements made during a closure */
  terminalClosureCashMovements: Array<CashMovement>;
  /** A summary of the payments made during a closure */
  terminalClosurePaymentsSummary: TerminalClosurePaymentsSummary;
  /** A summary of sales data made during a closure period */
  terminalClosureSalesSummary: TerminalClosureSalesSummary;
  /** List all terminal closures */
  terminalClosures: TerminalClosureList;
  terminals: TerminalList;
  testConnection: Success;
  /** max 100 at a time to stop overload server */
  undocumentedFilterMetadata: Array<FilterValueMeta>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryCategoriesArgs = {
  options?: InputMaybe<CategoryListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryCategoryArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryCategoryByCatCodeArgs = {
  catCode: Scalars['String']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryCustomerArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryCustomersArgs = {
  options?: InputMaybe<CustomerListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryDepartmentArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryDepartmentByDeptCodeArgs = {
  deptCode: Scalars['String']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryDepartmentsArgs = {
  options?: InputMaybe<DepartmentListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryDocketTemplateArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryDocketTemplateTypesArgs = {
  filters?: InputMaybe<DocketTemplateTypeListFilterOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryDocketTemplatesArgs = {
  options?: InputMaybe<DocketTemplateListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryEstimateFloatArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryEstimateNoMatchingStockFromStocktakeFiltersArgs = {
  filters: Array<InventoryMovementFilterInput>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryExportRawSaleJsonArgs = {
  saleId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryExportRetailerSaleArgs = {
  saleId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryExportRetailerSalesArgs = {
  options?: InputMaybe<ExportRetailerSaleOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryGetCountItemsSoldAnalyticsArgs = {
  options?: InputMaybe<QuerySalesAnalyticsOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryGetCountTotalSalesAnalyticsArgs = {
  options?: InputMaybe<QuerySalesAnalyticsOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryGetFormattedInboundRetailerDataForResourceArgs = {
  resourceId: Scalars['ID']['input'];
  resourceType: RetailerInboundResourceType;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryGetRecentSalesByProductAnalyticsArgs = {
  options?: InputMaybe<QuerySalesAnalyticsOptions>;
  productId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryGetSumRevenueTotalSalesAnalyticsArgs = {
  options?: InputMaybe<QuerySalesAnalyticsOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryGetTop10ProductsBySoldAnalyticsArgs = {
  options?: InputMaybe<QuerySalesAnalyticsOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInventoryItemsArgs = {
  options?: InputMaybe<InventoryItemListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInventoryLevelMovementsArgs = {
  options?: InputMaybe<InventoryLevelMovementListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInventoryLevelsArgs = {
  options?: InputMaybe<InventoryLevelListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInventoryMovementItemsArgs = {
  inventoryMovementId: Scalars['ID']['input'];
  options?: InputMaybe<InventoryMovementItemListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInventoryMovementItemsMetaArgs = {
  inventoryMovementId: Scalars['ID']['input'];
  inventoryMovementItemIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInvoiceArrivalArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInvoiceArrivalsArgs = {
  options?: InputMaybe<InvoiceArrivalListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInvoiceArrivalsCountArgs = {
  options?: InputMaybe<InvoiceArrivalListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLayoutArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLayoutByTerminalIdArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLayoutNodesArgs = {
  options?: InputMaybe<LayoutNodeListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLayoutsArgs = {
  options?: InputMaybe<LayoutListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLinklyConfigurationArgs = {
  paymentMethodId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLocationArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLocationsArgs = {
  options?: InputMaybe<LocationListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLookupBarcodeArgs = {
  input: BarcodeLookupInput;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryMovementItemProductSummaryArgs = {
  options: MovementItemProductSummaryOptions;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryPayTypesArgs = {
  filter?: InputMaybe<PayTypeListFilterOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryPaymentMethodArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryPaymentMethodsArgs = {
  options?: InputMaybe<PaymentMethodListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductByStockNumberArgs = {
  stockNumber: Scalars['String']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductIdByStockNumberArgs = {
  stockNumber: Scalars['String']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductImagesArgs = {
  productId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductOptionGroupTemplateArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductOptionGroupTemplateByIdentifierArgs = {
  identifier: Scalars['String']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductOptionGroupTemplatesArgs = {
  options?: InputMaybe<ProductOptionGroupTemplateListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductVariantsArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductsArgs = {
  options?: InputMaybe<ProductListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryReceiptArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryReceiptTemplateTypesArgs = {
  filters?: InputMaybe<ReceiptTemplatesListFilterOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryReceiptsArgs = {
  options?: InputMaybe<ReceiptListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryRetailerPayTypeArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryRetailerPayTypesArgs = {
  options?: InputMaybe<RetailerPayTypeListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySaleArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySaleHistoriesArgs = {
  saleId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySaleHistoryArgs = {
  saleId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySalesArgs = {
  options?: InputMaybe<SaleListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySalesCountArgs = {
  options?: InputMaybe<SaleListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySalesWithHistoryEntriesArgs = {
  options?: InputMaybe<SaleListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySearchArgs = {
  input: SearchInput;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySelfCheckoutLocationSettingsArgs = {
  locationId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySelfCheckoutLocationUrlArgs = {
  locationId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySerialNumberArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySerialNumbersArgs = {
  options?: InputMaybe<SerialNumberListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySerialNumbersForProductArgs = {
  options?: InputMaybe<SerialNumberListOptions>;
  productLocationInventoryPricingId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStaffArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStaffsArgs = {
  options?: InputMaybe<StaffListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStockCountArgs = {
  stockCountId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStockCountByRetailerStocktakeIdArgs = {
  retailerStocktakeId: Scalars['String']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStockCountLinesArgs = {
  options?: InputMaybe<StockCountLineListOptions>;
  stockCountId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStockCountsArgs = {
  options?: InputMaybe<StockCountListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStockOrderArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStockOrdersArgs = {
  options?: InputMaybe<StockOrderListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStockOrdersCountArgs = {
  options?: InputMaybe<StockOrderListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStocktakeArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStocktakeItemProductSummaryArgs = {
  options: MovementItemProductSummaryOptions;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStocktakeItemsArgs = {
  options?: InputMaybe<StocktakeItemListOptions>;
  stocktakeId: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStocktakeItemsMetaArgs = {
  productIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  stocktakeId: Scalars['ID']['input'];
  stocktakeItemIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStocktakesArgs = {
  options?: InputMaybe<InventoryMovementListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySupplierArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySupplierBySuppCodeArgs = {
  suppCode: Scalars['String']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySuppliersArgs = {
  options?: InputMaybe<SupplierListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTaxGroupArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTaxGroupByTaxGroupIdArgs = {
  taxGroupID: Scalars['String']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTaxGroupsArgs = {
  options?: InputMaybe<TaxGroupListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalClosureArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalClosureCashMovementsArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalClosurePaymentsSummaryArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalClosureSalesSummaryArgs = {
  id: Scalars['ID']['input'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalClosuresArgs = {
  options?: InputMaybe<TerminalClosureListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalsArgs = {
  options?: InputMaybe<TerminalListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryUndocumentedFilterMetadataArgs = {
  filters: Array<FilterValueMetaInput>;
};

export type QuerySalesAnalyticsOptions = {
  endCompletedDate?: InputMaybe<Scalars['String']['input']>;
  startComepletedDate?: InputMaybe<Scalars['String']['input']>;
  /** The state of the order */
  state?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Receipt = EntityNode & {
  __typename?: 'Receipt';
  createdAt: Scalars['DateTime']['output'];
  defaultTemplateType: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  settings: ReceiptSettings;
  updatedAt: Scalars['DateTime']['output'];
};

export enum ReceiptAutoPrintType {
  Both = 'Both',
  POS = 'POS',
  PinPad = 'PinPad'
}

export type ReceiptFilterParameter = {
  createdAt?: InputMaybe<DateOperators>;
  defaultTemplateType?: InputMaybe<StringOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ReceiptInput = {
  defaultTemplateType?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update only field */
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<ReceiptSettingsInput>;
};

export type ReceiptList = PaginatedList & {
  __typename?: 'ReceiptList';
  items: Array<Receipt>;
  meta: PaginatedListMeta;
};

export type ReceiptListOptions = {
  filter?: InputMaybe<ReceiptFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReceiptSortParameter>;
};

export type ReceiptSettings = {
  __typename?: 'ReceiptSettings';
  displayCustomerDetails: Scalars['Boolean']['output'];
  footer: Scalars['String']['output'];
  header: Scalars['String']['output'];
  logoURL?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ReceiptSettingsInput = {
  displayCustomerDetails?: InputMaybe<Scalars['Boolean']['input']>;
  footer?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ReceiptSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  defaultTemplateType?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ReceiptTemplateType = {
  __typename?: 'ReceiptTemplateType';
  createdAt: Scalars['DateTime']['output'];
  css: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['Int']['output'];
};

export type ReceiptTemplatesListFilterOptions = {
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RegisterSalePaymentInput = {
  /** Payment amount */
  amount: Scalars['Float']['input'];
  /** Required for edit */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Store any metadata relevent to the payment */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  /** The date the payment was time, by default current time will be assigned */
  paymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The skytill payment method id */
  paymentMethodId: Scalars['ID']['input'];
  /** Optional, will default to `Settled` if not provided */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The sale the payment was made, the payment can also be accepted in a different terminal the sale originated from */
  terminalId?: InputMaybe<Scalars['ID']['input']>;
  /** A optional unique id for the transaction, provided by the payment provider */
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export type RemovalAiDetails = {
  __typename?: 'RemovalAiDetails';
  apiKey?: Maybe<Scalars['String']['output']>;
};

export type RemovalAiDetailsInput = {
  apiKey: Scalars['String']['input'];
};

export type RemovalBgDetails = {
  __typename?: 'RemovalBgDetails';
  apiKey?: Maybe<Scalars['String']['output']>;
};

export type RemovalBgDetailsInput = {
  apiKey: Scalars['String']['input'];
};

export type Remove2faStrategyResult = InvalidMfaCodeError | InvalidMfaStrategyError | Success;

export type RemoveBackgroundBase64Result = {
  __typename?: 'RemoveBackgroundBase64Result';
  base64: Scalars['String']['output'];
};

export enum ResultStatus {
  FOUND = 'FOUND',
  NOT_FOUND = 'NOT_FOUND'
}

export type RetailerExportCustomer = {
  __typename?: 'RetailerExportCustomer';
  Email: Scalars['String']['output'];
  FirstName: Scalars['String']['output'];
  Phone?: Maybe<Scalars['String']['output']>;
  Surname?: Maybe<Scalars['String']['output']>;
};

export type RetailerExportPayment = {
  __typename?: 'RetailerExportPayment';
  Amount: Scalars['Float']['output'];
  PayCode?: Maybe<Scalars['String']['output']>;
  Tendered: Scalars['Float']['output'];
};

export type RetailerExportSale = {
  __typename?: 'RetailerExportSale';
  CustID?: Maybe<Scalars['String']['output']>;
  Customer?: Maybe<RetailerExportCustomer>;
  FreightCharge: Scalars['Float']['output'];
  FreightTax: Scalars['Float']['output'];
  LocationKey: Scalars['Int']['output'];
  OrderNumber: Scalars['String']['output'];
  Payments: Array<RetailerExportPayment>;
  SaleAmount: Scalars['Float']['output'];
  SaleCust?: Maybe<Scalars['Int']['output']>;
  SaleDate: Scalars['String']['output'];
  SaleDeposit?: Maybe<Scalars['Float']['output']>;
  SaleDiscount: Scalars['Float']['output'];
  SaleId: Scalars['String']['output'];
  SaleItems: Array<RetailerExportSaleItem>;
  SaleRound?: Maybe<Scalars['Float']['output']>;
  SaleStaff?: Maybe<Scalars['String']['output']>;
  SaleTax: Scalars['Float']['output'];
  SaleTerm: Scalars['String']['output'];
  SaleTime: Scalars['String']['output'];
  SaleType: Scalars['String']['output'];
};

export type RetailerExportSaleItem = {
  __typename?: 'RetailerExportSaleItem';
  SerialNumber?: Maybe<Scalars['String']['output']>;
  TotalPointsEarned: Scalars['Int']['output'];
  TotalPointsUsed: Scalars['Int']['output'];
  TxnDiscountType: Scalars['Int']['output'];
  TxnExtendedAmount: Scalars['Float']['output'];
  TxnExtendedDiscount: Scalars['Float']['output'];
  TxnExtendedTax: Scalars['Float']['output'];
  TxnItem: Scalars['Int']['output'];
  TxnQty: Scalars['Float']['output'];
  TxnStock: Scalars['String']['output'];
  TxnTotal: Scalars['Float']['output'];
  TxnTotalCost: Scalars['Float']['output'];
  TxnUnitAmount: Scalars['Float']['output'];
  TxnUnitCost: Scalars['Float']['output'];
  TxnUnitDiscount: Scalars['Float']['output'];
  TxnUnitTax: Scalars['Float']['output'];
};

export enum RetailerInboundResourceType {
  INVOICE_ARRIVAL = 'INVOICE_ARRIVAL',
  PRODUCT = 'PRODUCT',
  STOCK_COUNT = 'STOCK_COUNT',
  STOCK_ORDER = 'STOCK_ORDER'
}

export type RetailerPayType = EntityNode & {
  __typename?: 'RetailerPayType';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  payCode: Scalars['String']['output'];
  payDescription?: Maybe<Scalars['String']['output']>;
  payOver?: Maybe<Scalars['Boolean']['output']>;
  payType: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RetailerPayTypeFilterParameter = {
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  payCode?: InputMaybe<StringOperators>;
  payDescription?: InputMaybe<StringOperators>;
  payOver?: InputMaybe<BooleanOperators>;
  payType?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RetailerPayTypeList = PaginatedList & {
  __typename?: 'RetailerPayTypeList';
  items: Array<RetailerPayType>;
  meta: PaginatedListMeta;
};

export type RetailerPayTypeListOptions = {
  filter?: InputMaybe<RetailerPayTypeFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<RetailerPayTypeSortParameter>;
};

export type RetailerPayTypeSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  payCode?: InputMaybe<SortOrder>;
  payDescription?: InputMaybe<SortOrder>;
  payType?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Role = EntityNode & {
  __typename?: 'Role';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  permissions: Array<Permission>;
  updatedAt: Scalars['DateTime']['output'];
};

export type Sale = EntityNode & {
  __typename?: 'Sale';
  active: Scalars['Boolean']['output'];
  balance: Scalars['Float']['output'];
  code?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invoiceNumber: Scalars['String']['output'];
  invoiceSequence?: Maybe<Scalars['Int']['output']>;
  isReturn: Scalars['Boolean']['output'];
  lineItems: Array<SaleLineItem>;
  location?: Maybe<Location>;
  locationId: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  payments: Array<Payment>;
  purchaseOrderNumber?: Maybe<Scalars['String']['output']>;
  quoteAccepted?: Maybe<Scalars['Boolean']['output']>;
  receiptConfirmationUrl?: Maybe<Scalars['String']['output']>;
  receiptConfirmed: Scalars['Boolean']['output'];
  receiptId?: Maybe<Scalars['ID']['output']>;
  returnForId?: Maybe<Scalars['ID']['output']>;
  saleDate: Scalars['DateTime']['output'];
  saleType: SaleType;
  source: SaleSource;
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['ID']['output']>;
  state: Scalars['String']['output'];
  terminal?: Maybe<Terminal>;
  terminalId: Scalars['ID']['output'];
  totalAdjustmentPercentSaving: Scalars['Float']['output'];
  totalAdjustments: Scalars['Float']['output'];
  totalCost: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  totalTax: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SaleAddress = {
  __typename?: 'SaleAddress';
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
};

export type SaleAdjustment = EntityNode & {
  __typename?: 'SaleAdjustment';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  type: SaleAdjustmentType;
  unitLoyaltyPointsUsed: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SaleAdjustmentInput = {
  /** The date the adjustment was made, by defaut current time will be assigned */
  adjustedDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Payment amount */
  amount: Scalars['Float']['input'];
  /** Required for edit */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Store any metadata relevent to the adjustment */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  /** The type of adjustment */
  type: SaleAdjustmentType;
  /** The unit amount of loyalty points used for this adjustmentm, only applicable if type is LOYALTY_POINTS */
  unitLoyaltyPointsUsed?: InputMaybe<Scalars['Int']['input']>;
};

export enum SaleAdjustmentType {
  /** A discount was applied to this sale item because it was part of a catalogue */
  CATALOGUE_DISCOUNT = 'CATALOGUE_DISCOUNT',
  /** A discount was applied to this sale item because it was part of a loyalty program */
  LOYALTY_DISCOUNT = 'LOYALTY_DISCOUNT',
  /** A manual discount was applied to this sale item */
  MANUAL_DISCOUNT = 'MANUAL_DISCOUNT',
  /** The discount was applied to the entire sale and this is the distributed portion */
  SALE_DISCOUNT = 'SALE_DISCOUNT'
}

export type SaleFilterParameter = {
  active?: InputMaybe<BooleanOperators>;
  balance?: InputMaybe<NumberOperators>;
  code?: InputMaybe<StringOperators>;
  completedAt?: InputMaybe<DateOperators>;
  createdAt?: InputMaybe<DateOperators>;
  customerId?: InputMaybe<IdOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  invoiceNumber?: InputMaybe<StringOperators>;
  invoiceSequence?: InputMaybe<NumberOperators>;
  isReturn?: InputMaybe<BooleanOperators>;
  locationId?: InputMaybe<IdOperators>;
  note?: InputMaybe<StringOperators>;
  productId?: InputMaybe<StringOperators>;
  purchaseOrderNumber?: InputMaybe<StringOperators>;
  quoteAccepted?: InputMaybe<BooleanOperators>;
  receiptConfirmationUrl?: InputMaybe<StringOperators>;
  receiptConfirmed?: InputMaybe<BooleanOperators>;
  receiptId?: InputMaybe<IdOperators>;
  returnForId?: InputMaybe<IdOperators>;
  saleDate?: InputMaybe<DateOperators>;
  saleType?: InputMaybe<StringOperators>;
  source?: InputMaybe<StringOperators>;
  staffId?: InputMaybe<IdOperators>;
  state?: InputMaybe<StringOperators>;
  term?: InputMaybe<StringOperators>;
  terminalId?: InputMaybe<IdOperators>;
  totalAdjustmentPercentSaving?: InputMaybe<NumberOperators>;
  totalAdjustments?: InputMaybe<NumberOperators>;
  totalCost?: InputMaybe<NumberOperators>;
  totalPrice?: InputMaybe<NumberOperators>;
  totalTax?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type SaleHistoryEntry = EntityNode & {
  __typename?: 'SaleHistoryEntry';
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<SaleHistoryEntryData>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  type: SaleHistoryEntryType;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type SaleHistoryEntryData = SaleLineItemEntryData | SaleModifiedCustomerAddedEntryData | SaleModifiedEntryData | SaleNoteEntryData | SaleStatusTransitionData;

export enum SaleHistoryEntryType {
  /** Sale customer added */
  SALE_CUSTOMER_ADDED = 'SALE_CUSTOMER_ADDED',
  /** Sale customer removed */
  SALE_CUSTOMER_REMOVED = 'SALE_CUSTOMER_REMOVED',
  /** Sale line item created */
  SALE_LINE_ITEM_CREATED = 'SALE_LINE_ITEM_CREATED',
  /** Sale line item deleted */
  SALE_LINE_ITEM_DELETED = 'SALE_LINE_ITEM_DELETED',
  /** Sale line item modified */
  SALE_LINE_ITEM_MODIFIED = 'SALE_LINE_ITEM_MODIFIED',
  /** Sale modified */
  SALE_MODIFIED = 'SALE_MODIFIED',
  /** Sale note changed */
  SALE_NOTE_MODIFIED = 'SALE_NOTE_MODIFIED',
  /** Sale state transitioned */
  SALE_STATUS_TRANSITION = 'SALE_STATUS_TRANSITION',
  /** Sale was voided */
  VOID_SALE = 'VOID_SALE'
}

export type SaleLineItem = EntityNode & {
  __typename?: 'SaleLineItem';
  adjustments: Array<SaleAdjustment>;
  basePrice: Scalars['Float']['output'];
  basePriceInclTax: Scalars['Float']['output'];
  billingAddress?: Maybe<SaleAddress>;
  cost: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerNote?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  loyaltyPointEarned: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  priceInclTax: Scalars['Float']['output'];
  product?: Maybe<Product>;
  productId: Scalars['ID']['output'];
  quantity: Scalars['Float']['output'];
  saleId: Scalars['ID']['output'];
  serialNumber?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<SaleAddress>;
  stockCode?: Maybe<Scalars['String']['output']>;
  stockNumber?: Maybe<Scalars['String']['output']>;
  tax: Scalars['Float']['output'];
  taxRatePercent: Scalars['Int']['output'];
  totalAdjustments: Scalars['Float']['output'];
  totalBasePrice: Scalars['Float']['output'];
  totalBasePriceInclTax: Scalars['Float']['output'];
  totalCost: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  totalPriceInclusiveTax: Scalars['Float']['output'];
  totalTax: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SaleLineItemEntryData = {
  __typename?: 'SaleLineItemEntryData';
  adjustments: Array<SaleAdjustment>;
  basePrice: Scalars['Float']['output'];
  basePriceInclTax: Scalars['Float']['output'];
  cost: Scalars['Float']['output'];
  loyaltyPointEarned: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  price: Scalars['Float']['output'];
  priceInclTax: Scalars['Float']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  serialNumber?: Maybe<Scalars['String']['output']>;
  stockCode?: Maybe<Scalars['String']['output']>;
  stockNumber?: Maybe<Scalars['String']['output']>;
  tax: Scalars['Float']['output'];
  taxRatePercent: Scalars['Int']['output'];
  totalAdjustments: Scalars['Float']['output'];
  totalBasePrice: Scalars['Float']['output'];
  totalBasePriceInclTax: Scalars['Float']['output'];
  totalCost: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  totalPriceInclusiveTax: Scalars['Float']['output'];
  totalTax: Scalars['Float']['output'];
};

export type SaleLineItemInput = {
  /** All adjustments made to the sale item that affected the unit price */
  adjustments: Array<SaleAdjustmentInput>;
  /** Unit base price, tax exclusive, before adjustments  */
  basePrice: Scalars['Float']['input'];
  /** Unit base price, tax inclusive, before adjustments  */
  basePriceInclTax: Scalars['Float']['input'];
  /** Cost price used for margin calculations */
  cost?: InputMaybe<Scalars['Float']['input']>;
  /** Optional: Leave a note for the customer. Visible on receipt */
  customerNote?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  loyaltyPointEarned?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  /** Optional name of product. Default product name will be used if non provided */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Unit price, tax exclusive. after adjustments */
  price: Scalars['Float']['input'];
  /** Unit price, tax exclusive. after adjustments */
  priceInclTax: Scalars['Float']['input'];
  productId: Scalars['ID']['input'];
  /** Product quantity */
  quantity: Scalars['Float']['input'];
  /** Order of the sale line item */
  sequence?: InputMaybe<Scalars['Int']['input']>;
  /** Optional serialNumber of product. */
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  /** Optional stockCode of product. Default stockCode sku will be used if non provided */
  stockCode?: InputMaybe<Scalars['String']['input']>;
  /** Optional stockNumber of product. Default stockNumber will be used if non provided */
  stockNumber?: InputMaybe<Scalars['String']['input']>;
  /** Tax value */
  tax: Scalars['Float']['input'];
  /** The tax rate used to calculate the tax value. defaults to 0 */
  taxRatePercent?: InputMaybe<Scalars['Int']['input']>;
};

export type SaleList = PaginatedList & {
  __typename?: 'SaleList';
  items: Array<Sale>;
  meta: PaginatedListMeta;
};

export type SaleListOptions = {
  filter?: InputMaybe<SaleFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SaleSortParameter>;
};

export type SaleModifiedCustomerAddedEntryData = {
  __typename?: 'SaleModifiedCustomerAddedEntryData';
  customerId: Scalars['ID']['output'];
  emailAddress: Scalars['String']['output'];
};

export type SaleModifiedEntryData = {
  __typename?: 'SaleModifiedEntryData';
  input: Scalars['JSON']['output'];
  oldEntity?: Maybe<Scalars['JSON']['output']>;
};

export type SaleNoteEntryData = {
  __typename?: 'SaleNoteEntryData';
  note: Scalars['String']['output'];
};

export type SaleSortParameter = {
  balance?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceNumber?: InputMaybe<SortOrder>;
  invoiceSequence?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  note?: InputMaybe<SortOrder>;
  purchaseOrderNumber?: InputMaybe<SortOrder>;
  receiptConfirmationUrl?: InputMaybe<SortOrder>;
  receiptId?: InputMaybe<SortOrder>;
  returnForId?: InputMaybe<SortOrder>;
  saleDate?: InputMaybe<SortOrder>;
  staffId?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  terminalId?: InputMaybe<SortOrder>;
  totalAdjustmentPercentSaving?: InputMaybe<SortOrder>;
  totalAdjustments?: InputMaybe<SortOrder>;
  totalCost?: InputMaybe<SortOrder>;
  totalPrice?: InputMaybe<SortOrder>;
  totalTax?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum SaleSource {
  /** A sale that was created via the web checkout */
  WEB_CHECKOUT = 'WEB_CHECKOUT',
  /** A sale that was created via the POS */
  WEB_REGISTER = 'WEB_REGISTER'
}

/** Returned if there is an error in transitioning the Salle state */
export type SaleStateTransitionError = ErrorResult & {
  __typename?: 'SaleStateTransitionError';
  errorCode: ErrorCode;
  fromState: Scalars['String']['output'];
  message: Scalars['String']['output'];
  toState: Scalars['String']['output'];
  transitionError: Scalars['String']['output'];
};

export type SaleStatusTransitionData = {
  __typename?: 'SaleStatusTransitionData';
  from: Scalars['String']['output'];
  to: Scalars['String']['output'];
};

export type SaleSummaryProductResult = {
  __typename?: 'SaleSummaryProductResult';
  /** the date the sale was completed */
  completedDate: Scalars['DateTime']['output'];
  /** the amount of product sold in the sale */
  count: Scalars['Int']['output'];
  product?: Maybe<Product>;
  /** the id of the product */
  productId: Scalars['ID']['output'];
  /** the id of the sale */
  saleId: Scalars['ID']['output'];
  /** the state of the sale */
  state: Scalars['String']['output'];
  /** the total amount of the product sold in the sale */
  total: Scalars['Float']['output'];
};

export enum SaleType {
  INVOICE = 'INVOICE',
  LAYBY = 'LAYBY',
  QUOTE = 'QUOTE',
  SALE = 'SALE'
}

export type SaleWithHistoryEntries = {
  __typename?: 'SaleWithHistoryEntries';
  historyEntries: Array<SaleHistoryEntry>;
  sale: Sale;
};

export type SaleWithHistoryEntriesList = {
  __typename?: 'SaleWithHistoryEntriesList';
  items: Array<SaleWithHistoryEntries>;
  meta: PaginatedListMeta;
};

export type SearchInput = {
  /** defaults to false */
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
  type: SuggestType;
};

export type SearchResponse = {
  __typename?: 'SearchResponse';
  items?: Maybe<Array<SearchResultItem>>;
};

export type SearchResultItem = {
  __typename?: 'SearchResultItem';
  entity: SearchResultItemEntity;
  id: Scalars['ID']['output'];
  type: SuggestType;
};

export type SearchResultItemEntity = Customer | Product;

export type SelectedOption = {
  __typename?: 'SelectedOption';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SelectedOptionInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type SelfCheckoutLocationSettings = {
  __typename?: 'SelfCheckoutLocationSettings';
  checkoutSuccessHeading?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  location?: Maybe<Location>;
  locationId: Scalars['ID']['output'];
  offlineCheckoutEnabled: Scalars['Boolean']['output'];
  onlineCheckoutEnabled: Scalars['Boolean']['output'];
  payAtCounterHeading?: Maybe<Scalars['String']['output']>;
  payAtCounterSubheading?: Maybe<Scalars['String']['output']>;
  terminal?: Maybe<Terminal>;
  terminalId?: Maybe<Scalars['ID']['output']>;
};

export type SelfCheckoutSettings = {
  __typename?: 'SelfCheckoutSettings';
  enabled: Scalars['Boolean']['output'];
  locationSettings: Array<SelfCheckoutLocationSettings>;
  logoURL?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId?: Maybe<Scalars['ID']['output']>;
};

export type SendReceiptInput = {
  receiptId?: InputMaybe<Scalars['ID']['input']>;
  saleId: Scalars['ID']['input'];
  /** Must be valid email or E.164 for sms */
  to: Scalars['String']['input'];
  type: SendReceiptType;
};

export enum SendReceiptType {
  /** EMAIL */
  EMAIL = 'EMAIL'
}

export type SendStocktakeInput = {
  stocktakeId: Scalars['ID']['input'];
  /** Must be valid email */
  to: Scalars['String']['input'];
  type: SendStocktakeType;
};

export enum SendStocktakeType {
  /** EMAIL */
  EMAIL = 'EMAIL'
}

export type SerialNumber = EntityNode & {
  __typename?: 'SerialNumber';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  productLocationInventoryPricingId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type SerialNumberFilterParameter = {
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  productLocationInventoryPricingId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  value?: InputMaybe<StringOperators>;
};

export type SerialNumberList = PaginatedList & {
  __typename?: 'SerialNumberList';
  items: Array<SerialNumber>;
  meta: PaginatedListMeta;
};

export type SerialNumberListOptions = {
  filter?: InputMaybe<SerialNumberFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SerialNumberSortParameter>;
};

export type SerialNumberSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productLocationInventoryPricingId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ServerConfig = {
  __typename?: 'ServerConfig';
  availablePayTypes: Array<PayType>;
  internalPayTypes: Array<PayType>;
  receiptTemplateTypes: Array<ReceiptTemplateType>;
};

export type Setup2faStrategyInput = {
  /** The identifier for the strategy, eg email address or phone number */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** The strategy to use for 2fa */
  strategy: MfaCodeStrategy;
};

export type Setup2faStrategyResult = InvalidMfaIdentifierError | InvalidMfaStrategyError | MfaAlreadySetupError | Success;

export type ShiftLayoutNodesInput = {
  id: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Staff = EntityNode & {
  __typename?: 'Staff';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  hasUserAccount: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  initials: Scalars['String']['output'];
  terminalIds: Array<Scalars['ID']['output']>;
  terminals: Array<Terminal>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<UserV2>;
  userId?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type StaffFilterParameter = {
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  emailAddress?: InputMaybe<StringOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  fullName?: InputMaybe<StringOperators>;
  hasUserAccount?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  initials?: InputMaybe<StringOperators>;
  terminalIds?: InputMaybe<IdListOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  userId?: InputMaybe<IdOperators>;
  username?: InputMaybe<StringOperators>;
};

export type StaffList = PaginatedList & {
  __typename?: 'StaffList';
  items: Array<Staff>;
  meta: PaginatedListMeta;
};

export type StaffListOptions = {
  filter?: InputMaybe<StaffFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<StaffSortParameter>;
};

export type StaffSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  emailAddress?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  initials?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type StockCount = EntityNode & {
  __typename?: 'StockCount';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  locationId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  retailerStocktakeId?: Maybe<Scalars['String']['output']>;
  source: StockCountSource;
  status: StockCountStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type StockCountFilterParameter = {
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  locationId?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  retailerStocktakeId?: InputMaybe<StringOperators>;
  source?: InputMaybe<StringOperators>;
  status?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type StockCountItem = EntityNode & {
  __typename?: 'StockCountItem';
  counted: Scalars['Float']['output'];
  countedAt: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lineId: Scalars['ID']['output'];
  status: StockCountItemStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export enum StockCountItemStatus {
  /** The stock count has been exported at least once */
  EXPORTED = 'EXPORTED',
  /** Stock count has been pending but not yet started */
  PENDING = 'PENDING'
}

export type StockCountLine = EntityNode & {
  __typename?: 'StockCountLine';
  barcode: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  expected?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  initialExpected?: Maybe<Scalars['Float']['output']>;
  items: Array<StockCountItem>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['ID']['output']>;
  stockCountId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type StockCountLineFilterParameter = {
  barcode?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  expected?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  initialExpected?: InputMaybe<NumberOperators>;
  productId?: InputMaybe<IdOperators>;
  stockCountId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type StockCountLineList = PaginatedList & {
  __typename?: 'StockCountLineList';
  items: Array<StockCountLine>;
  meta: PaginatedListMeta;
};

export type StockCountLineListOptions = {
  filter?: InputMaybe<StockCountLineFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<StockCountLineSortParameter>;
};

export type StockCountLineSortParameter = {
  barcode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  expected?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  initialExpected?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  stockCountId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StockCountList = PaginatedList & {
  __typename?: 'StockCountList';
  items: Array<StockCount>;
  meta: PaginatedListMeta;
};

export type StockCountListOptions = {
  filter?: InputMaybe<StockCountFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<StockCountSortParameter>;
};

export type StockCountSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  retailerStocktakeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum StockCountSource {
  INTERNAL = 'INTERNAL',
  RETAILER = 'RETAILER'
}

export enum StockCountStatus {
  /** Stock count has been completed */
  COMPLETE = 'COMPLETE',
  /** Stock count can be counted */
  COUNTING = 'COUNTING'
}

/** Returned when there is an error with user input. */
export type StockNumberNotFoundError = ErrorResult & {
  __typename?: 'StockNumberNotFoundError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type StockOrder = EntityNode & {
  __typename?: 'StockOrder';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  formattedOrderNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items: Array<StockOrderItem>;
  location?: Maybe<Location>;
  locationId: Scalars['ID']['output'];
  orderCreatedDate: Scalars['DateTime']['output'];
  orderNumber: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  totalQuantity: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type StockOrderFilterParameter = {
  completedAt?: InputMaybe<DateOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  formattedOrderNumber?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  locationId?: InputMaybe<IdOperators>;
  orderCreatedDate?: InputMaybe<DateOperators>;
  orderNumber?: InputMaybe<NumberOperators>;
  productId?: InputMaybe<StringOperators>;
  status?: InputMaybe<StringOperators>;
  totalQuantity?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type StockOrderItem = EntityNode & {
  __typename?: 'StockOrderItem';
  barcode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['ID']['output']>;
  quantity: Scalars['Float']['output'];
  stockOrderId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type StockOrderList = PaginatedList & {
  __typename?: 'StockOrderList';
  items: Array<StockOrder>;
  meta: PaginatedListMeta;
};

export type StockOrderListOptions = {
  filter?: InputMaybe<StockOrderFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<StockOrderSortParameter>;
};

export type StockOrderSortParameter = {
  completedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  formattedOrderNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  orderCreatedDate?: InputMaybe<SortOrder>;
  orderNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  totalQuantity?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StocktakeCreateInput = {
  /** Optional, default timestamp used */
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** filters used to generate InventoryMovementItems when transitioning to STOCKTAKE_UNDERWAY, empty array will create for all items. null will generate none. */
  filters?: InputMaybe<Array<InputMaybe<InventoryMovementFilterInput>>>;
  /** The location which will be doing the stocktake */
  locationId: Scalars['ID']['input'];
  /** A name to help identify the stocktake */
  name: Scalars['String']['input'];
  /** Optional, default timestamp used */
  stocktakeDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StocktakeDetailsUpdateInput = {
  /** Optional, default timestamp used */
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Can only update  these if not started, filters used to generate InventoryMovementItems when transitioning to STOCKTAKE_UNDERWAY, empty array will create for all items. null will generate none. */
  filters?: InputMaybe<Array<InputMaybe<InventoryMovementFilterInput>>>;
  /** A name to help identify the stocktake */
  name: Scalars['String']['input'];
  /** Optional, default timestamp used */
  stocktakeDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StocktakeItem = EntityNode & {
  __typename?: 'StocktakeItem';
  cost?: Maybe<Scalars['Float']['output']>;
  counted?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  expected?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  initialExpected?: Maybe<Scalars['Int']['output']>;
  manual: Scalars['Boolean']['output'];
  product: Scalars['ID']['output'];
  status?: Maybe<StocktakeItemStatus>;
  stockCode?: Maybe<Scalars['String']['output']>;
  stocktakeId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type StocktakeItemFilterParameter = {
  cost?: InputMaybe<NumberOperators>;
  counted?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  expected?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  initialExpected?: InputMaybe<NumberOperators>;
  manual?: InputMaybe<BooleanOperators>;
  product?: InputMaybe<IdOperators>;
  status?: InputMaybe<StringOperators>;
  stockCode?: InputMaybe<StringOperators>;
  stocktakeId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type StocktakeItemList = PaginatedList & {
  __typename?: 'StocktakeItemList';
  items: Array<StocktakeItem>;
  meta: PaginatedListMeta;
};

export type StocktakeItemListOptions = {
  filter?: InputMaybe<StocktakeItemFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<StocktakeItemSortParameter>;
};

export type StocktakeItemSortParameter = {
  cost?: InputMaybe<SortOrder>;
  counted?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  expected?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  initialExpected?: InputMaybe<SortOrder>;
  product?: InputMaybe<SortOrder>;
  stockCode?: InputMaybe<SortOrder>;
  stocktakeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum StocktakeItemStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS'
}

/** Operators for filtering on a list of String fields */
export type StringListOperators = {
  inList: Array<InputMaybe<Scalars['String']['input']>>;
};

export type StringOperators = {
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notEq?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  regex?: InputMaybe<Scalars['String']['input']>;
};

export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean']['output'];
};

export enum SuggestType {
  /** Include result for all types */
  ALL = 'ALL',
  /** Search for customers */
  CUSTOMER = 'CUSTOMER',
  /** Search for products */
  PRODUCT = 'PRODUCT'
}

export type Supplier = EntityNode & {
  __typename?: 'Supplier';
  active: Scalars['Boolean']['output'];
  bannerImageUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  suppCode: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SupplierFilterParameter = {
  active?: InputMaybe<BooleanOperators>;
  bannerImageUrl?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  suppCode?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type SupplierList = PaginatedList & {
  __typename?: 'SupplierList';
  items: Array<Supplier>;
  meta: PaginatedListMeta;
};

export type SupplierListOptions = {
  filter?: InputMaybe<SupplierFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SupplierSortParameter>;
};

export type SupplierSortParameter = {
  bannerImageUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  suppCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SwapLayoutNodesInput = {
  firstNodeId: Scalars['ID']['input'];
  secondNodeId: Scalars['ID']['input'];
};

export type SwapLayoutNodesResult = {
  __typename?: 'SwapLayoutNodesResult';
  firstNode: LayoutNode;
  secondNode: LayoutNode;
};

export type SwitchUserResult = CurrentUserV2 | InvalidCredentialsError | InvalidMfaCodeError | MfaRequiredError;

export type TalinkAuthInputType = {
  password: Scalars['String']['input'];
  talinkUsername: Scalars['String']['input'];
};

export type TalinkStaffAuthInputType = {
  password: Scalars['String']['input'];
  talinkUsername: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type TalinkTokenAuthInputType = {
  token: Scalars['String']['input'];
};

export type TaxGroup = EntityNode & {
  __typename?: 'TaxGroup';
  active: Scalars['Boolean']['output'];
  arrivePercentage?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  salePercentage?: Maybe<Scalars['Float']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  taxGroupID: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TaxGroupFilterParameter = {
  active?: InputMaybe<BooleanOperators>;
  arrivePercentage?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  salePercentage?: InputMaybe<NumberOperators>;
  shortName?: InputMaybe<StringOperators>;
  taxGroupID?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type TaxGroupList = PaginatedList & {
  __typename?: 'TaxGroupList';
  items: Array<TaxGroup>;
  meta: PaginatedListMeta;
};

export type TaxGroupListOptions = {
  filter?: InputMaybe<TaxGroupFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TaxGroupSortParameter>;
};

export type TaxGroupSortParameter = {
  arrivePercentage?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  salePercentage?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  taxGroupID?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Tenant = {
  __typename?: 'Tenant';
  identifier: Scalars['String']['output'];
};

export type Terminal = EntityNode & {
  __typename?: 'Terminal';
  createdAt: Scalars['DateTime']['output'];
  defaultReceiptTemplateId?: Maybe<Scalars['ID']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invoiceSequence: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  openTerminalClosure?: Maybe<TerminalClosure>;
  openTerminalClosureId?: Maybe<Scalars['ID']['output']>;
  paymentMethodIds: Array<Scalars['ID']['output']>;
  printReceiptOnCheckout: Scalars['Boolean']['output'];
  salePrefix?: Maybe<Scalars['String']['output']>;
  saleSufix?: Maybe<Scalars['String']['output']>;
  terminalCloseTime?: Maybe<Scalars['DateTime']['output']>;
  terminalOpenTime?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TerminalClosure = EntityNode & {
  __typename?: 'TerminalClosure';
  cashMovements: Array<CashMovement>;
  /** Only available if the terminal closure is closed */
  countedPaymentsSummary?: Maybe<Array<TerminalClosurePaymentMethodSummary>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  estimatedCashBalance: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isClosed: Scalars['Boolean']['output'];
  payments: Array<TerminalClosurePaymentTotal>;
  saleSummary: TerminalClosureSalesSummary;
  terminalCloseDate?: Maybe<Scalars['DateTime']['output']>;
  terminalId: Scalars['ID']['output'];
  terminalName?: Maybe<Scalars['String']['output']>;
  terminalOpenDate: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TerminalClosureFilterParameter = {
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  estimatedCashBalance?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  isClosed?: InputMaybe<BooleanOperators>;
  terminalCloseDate?: InputMaybe<DateOperators>;
  terminalId?: InputMaybe<IdOperators>;
  terminalName?: InputMaybe<StringOperators>;
  terminalOpenDate?: InputMaybe<DateOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type TerminalClosureList = PaginatedList & {
  __typename?: 'TerminalClosureList';
  items: Array<TerminalClosure>;
  meta: PaginatedListMeta;
};

export type TerminalClosureListOptions = {
  filter?: InputMaybe<TerminalClosureFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TerminalClosureSortParameter>;
};

export type TerminalClosurePaymentMethodSummary = {
  __typename?: 'TerminalClosurePaymentMethodSummary';
  difference: Scalars['Decimal']['output'];
  expected: Scalars['Decimal']['output'];
  payTypeId: Scalars['ID']['output'];
  paymentMethodId: Scalars['ID']['output'];
  paymentTypeName: Scalars['String']['output'];
  total: Scalars['Decimal']['output'];
};

export type TerminalClosurePaymentTotal = {
  __typename?: 'TerminalClosurePaymentTotal';
  counted: Scalars['Float']['output'];
  paymentMethodId: Scalars['ID']['output'];
};

export type TerminalClosurePaymentTotalInput = {
  paymentMethodId: Scalars['ID']['input'];
  total: Scalars['Decimal']['input'];
};

export type TerminalClosurePaymentsSummary = {
  __typename?: 'TerminalClosurePaymentsSummary';
  payments: Array<TerminalClosurePaymentMethodSummary>;
  terminalClosureId: Scalars['ID']['output'];
  terminalId: Scalars['ID']['output'];
  terminalOpenTime: Scalars['DateTime']['output'];
};

export type TerminalClosureSalesSummary = {
  __typename?: 'TerminalClosureSalesSummary';
  totalDiscounts: Scalars['Decimal']['output'];
  totalInvoices: Scalars['Decimal']['output'];
  totalLaybys: Scalars['Decimal']['output'];
  totalPayments: Scalars['Decimal']['output'];
  totalRefunds: Scalars['Decimal']['output'];
  totalSales: Scalars['Decimal']['output'];
  totalTaxes: Scalars['Decimal']['output'];
};

export type TerminalClosureSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  estimatedCashBalance?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  terminalCloseDate?: InputMaybe<SortOrder>;
  terminalId?: InputMaybe<SortOrder>;
  terminalName?: InputMaybe<SortOrder>;
  terminalOpenDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TerminalFilterParameter = {
  createdAt?: InputMaybe<DateOperators>;
  defaultReceiptTemplateId?: InputMaybe<IdOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  invoiceSequence?: InputMaybe<NumberOperators>;
  name?: InputMaybe<StringOperators>;
  openTerminalClosureId?: InputMaybe<IdOperators>;
  paymentMethodIds?: InputMaybe<IdListOperators>;
  printReceiptOnCheckout?: InputMaybe<BooleanOperators>;
  salePrefix?: InputMaybe<StringOperators>;
  saleSufix?: InputMaybe<StringOperators>;
  terminalCloseTime?: InputMaybe<DateOperators>;
  terminalOpenTime?: InputMaybe<DateOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type TerminalList = PaginatedList & {
  __typename?: 'TerminalList';
  items: Array<Terminal>;
  meta: PaginatedListMeta;
};

export type TerminalListOptions = {
  filter?: InputMaybe<TerminalFilterParameter>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TerminalSortParameter>;
};

export type TerminalSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  defaultReceiptTemplateId?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceSequence?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  openTerminalClosureId?: InputMaybe<SortOrder>;
  salePrefix?: InputMaybe<SortOrder>;
  saleSufix?: InputMaybe<SortOrder>;
  terminalCloseTime?: InputMaybe<SortOrder>;
  terminalOpenTime?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum Uom {
  KG = 'KG',
  g = 'g'
}

export type UpdateAccessTokenInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCategoryInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  bannerImage?: InputMaybe<Scalars['Upload']['input']>;
  catCode?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  markup?: InputMaybe<Array<MarkupInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  preferredTaxGroupCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDepartmentInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  bannerImage?: InputMaybe<Scalars['Upload']['input']>;
  deptCode?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  markup?: InputMaybe<Array<MarkupInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  preferredTaxGroupCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDocketTemplateInput = {
  automaticallyPrint?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<Array<DocketFilterInput>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  templateTypeId?: InputMaybe<Scalars['String']['input']>;
  terminalIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateGlobalSettingsInput = {
  abn?: InputMaybe<Scalars['String']['input']>;
  automaticBackgroundRemoval?: InputMaybe<Scalars['Boolean']['input']>;
  automaticOpenTerminalClosure?: InputMaybe<Scalars['Boolean']['input']>;
  automaticallyEmailStockTake?: InputMaybe<Scalars['Boolean']['input']>;
  automaticallyEmailTerminalClosureSummary?: InputMaybe<Scalars['Boolean']['input']>;
  bgRemovalStrategy?: InputMaybe<BgRemovalStrategy>;
  cashManagedPaymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<CurrencyInput>;
  defaultLaybyDepositPercentage?: InputMaybe<Scalars['Int']['input']>;
  defaultTerminalClosureFloat?: InputMaybe<Scalars['Float']['input']>;
  laybyEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  minLaybySpend?: InputMaybe<Scalars['Float']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  removalAiDetails?: InputMaybe<RemovalAiDetailsInput>;
  removalBgDetails?: InputMaybe<RemovalBgDetailsInput>;
  retailerInBoundEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  showExpectedClosureCounts?: InputMaybe<Scalars['Boolean']['input']>;
  stocktakeSendToAddress?: InputMaybe<Scalars['String']['input']>;
  taxPercentage?: InputMaybe<Scalars['Int']['input']>;
  terminalClosureSendToAddress?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInvoiceArrivalInput = {
  /** Date the stock is being arrived */
  arrivalDate?: InputMaybe<Scalars['DateTime']['input']>;
  /**
   * the price includes GST
   * defaults to true
   * * retailer apparently needs this
   */
  costPriceIncGST?: InputMaybe<Scalars['Boolean']['input']>;
  /** Date the payment for the invoice is due */
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Value of freight component of the invoice */
  freightCost?: InputMaybe<Scalars['Float']['input']>;
  /** The id of the line item */
  id: Scalars['ID']['input'];
  /** Date on the suppliers invoice */
  invoiceDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The invoice number from the supplier */
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  /** The order number from the supplier */
  orderNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInvoiceArrivalLineItemInput = {
  /** Required if productId is not provided */
  barcode?: InputMaybe<Scalars['String']['input']>;
  /**
   * The cost price of the product, required if productId is not provided.
   * ~ Prefilled if productId is provided
   */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The id of the line item */
  id: Scalars['ID']['input'];
  /** Can only be changed if the productId is yet to be set */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** The expected quantity being ordered */
  quantity?: InputMaybe<Scalars['Float']['input']>;
  /** The expected quantity being ordered */
  received?: InputMaybe<Scalars['Float']['input']>;
  /**
   * The price price of the product, required if productId is not provided.
   * ~ Prefilled if productId is provided
   */
  retailPrice?: InputMaybe<Scalars['Float']['input']>;
  /**
   * The serial numbers of the products being received.
   * Required if product has been flagged as requiring serial numbers
   * The amount should be equal to the received amount
   */
  serialNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * The price price of the product, required if productId is not provided.
   * ~ Prefilled if productId is provided
   */
  tradePrice?: InputMaybe<Scalars['Float']['input']>;
  /**
   * The price price of the product, required if productId is not provided.
   * ~ Prefilled if productId is provided
   */
  webPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateLayoutNodeInput = {
  id: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
};

export type UpdateLocationInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  retailerLocationKey: Scalars['String']['input'];
};

/**
 * Contains a subset of information that can be updated.
 * Not all fields can be updated, and some fields are only available to some roles
 */
export type UpdateMeInput = {
  /** force disable all 2fa strategies */
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePaymentMethodInput = {
  configuration?: InputMaybe<PaymentMethodConfigurationInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated, use retailerPayTypeId */
  retailerPayCode?: InputMaybe<Scalars['String']['input']>;
  retailerPayTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateProductImageInput = {
  id: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
};

export type UpdateProductInput = {
  UOM?: InputMaybe<Uom>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locationInventoryPricing?: InputMaybe<Array<InventoryPricingInput>>;
  loyaltyPointEarnValue?: InputMaybe<Scalars['Int']['input']>;
  loyaltyPointRedeemValue?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use productOptionGroupTemplateId instead */
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  priceLookup?: InputMaybe<Scalars['String']['input']>;
  /**
   * The ID of the product option group template to use for this product.
   * Replaces the deprecated options field.
   */
  productOptionGroupTemplateId?: InputMaybe<Scalars['ID']['input']>;
  /** Must match the options of the parent product option group template */
  selectedOptions?: InputMaybe<Array<SelectedOptionInput>>;
  serialNumbersArr?: InputMaybe<Scalars['Boolean']['input']>;
  serialNumbersSale?: InputMaybe<Scalars['Boolean']['input']>;
  stockCode?: InputMaybe<Scalars['String']['input']>;
  stockNumber?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
  supplierStockCode?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  variantParentId?: InputMaybe<Scalars['ID']['input']>;
  webDescription?: InputMaybe<Scalars['String']['input']>;
  webPrice?: InputMaybe<Scalars['Float']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateProductOptionGroupTemplateInput = {
  id: Scalars['ID']['input'];
  /**
   * The identifier is used to reference the template in the product group.
   * Should be the same as the Retailer ID
   */
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Must pass all options, not just the ones that are being updated. */
  options?: InputMaybe<Array<ProductOptionInput>>;
};

export type UpdateRetailerPayTypeInput = {
  id: Scalars['ID']['input'];
  payCode?: InputMaybe<Scalars['String']['input']>;
  payDescription?: InputMaybe<Scalars['String']['input']>;
  payOver?: InputMaybe<Scalars['Boolean']['input']>;
  payType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSaleInput = {
  /** Valid id of the customer accociated with the sale */
  customerId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  lineItems: Array<SaleLineItemInput>;
  /** Optional: Store any metadata relevent to the sale */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  /** Optional note to add to the sale */
  note?: InputMaybe<Scalars['String']['input']>;
  /** An arra yof payments */
  payments: Array<RegisterSalePaymentInput>;
  /** Optional purchase order number */
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
  /** Required when the sale is a quote */
  quoteAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  /** optional, the the receipt template to use when sending email */
  receiptId?: InputMaybe<Scalars['ID']['input']>;
  /** optional, send a receipt to customer */
  receiptTo?: InputMaybe<Scalars['String']['input']>;
  /** Time of sale, by default current time will be assigned */
  saleDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Valid id of the retailer staff associated with sale. */
  staffId?: InputMaybe<Scalars['ID']['input']>;
  /** One of: OPEN, SAVED, CLOSED, LAYBY, LAYBY_CLOSED, VOIDED */
  state: Scalars['String']['input'];
};

export type UpdateSelfCheckoutLocationSettingsInput = {
  checkoutSuccessHeading?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  locationId: Scalars['ID']['input'];
  offlineCheckoutEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  onlineCheckoutEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  payAtCounterHeading?: InputMaybe<Scalars['String']['input']>;
  payAtCounterSubheading?: InputMaybe<Scalars['String']['input']>;
  terminalId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateSelfCheckoutSettingsInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateSerialNumberInput = {
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type UpdateStaffInput = {
  enabled: Scalars['Boolean']['input'];
  fullName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  /** Must be unique */
  initials: Scalars['String']['input'];
};

export type UpdateStaffUserAccountInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  initials?: InputMaybe<Scalars['String']['input']>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
  staffId: Scalars['ID']['input'];
  /** Empty array means all terminals are allowed. */
  terminalIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStockOrderInput = {
  /** The id of the Stock Order */
  id: Scalars['ID']['input'];
  /** Optional. The date the order was created. */
  orderCreatedDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateStockOrderLineItemInput = {
  /** The id of the line item */
  id: Scalars['ID']['input'];
  /** The expected quantity being ordered */
  quantity?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Optionally if product has been provided, and the supplier has been set,
   * then set the supplier code of the product
   */
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  /**
   * Optionally if product has been provided, then set
   * the supplier of the product
   */
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateSupplierInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  bannerImage?: InputMaybe<Scalars['Upload']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaxGroupInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  arrivePercentage?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  salePercentage?: InputMaybe<Scalars['Int']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTerminalInput = {
  defaultReceiptTemplateId?: InputMaybe<Scalars['ID']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  invoiceSequence?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** The payment methods available at this Terminal */
  paymentMethodIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  salePrefix?: InputMaybe<Scalars['String']['input']>;
  saleSufix?: InputMaybe<Scalars['String']['input']>;
};

export type UploadImageToRetailerBySkuInput = {
  file: Scalars['Upload']['input'];
  position: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
};

export type User = EntityNode & {
  __typename?: 'User';
  displayName?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mobileNumber?: Maybe<Scalars['String']['output']>;
  numberAvailableTerminals: Scalars['Int']['output'];
  stripeAccountId?: Maybe<Scalars['String']['output']>;
  stripeOnboardingComplete: Scalars['Boolean']['output'];
  userAccountType: UserAccountType;
  username: Scalars['String']['output'];
};

export enum UserAccountType {
  RETAILER_USER = 'RETAILER_USER',
  TOWER_STAFF = 'TOWER_STAFF'
}

export type UserV2 = EntityNode & {
  __typename?: 'UserV2';
  authenticationMethods: Array<AuthenticationMethod>;
  createdAt: Scalars['DateTime']['output'];
  enabled2fa: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  mfaMethods: Array<MfaMethod>;
  roles: Array<Role>;
  updatedAt: Scalars['DateTime']['output'];
  verified: Scalars['Boolean']['output'];
};

export type Vendor = {
  __typename?: 'Vendor';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  numberAvailableTerminals: Scalars['Int']['output'];
  stripeOnboardingComplete: Scalars['Boolean']['output'];
  talinkId: Scalars['String']['output'];
  talinkUsername: Scalars['String']['output'];
  userAccountType: UserAccountType;
};

export type Verify2faStrategyResult = InvalidCredentialsError | InvalidMfaCodeError | InvalidMfaStrategyError | MfaAlreadySetupError | Success;
