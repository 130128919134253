import { IconButton, Tooltip } from "@mui/material";
import { TbFileBarcode } from "react-icons/tb";
import {
  GENERIC_BARCODE_FAILURE_MESSAGE,
  useBarcodeGenerator,
} from "../../utils";
import { useFlashMessages } from "../../../../utilities/flash-messages";

export interface BarcodeGeneratorProps {
  onSuccess?(barcode: string): void;
}

export function BarcodeGenerator({ onSuccess }: BarcodeGeneratorProps) {
  const { generate } = useBarcodeGenerator();

  const { showMessage } = useFlashMessages();

  async function handleGenerateBarcode() {
    return generate()
      .then((barcode) => {
        onSuccess && onSuccess(barcode);
      })
      .catch(handleGenerateBarcodeFailed);
  }

  function handleGenerateBarcodeFailed(e: any) {
    showMessage({
      severity: "error",
      message: `${GENERIC_BARCODE_FAILURE_MESSAGE}: ` + e?.toString(),
    });
  }

  return (
    <Tooltip title="Generate Barcode">
      <IconButton onClick={handleGenerateBarcode}>
        <TbFileBarcode />
      </IconButton>
    </Tooltip>
  );
}
