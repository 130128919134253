import { useLazyQuery } from "@apollo/client";
import {
  BarcodeLookupInput,
  BarcodeLookupResult,
} from "@towersystems/roam-common/lib/generated-types";
import { QUERY_LOOKUP_BARCODE } from "./constants";

export const useLookupBarcode = () => {
  const [lookupBarcodeMutation] = useLazyQuery<{
    lookupBarcode: BarcodeLookupResult;
  }>(QUERY_LOOKUP_BARCODE);

  async function lookupBarcode(input: BarcodeLookupInput) {
    return lookupBarcodeMutation({ variables: { input: input } }).then(
      (res) => {
        const newBarcode = res?.data?.lookupBarcode;
        return newBarcode;
      }
    );
  }

  return { lookupBarcode };
};
