import { FormControlLabel, Switch } from "@mui/material";
import { Location } from "@towersystems/roam-common/lib/generated-types";
import { useState } from "react";
import { FormLayout, Stack } from "../../../../components";
import { useLocationInventoryPricingForm } from "../../utils";
import { InventoryFields } from "./components";

export interface InventoryFormProps {
  variant: "edit" | "create";
  locations: Location[];
}

export function InventoryForm({ variant, locations }: InventoryFormProps) {
  const [globalInventory, setGlobalInventory] = useState<boolean>(
    variant === "create"
  );

  const { handleGlobalSetValue, makePrefixForField } =
    useLocationInventoryPricingForm();

  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalInventory((prev) => !prev);
  };

  return (
    <FormLayout>
      <FormControlLabel
        style={{ marginLeft: "auto" }}
        label="Global Inventory"
        control={
          <Switch
            checked={globalInventory}
            onChange={handleChange}
            color="primary"
          />
        }
      />

      {globalInventory ? (
        <InventoryFields onSetValue={handleGlobalSetValue} />
      ) : (
        <Stack spacing={2}>
          {locations?.map((location) => {
            return (
              <InventoryFields
                location={location}
                prefix={makePrefixForField(location)}
              />
            );
          })}
        </Stack>
      )}
    </FormLayout>
  );
}
