import { Alert, AlertTitle, FormControlLabel, Switch } from "@mui/material";
import {
  Location,
  MarkupType,
  TaxGroup,
} from "@towersystems/roam-common/lib/generated-types";
import {
  getApplicableMarkupByType,
  getMarkupPercentage,
  toTitleCase,
} from "@towersystems/roam-common/lib/shared-utils";
import { FormLayout, InlineErrorAlert, Stack } from "components";
import { joinArrayWithAnd } from "../../../../utilities/list";
import { useLocationInventoryPricingForm } from "../../utils";
import { usePricingFormProvider } from "../../utils/pricing";
import { PricingFields } from "./components";

export interface PricingFormProps {
  globalPricing?: boolean;
  onGlobalPricingChange?: (value: boolean) => void;
  exposedKey?: string;
  taxable: boolean;
  taxGroups: TaxGroup[];
  locations: Location[];
  variant: "edit" | "create";
}

export function PricingForm({
  globalPricing,
  onGlobalPricingChange,
  exposedKey = "create-pricing-form",
  taxable,
  taxGroups,
  locations,
  variant,
}: PricingFormProps) {
  const isCreateVariant = variant === "create";

  const { category, department, error, refetch } = usePricingFormProvider();

  const { makePrefixForField } = useLocationInventoryPricingForm();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onGlobalPricingChange?.(event.target.checked);
  };

  const commonPricingFieldsProps = {
    taxable,
    taxGroups,
    departmentMarkups: department?.markup || [],
    categoryMarkups: category?.markup || [],
  };

  const markupPercentages = Object.values(MarkupType).map((markupType) => {
    const markups = {
      departmentMarkups: department?.markup,
      categoryMarkups: category?.markup,
    };

    const appliedMarkup = getApplicableMarkupByType(markups, markupType);

    const markupPercentage = getMarkupPercentage(appliedMarkup);

    return {
      markupType,
      markupPercentage,
      markupStyle: appliedMarkup?.markupStyle,
    };
  });

  if (error) {
    return (
      <InlineErrorAlert
        message="Error loading pricing markups"
        retryAction={refetch}
      />
    );
  }

  const appliedMarkups = markupPercentages.filter(
    (markup) => !!markup.markupPercentage
  );

  return (
    <FormLayout>
      {!isCreateVariant && (
        <FormControlLabel
          style={{ marginLeft: "auto" }}
          control={
            <Switch
              checked={globalPricing}
              onChange={handleChange}
              color="primary"
            />
          }
          label="Global Pricing"
        />
      )}
      {!!appliedMarkups.length && (
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
          Markups are being applied to the following pricing:{" "}
          {joinArrayWithAnd(
            appliedMarkups.map((markup) => {
              const suffix = markup.markupStyle
                ? ` ${markup.markupStyle.split("_").join(" ").toLowerCase()}`
                : "";

              return `${toTitleCase(markup.markupType)} (${
                markup.markupPercentage * 100
              }%${suffix})`;
            })
          )}
        </Alert>
      )}
      {globalPricing ? (
        <PricingFields
          exposedKey={`${exposedKey}-global`}
          {...commonPricingFieldsProps}
        />
      ) : (
        <Stack spacing={2}>
          {locations?.map((location, i) => {
            return (
              <PricingFields
                exposedKey={`${exposedKey}-${i}`}
                {...commonPricingFieldsProps}
                location={location}
                prefix={makePrefixForField(location)}
              />
            );
          })}
        </Stack>
      )}
    </FormLayout>
  );
}
