import { useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { useScheduler } from "../../utilities/use-scheduler";
import {
  COMBO_BOX_LIST_OPTIONS,
  ComboBoxBaseProps,
} from "../../utilities/combo-box";
import { QUERY_SUPPLIER_OPTIONS_LIST } from "../../utilities/supplier";
import { Autocomplete } from "../Autocomplete";

export const SupplierComboBox = ({ value, ...props }: ComboBoxBaseProps) => {
  const [executedQuery, setExecutedQuery] = useState("");
  const { schedule } = useScheduler();
  const [initialised, setInitialised] = useState(false);

  const { data, loading } = useQuery<{
    suppliers: {
      items: { id: string; name: string }[];
    };
  }>(QUERY_SUPPLIER_OPTIONS_LIST, {
    variables: {
      options: {
        ...COMBO_BOX_LIST_OPTIONS,
        filter: {
          name: {
            contains: executedQuery,
          },
        },
      },
    },
    onCompleted: () => setInitialised(true),
  });

  const options: any[] = useMemo(
    () =>
      data?.suppliers.items.map((supplier) => ({
        label: supplier.name,
        value: supplier.id,
      })) || [],
    [data]
  );

  return (
    <Autocomplete
      {...props}
      value={value}
      options={options}
      loading={loading}
      executedQuery={executedQuery}
      initialised={initialised}
      onInputChange={(event, newInputValue) => {
        if (value !== newInputValue) {
          schedule(() => setExecutedQuery(newInputValue));
        }
      }}
    />
  );
};
