import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ProductFormValues } from "../../utils";

export interface ProductSettingsFieldsProps {}

export function ProductSettingsFields(props: ProductSettingsFieldsProps) {
  const { control } = useFormContext<ProductFormValues>();

  return (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
      <FormLabel component="legend">Settings</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value} color="primary" />
              )}
            />
          }
          label="Active"
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Controller
              name="taxable"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value} color="primary" />
              )}
            />
          }
          label="Taxable"
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Controller
              name="serialNumbersArr"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value} color="primary" />
              )}
            />
          }
          label="Serial Number on Arrival"
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Controller
              name="serialNumbersSale"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value} color="primary" />
              )}
            />
          }
          label="Serial Number on Sale"
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  );
}
