import { Box, Pagination } from "@mui/material";
import { Product } from "@towersystems/roam-common/lib/generated-types";
import React from "react";
import {
  ProductResourceList,
  ProductResourceListProps,
} from "../../../../components/ProductResourceList";
import { UseAllProductsControllerReturn } from "../../utils/types";

export interface AllProductsTabViewProps {
  allProductsController: UseAllProductsControllerReturn;
}

export const AllProductsTabView = ({
  allProductsController: { paginatedList, dynamicSort, toggleSort },
}: AllProductsTabViewProps) => {
  const handleOnPageChange = React.useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      paginatedList.setPage(value);
    },
    [paginatedList.setPage]
  );

  const items: Product[] = paginatedList.loading
    ? paginatedList.previousResult?.items || []
    : paginatedList.result?.items || [];

  const listMarkup = items ? (
    <ProductResourceList
      loading={paginatedList.loading}
      items={items}
      dynamicSort={dynamicSort}
      toggleSort={toggleSort as ProductResourceListProps["toggleSort"]}
    />
  ) : undefined;

  const totalPages = paginatedList.maxPage;

  const paginationMarkup = (
    <Box sx={{ p: 2, borderColor: "grey.300" }} borderTop={1}>
      <Pagination
        onChange={handleOnPageChange}
        count={totalPages}
        page={paginatedList.page}
        variant="outlined"
        shape="rounded"
      />
    </Box>
  );

  return (
    <Box>
      {listMarkup}
      {paginationMarkup}
    </Box>
  );
};
