import {
  BarcodeLookupResult,
  InsufficientBarcodeLookupCreditsError,
  InvalidBarcodeLookupCredentialsError,
  ProductFoundResult,
  ProductInfo,
  ResultStatus,
  Uom,
} from "@towersystems/roam-common/lib/generated-types";

export const isInvalidBarcodeLookupCredentialsError = (
  barcodeLookupResult: BarcodeLookupResult
): barcodeLookupResult is InvalidBarcodeLookupCredentialsError => {
  return (
    barcodeLookupResult.__typename === "InvalidBarcodeLookupCredentialsError"
  );
};

export const isInsufficientBarcodeLookupCreditsError = (
  barcodeLookupResult: BarcodeLookupResult
): barcodeLookupResult is InsufficientBarcodeLookupCreditsError => {
  return (
    barcodeLookupResult.__typename === "InsufficientBarcodeLookupCreditsError"
  );
};

export const isProductFoundResult = (
  barcodeLookupResult: BarcodeLookupResult
): barcodeLookupResult is ProductFoundResult => {
  return (barcodeLookupResult as any)?.result === ResultStatus.FOUND;
};

export const productInfoToFormValues = (productInfo: ProductInfo) => {
  const weightProps = productInfo.weight
    ? parseWeightString(productInfo.weight)
    : { weight: undefined, UOM: undefined };
  return {
    name: productInfo.name,
    description: productInfo.description,
    ...weightProps,
  };
};

function parseWeightString(weightString: string) {
  const [weight, rawUom] = weightString?.length ? weightString.split(" ") : [];

  if (weight === undefined) {
    return { weight: undefined, UOM: undefined };
  }

  switch (rawUom) {
    case "kg":
      return { weight: weight, UOM: Uom.KG };
    case "g":
      return { weight: weight, UOM: Uom.g };
    case "lbs":
      return { weight: parseFloat(weight) * 0.45359237, UOM: Uom.KG };
    default:
      return { weight: undefined, UOM: undefined };
  }
}
