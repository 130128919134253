import { Await, Outlet, useLoaderData } from "react-router-dom";
import { ProductPageSkeleton } from "../../components/ProductPageSkeleton";
import React from "react";
import { ProductsRootContext } from "../../utils";

export interface ProductsRootPageProps {}

export function ProductsRootPage(props: ProductsRootPageProps) {
  const { data }: any = useLoaderData();

  return (
    <React.Suspense fallback={<ProductPageSkeleton />}>
      <Await resolve={data}>
        {([taxGroups, locations]) => {
          return (
            <ProductsRootContext.Provider
              value={{
                taxGroups,
                locations,
              }}
            >
              <Outlet />
            </ProductsRootContext.Provider>
          );
        }}
      </Await>
    </React.Suspense>
  );
}
