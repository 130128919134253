import { Outlet } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import {
  Box,
  Container,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { TopBar } from "../Topbar";
import { Frame } from "components";
import { FlashMessagesProvider } from "components";
import { useLocation } from "react-router-dom";
import { matchedLocation } from "../../utilities/match-navigation";
import ScrollToTop from "../ScrollTop/ScrollTop";
import { USER_TOKEN_KEY } from "../../utilities/authentication/constants";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PointOfSaleTwoToneIcon from "@mui/icons-material/PointOfSaleTwoTone";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import ReceiptLongTwoToneIcon from "@mui/icons-material/ReceiptLongTwoTone";
import FactoryTwoToneIcon from "@mui/icons-material/FactoryTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import { FrameContext } from "../../utilities/frame";
import { useFeaturesProvider } from "../../utilities/features";
import BuildTwoToneIcon from "@mui/icons-material/BuildTwoTone";
import { PermissionVisabilityHidden } from "../PermissionVisabilityHidden";

export interface LoggedInFrameProps {}

export const LoggedInFrame = ({}: LoggedInFrameProps) => {
  const { pathname } = useLocation();
  const features = useFeaturesProvider();

  return (
    <Frame>
      <FrameContext.Consumer>
        {(maybeContext) => {
          if (!maybeContext) {
            return null;
          }

          const { toggleDrawer, drawerOpen, mobileViewActive } = maybeContext;

          const toggleIfMobile = () => {
            if (mobileViewActive && drawerOpen) {
              toggleDrawer();
            }
          };

          return (
            <>
              <ScrollToTop />
              <FlashMessagesProvider>
                <Box sx={{ display: "flex", background: "red" }}>
                  <TopBar title="Retailer Roam">
                    <List component="nav">
                      <ListItemButton
                        component={Link}
                        to="/"
                        selected={matchedLocation(
                          { url: "/", exactMatch: true },
                          pathname
                        )}
                        onClick={toggleIfMobile}
                      >
                        <ListItemIcon>
                          <DashboardTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                      </ListItemButton>
                      <PermissionVisabilityHidden
                        permissions={["ManageProducts"]}
                      >
                        <ListItemButton
                          component={Link}
                          to="/products"
                          selected={matchedLocation(
                            { url: "/products", exactMatch: false },
                            pathname
                          )}
                          onClick={toggleIfMobile}
                        >
                          <ListItemIcon>
                            <ShoppingCartTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                          </ListItemIcon>
                          <ListItemText primary="Products" />
                        </ListItemButton>
                      </PermissionVisabilityHidden>
                      <PermissionVisabilityHidden
                        permissions={["ManageDepartments"]}
                      >
                        <ListItemButton
                          component={Link}
                          to="/departments"
                          selected={matchedLocation(
                            { url: "/departments", exactMatch: false },
                            pathname
                          )}
                          onClick={toggleIfMobile}
                        >
                          <ListItemIcon>
                            <CategoryOutlinedIcon htmlColor="var(--mui-palette-text-primary)" />
                          </ListItemIcon>
                          <ListItemText primary="Departments" />
                        </ListItemButton>
                      </PermissionVisabilityHidden>
                      <PermissionVisabilityHidden
                        permissions={["ManageCustomers"]}
                      >
                        <ListItemButton
                          component={Link}
                          to="/customers"
                          selected={matchedLocation(
                            { url: "/customers", exactMatch: false },
                            pathname
                          )}
                          onClick={toggleIfMobile}
                        >
                          <ListItemIcon>
                            <AccountBoxTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                          </ListItemIcon>
                          <ListItemText primary="Customers" />
                        </ListItemButton>
                      </PermissionVisabilityHidden>
                      <PermissionVisabilityHidden permissions={["ManageSales"]}>
                        <ListItemButton
                          component={Link}
                          to="/sales"
                          selected={matchedLocation(
                            { url: "/sales", exactMatch: false },
                            pathname
                          )}
                          onClick={toggleIfMobile}
                        >
                          <ListItemIcon>
                            <ReceiptLongTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                          </ListItemIcon>
                          <ListItemText primary="Sales" />
                        </ListItemButton>
                      </PermissionVisabilityHidden>
                      <PermissionVisabilityHidden
                        permissions={["ManageSuppliers"]}
                      >
                        <ListItemButton
                          component={Link}
                          to="/suppliers"
                          selected={matchedLocation(
                            { url: "/suppliers", exactMatch: false },
                            pathname
                          )}
                          onClick={toggleIfMobile}
                        >
                          <ListItemIcon>
                            <FactoryTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                          </ListItemIcon>
                          <ListItemText primary="Suppliers" />
                        </ListItemButton>
                      </PermissionVisabilityHidden>
                      <Divider sx={{ my: 1 }} />
                      {features.pointOfSale && (
                        <>
                          <ListItemButton
                            component={"a"}
                            href={`${
                              process.env.REACT_APP_POS_URL
                            }?auth=${localStorage.getItem(USER_TOKEN_KEY)}
                  `}
                            onClick={toggleIfMobile}
                          >
                            <ListItemIcon>
                              <PointOfSaleTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                            </ListItemIcon>
                            <ListItemText primary="Point of Sale" />
                          </ListItemButton>

                          <Divider sx={{ my: 1 }} />
                        </>
                      )}
                      {features.utilities && (
                        <>
                          <ListItemButton
                            component={"a"}
                            href={`${
                              process.env.REACT_APP_UTILITIES_URL
                            }?auth=${localStorage.getItem(USER_TOKEN_KEY)}
                  `}
                            onClick={toggleIfMobile}
                          >
                            <ListItemIcon>
                              <BuildTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                            </ListItemIcon>
                            <ListItemText primary="Image Management" />
                          </ListItemButton>
                          <Divider sx={{ my: 1 }} />
                        </>
                      )}
                      <PermissionVisabilityHidden
                        permissions={["ManageSettings"]}
                      >
                        <ListItemButton
                          component={Link}
                          to="/settings"
                          onClick={toggleIfMobile}
                        >
                          <ListItemIcon>
                            <SettingsTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                          </ListItemIcon>
                          <ListItemText primary="Settings" />
                        </ListItemButton>
                      </PermissionVisabilityHidden>
                    </List>
                  </TopBar>
                  <Box
                    component="main"
                    sx={{
                      flexGrow: 1,
                      height: "100vh",
                      overflow: "auto",
                      backgroundColor: "background.default",
                    }}
                  >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                      <Outlet />
                    </Container>
                  </Box>
                </Box>
              </FlashMessagesProvider>
            </>
          );
        }}
      </FrameContext.Consumer>
    </Frame>
  );
};
