import { MfaCodeStrategy } from "@towersystems/roam-common/lib/generated-types";
import * as yup from "yup";

export const schema = yup.object().shape({
  ui: yup.object().shape({
    codeSent: yup.boolean(),
    strategies: yup.array().of(yup.string()),
  }),
  data: yup.object().shape({
    code: yup.string().test("valid-code", "Invalid code", function (value) {
      //@ts-ignore
      const [_, rootSchema] = this.from;
      if (true === rootSchema.value.ui.codeSent) {
        return Boolean(value && value?.length >= 1);
      }
      return true;
    }),
    strategy: yup
      .string()
      .oneOf(
        [MfaCodeStrategy.SMS, MfaCodeStrategy.EMAIL],
        "Strategy must be one of the defined strategies"
      ),
    identifier: yup.string().when("strategy", {
      is: (strategy: any) =>
        strategy == MfaCodeStrategy.SMS || strategy == MfaCodeStrategy.EMAIL,
      then: yup
        .string()
        .test("valid-identifier", "Invalid identifier", function (value) {
          const { strategy } = this.parent;
          if (!value) {
            return false; // If identifier is empty, return false
          }
          if (strategy === MfaCodeStrategy.SMS) {
            // You can add your SMS validation logic here, e.g., regex or other checks
            // For example, checking if it's a valid phone number for SMS
            const smsRegex = /^[0-9]{10}$/; // Modify this regex as needed
            return smsRegex.test(value);
          } else if (strategy === MfaCodeStrategy.EMAIL) {
            // You can add your email validation logic here, e.g., regex or other checks
            // For example, using a simple regex for email validation
            const emailRegex =
              /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return emailRegex.test(value);
          }
          return false; // Return false for other strategies
        }),
      otherwise: yup.string().notRequired(), // If strategy is not SMS or EMAIL, identifier is not required
    }),
    password: yup
      .string()
      .test("password-required", "Password required", function (value) {
        //@ts-ignore
        const [_, rootSchema] = this.from;
        if (true === rootSchema.value.ui.codeSent) {
          return Boolean(value && value?.length >= 1);
        }
        return true;
      }),
  }),
});

export const mfaStrategyInfoMap = {
  [MfaCodeStrategy.SMS]: {
    title: "SMS",
    description: "Receive a code via SMS",
    identifierInputDescription:
      "Please enter a valid mobile number, a code will be sent to this number to verify your identity",
  },
  [MfaCodeStrategy.EMAIL]: {
    title: "Email",
    description: "Receive a code via Email",
    identifierInputDescription:
      "Please enter a valid email address, a code will be sent to this email address to verify your identity",
  },
};
