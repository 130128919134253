import { TextField, Typography } from "@mui/material";
import { Location } from "@towersystems/roam-common/lib/generated-types";
import { FormLayout } from "components";
import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ProductLocationPricingValues } from "../../../../utils";

export interface InventoryFieldsProps {
  location?: Location;
  prefix?: string;
  onSetValue?: (key: keyof ProductLocationPricingValues, value: any) => void;
}

export function InventoryFields({
  prefix = "global.",
  location,
  onSetValue,
}: InventoryFieldsProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const makeLocalId = useCallback(
    (name: string) => {
      return `${prefix}${name}`;
    },
    [prefix]
  );

  return (
    <FormLayout>
      {!!location && <Typography variant="h6">{location.name}</Typography>}

      <FormLayout.Group>
        <Controller
          name={makeLocalId("stockOnHand")}
          control={control}
          shouldUnregister
          render={({ field: { name, onChange, value } }) => {
            function handleChange(event: any) {
              const newValue = +event.target.value;
              onChange(newValue);
              onSetValue?.("stockOnHand", newValue);
            }

            return (
              <TextField
                label="On Hand"
                fullWidth
                value={value}
                onChange={handleChange}
                id={name}
                error={Boolean(errors.name)}
                type="number"
                inputProps={{
                  step: "1",
                }}
                helperText={errors.name?.message?.toString()}
              />
            );
          }}
        />
      </FormLayout.Group>
    </FormLayout>
  );
}
