import { Typography, Box, IconButton } from "@mui/material";
import {
  Location,
  Markup,
  TaxGroup,
} from "@towersystems/roam-common/lib/generated-types";
import { CurrencyField, FormLayout, TaxGroupComboBox } from "components";
import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import {
  Controller,
  ControllerProps,
  SetValueConfig,
  useFormContext,
} from "react-hook-form";
import { NumberFormatValues, SourceInfo } from "react-number-format";
import {
  ReactivePricingCalculatorState,
  useReactivePricingCalculator,
} from "../../../../../../utilities/pricing";
import {
  ProductFormValues,
  ProductLocationPricingValues,
} from "../../../../utils";
import { MdCalculate } from "react-icons/md";

export interface PricingFieldsProps {
  exposedKey: string;
  prefix?: string;
  onSetValue?: (key: keyof ProductLocationPricingValues, value: any) => void;
  taxable: boolean;
  taxGroups: TaxGroup[];
  location?: Location;
  departmentMarkups: Markup[];
  categoryMarkups: Markup[];
}

const setValueOptions = { shouldValidate: true, shouldDirty: true };

export const PricingFields = ({
  exposedKey,
  prefix = "global.",
  onSetValue,
  taxable,
  taxGroups,
  location,
  departmentMarkups,
  categoryMarkups,
}: PricingFieldsProps) => {
  const methods =
    useFormContext<Pick<ProductFormValues, "locationInventoryPricing">>();

  const { control, getValues, setValue, resetField } = methods;

  const makeLocalId = useCallback(
    (name: string) => {
      return `${prefix}${name}` as any;
    },
    [prefix]
  );

  const reactivePricingProps = useMemo(
    () => ({
      departmentMarkups,
      categoryMarkups,
      ...deriveDefaultValues(),
    }),
    []
  );

  const pricingCalculator = useReactivePricingCalculator(reactivePricingProps);

  function deriveDefaultValues() {
    const taxGroup = getCurrentTaxGroup();

    return {
      costPriceEx: {
        value: getValues(makeLocalId("costPriceEx")),
        locked: false,
      },
      retailPrice: {
        value: getValues(makeLocalId("retailPrice")),
        locked: false,
      },
      costPriceInc: {
        value: getValues(makeLocalId("costPriceInc")),
        locked: false,
      },
      taxRatePercentage: taxGroup?.salePercentage,
    };
  }

  function handleUpdateTaxGroup(newTaxGroupId: string | null) {
    const targetTaxGroup = taxGroups.find(
      (taxGroup) => taxGroup.id === newTaxGroupId
    );

    const taxGroup = taxable ? targetTaxGroup : null;

    handleSetValue("taxGroupId", taxable ? newTaxGroupId : null);
    updatePricing(
      pricingCalculator.actions.updateTaxRatePercentage(
        taxGroup?.salePercentage || 0
      )
    );
  }

  const handleUpdateCostPriceEx = (
    { floatValue }: NumberFormatValues,
    sourceInfo?: SourceInfo
  ) => {
    if (!shouldUpdateState(floatValue, sourceInfo)) return;
    const newState = pricingCalculator.actions.updateCostPriceEx({
      value: floatValue,
      locked: true,
    });
    updatePricing(newState);
  };

  function handleUpdateCostPriceInc(
    { floatValue }: NumberFormatValues,
    sourceInfo?: SourceInfo
  ) {
    if (!shouldUpdateState(floatValue, sourceInfo)) return;
    updatePricing(
      pricingCalculator.actions.updateCostPriceInc({
        value: floatValue,
        locked: true,
      })
    );
  }

  function handleUpdateRetailPrice(
    { floatValue }: NumberFormatValues,
    sourceInfo?: SourceInfo
  ) {
    if (!shouldUpdateState(floatValue, sourceInfo)) return;
    updatePricing(
      pricingCalculator.actions.updateRetailPrice({
        value: floatValue,
        locked: true,
      })
    );
  }

  function handleRecalculateFromRetailPrice() {
    pricingCalculator.actions.resetLocks(false);
    // trigger update from retail price
    updatePricing(
      pricingCalculator.actions.updateRetailPrice({
        locked: true,
      })
    );
  }

  function handleUpdateRRPrice(
    { floatValue }: NumberFormatValues,
    sourceInfo?: SourceInfo
  ) {
    if (!shouldUpdateState(floatValue, sourceInfo)) return;
    handleSetValue("rrPrice", floatValue);
  }

  function handleUpdateTradePrice(
    { floatValue }: NumberFormatValues,
    sourceInfo?: SourceInfo
  ) {
    if (!shouldUpdateState(floatValue, sourceInfo)) return;
    updatePricing(
      pricingCalculator.actions.updateTradePrice({
        value: floatValue,
        locked: true,
      })
    );
  }

  function updatePricing(
    pricing: ReactivePricingCalculatorState,
    options?: SetValueConfig
  ) {
    handleSetValue("costPriceInc", pricing.costPriceInc.value, options);
    handleSetValue("costPriceEx", pricing.costPriceEx.value, options);
    handleSetValue("retailPrice", pricing.retailPrice.value, options);
    handleSetValue("tradePrice", pricing.tradePrice.value, options);
  }

  function handleSetValue(
    key: any,
    value: any,
    options: SetValueConfig = setValueOptions
  ) {
    setValue(makeLocalId(key), value, options);
    onSetValue?.(key, value);
  }

  function getCurrentTaxGroup() {
    return taxGroups.find(
      (taxGroup) => taxGroup.id === getValues(makeLocalId("taxGroupId"))
    );
  }

  useLayoutEffect(() => {
    pricingCalculator.reset({
      departmentMarkups,
      categoryMarkups,
      ...deriveDefaultValues(),
    });

    const newDefaults = pricingCalculator.getValues();

    resetField(makeLocalId("costPriceInc"), {
      defaultValue: newDefaults.costPriceInc.value,
    });
    resetField(makeLocalId("costPriceEx"), {
      defaultValue: newDefaults.costPriceEx.value,
    });
    resetField(makeLocalId("retailPrice"), {
      defaultValue: newDefaults.retailPrice.value,
    });
    resetField(makeLocalId("tradePrice"), {
      defaultValue: newDefaults.tradePrice.value,
    });
  }, [
    exposedKey,
    JSON.stringify(categoryMarkups),
    JSON.stringify(departmentMarkups),
  ]);

  useEffect(() => {
    const taxGroup = getCurrentTaxGroup();
    handleUpdateTaxGroup(taxable && taxGroup ? taxGroup.id : null);
  }, [taxable]);

  return (
    <FormLayout>
      {!!location && <Typography variant="h6">{location.name}</Typography>}

      <FormLayout.Group>
        <Controller
          name={makeLocalId("costPriceEx")}
          shouldUnregister
          render={(props) => (
            <CurrencyField
              {...formatCurrencyFieldProps(props)}
              label="Cost Ex."
              onValueChange={handleUpdateCostPriceEx}
              locked={pricingCalculator.state.costPriceEx.locked}
              onLockPress={() => {
                pricingCalculator.actions.updateCostPriceEx({ locked: false });
              }}
            />
          )}
        />
        <Controller
          name={makeLocalId("costPriceInc")}
          shouldUnregister
          render={(props) => (
            <CurrencyField
              {...formatCurrencyFieldProps(props)}
              label="Cost Inc."
              onValueChange={handleUpdateCostPriceInc}
              locked={pricingCalculator.state.costPriceInc.locked}
              onLockPress={() => {
                pricingCalculator.actions.updateCostPriceInc({ locked: false });
              }}
            />
          )}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <Controller
          name={makeLocalId("retailPrice")}
          shouldUnregister
          render={(props) => (
            <CurrencyField
              {...formatCurrencyFieldProps(props)}
              label="Retail Price"
              onValueChange={handleUpdateRetailPrice}
              locked={pricingCalculator.state.retailPrice.locked}
              onLockPress={() => {
                pricingCalculator.actions.updateRetailPrice({ locked: false });
              }}
              endAdornment={
                <IconButton onClick={handleRecalculateFromRetailPrice}>
                  <MdCalculate />
                </IconButton>
              }
            />
          )}
        />
        <Controller
          name={makeLocalId("rrPrice")}
          shouldUnregister
          render={(props) => (
            <CurrencyField
              {...formatCurrencyFieldProps(props)}
              label="RRP"
              onValueChange={handleUpdateRRPrice}
            />
          )}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <Controller
          name={makeLocalId("tradePrice")}
          shouldUnregister
          render={(props) => (
            <CurrencyField
              {...formatCurrencyFieldProps(props)}
              label="Trade Price"
              onValueChange={handleUpdateTradePrice}
              locked={pricingCalculator.state.tradePrice.locked}
              onLockPress={() => {
                pricingCalculator.actions.updateTradePrice({ locked: false });
              }}
            />
          )}
        />
        {taxable ? (
          <Controller
            control={control}
            name={makeLocalId("taxGroupId")}
            shouldUnregister
            render={({ field: { name, value } }) => (
              <TaxGroupComboBox
                id={name}
                label="Tax Group"
                value={value}
                onChange={handleUpdateTaxGroup}
              />
            )}
          />
        ) : (
          <Box style={{ width: "100%" }} />
        )}
      </FormLayout.Group>
    </FormLayout>
  );
};

function formatCurrencyFieldProps(
  props: Parameters<ControllerProps["render"]>[0]
) {
  return {
    ...props,
    fullWidth: true,
    defaultValue: 0,
    value: props.field.value,
  };
}

function shouldUpdateState(
  floatValue: number | undefined,
  sourceInfo?: SourceInfo
) {
  const shouldNotUpdate =
    floatValue === undefined || sourceInfo?.source === "prop";
  return !shouldNotUpdate;
}
