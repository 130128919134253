import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  MenuItem,
} from "@mui/material";

export type SelectProps = MuiSelectProps & {
  leadingChildren?: React.ReactNode;
  trailingChildren?: React.ReactNode;
  options?: SelectOptionProps[];
};

export interface SelectOptionProps {
  value: string;
  label: string;
  disabled?: boolean;
}

export const Select = ({
  options,
  leadingChildren,
  trailingChildren,
  children,
  ...props
}: SelectProps) => {
  const optionsMarkup = options?.map((option) => {
    return (
      <MenuItem
        value={option.value}
        disabled={option.disabled}
        key={option.value}
      >
        {option.label}
      </MenuItem>
    );
  });

  return (
    <MuiSelect displayEmpty={true} {...props}>
      {leadingChildren}
      {optionsMarkup}
      {children}
      {trailingChildren}
    </MuiSelect>
  );
};
