import { useSuspenseQuery } from "@apollo/client";
import {
  Category,
  Department,
} from "@towersystems/roam-common/lib/generated-types";
import { QUERY_CATEGORY_DEEP_NESTING } from "../../../../utilities/categories";
import { QUERY_DEPARTMENT_DEEP_NESTING } from "../../../../utilities/departments";
import { PricingFormContext } from "../../utils/pricing/context";

export interface PricingFormProviderProps {
  categoryId?: string;
  departmentId?: string;
  children?: React.ReactNode;
}

export function PricingFormProvider({
  categoryId,
  departmentId,
  children,
}: PricingFormProviderProps) {
  const commonSuspenseQueryOptions = {
    errorPolicy: "all" as const,
  };

  const {
    data: departmentResult,
    error: departmentError,
    refetch: refetchDepartments,
  } = useSuspenseQuery<{
    department: Department;
  }>(QUERY_DEPARTMENT_DEEP_NESTING, {
    variables: {
      id: departmentId,
    },
    skip: !departmentId,
    ...commonSuspenseQueryOptions,
  });

  const {
    data: categoryResult,
    error: categoryError,
    refetch: refetchCategories,
  } = useSuspenseQuery<{
    category: Category;
  }>(QUERY_CATEGORY_DEEP_NESTING, {
    variables: {
      id: categoryId,
    },
    skip: !categoryId,
    ...commonSuspenseQueryOptions,
  });

  function handleRefetch() {
    refetchDepartments();
    refetchCategories();
  }

  return (
    <PricingFormContext.Provider
      value={{
        category: categoryId ? categoryResult?.category : undefined,
        department: departmentId ? departmentResult?.department : undefined,
        refetch: handleRefetch,
        error:
          (departmentId && departmentError) || (categoryId && categoryError),
      }}
    >
      {children}
    </PricingFormContext.Provider>
  );
}
