import { useMutation } from "@apollo/client";
import {
  CreateProductInput,
  Product,
  ProductInfo,
  Location,
} from "@towersystems/roam-common/lib/generated-types";
import { useCallback, useContext, useState } from "react";
import {
  GENERIC_BARCODE_FAILURE_MESSAGE,
  MUTATION_CREATE_PRODUCT,
  MUTATION_GENERATE_PRODUCT_BARCODE,
} from "./constants";
import { minBy } from "lodash";
import { EditProductContext, ProductsRootContext } from "./context";
import {
  isInsufficientBarcodeLookupCreditsError,
  isInvalidBarcodeLookupCredentialsError,
  isProductFoundResult,
  productInfoToFormValues,
  useLookupBarcode,
} from "../barcode-lookup";
import { useFlashMessages } from "../../../../utilities/flash-messages";
import { ProductFormValues, ProductLocationPricingValues } from "./types";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

const GENERIC_BARCODE_LOOKUP_FAILURE_MESSAGE = "Failed to lookup barcode";
const BARCODE_LOOKUP_INVALID_CREDENTIALS_MESSAGE =
  "Invalid key used for lookup barcode";
const BARCODE_LOOKUP_INSUFFICIENT_CREDITS_MESSAGE =
  "Insufficient credits to perform barcode lookup";
const BARCODE_LOOKUP_NO_DATA_MESSAGE =
  "No product information found for barcode";

const SET_VALUE_OPTIONS = {
  shouldValidate: true,
  shouldDirty: true,
};

export const useMutationCreateProduct = () => {
  const [m] = useMutation<{
    createProduct: Product;
  }>(MUTATION_CREATE_PRODUCT);
  const createProduct = useCallback(
    async (input: CreateProductInput) => {
      const d = await m({ variables: { input } });
      if (!d.data) {
        throw new Error();
      }
      return d.data?.createProduct;
    },
    [m]
  );
  return {
    createProduct,
  };
};

export const lowestPrice = (product: Product): number => {
  const variants = product.variants || [];

  const allLocationInventoryPricing = [
    ...product.locationInventoryPricing,
    ...variants.flatMap((v) => v.locationInventoryPricing),
  ];

  return minBy(allLocationInventoryPricing.map((i) => i.retailPrice)) || 0;
};

export const useProductsRootProvider = () => {
  const c = useContext(ProductsRootContext);
  if (!c) {
    throw Error(
      "useProductsRootProvider must be used within a ProductsRootProvider"
    );
  }
  return c;
};

export const useBarcodeGenerator = () => {
  const [generateBarcode] = useMutation<{
    generateProductBarcode: string;
  }>(MUTATION_GENERATE_PRODUCT_BARCODE);

  async function generate() {
    return generateBarcode().then((res) => {
      const newBarcode = res?.data?.generateProductBarcode;
      if (!newBarcode) {
        throw new Error(GENERIC_BARCODE_FAILURE_MESSAGE);
      }
      return newBarcode;
    });
  }

  return { generate };
};

export const useProductFormBarcode = () => {
  const { showMessage } = useFlashMessages();

  const {
    control,
    register,
    setValue,
    formState: { errors, dirtyFields },
  } = useFormContext<ProductFormValues>();

  const [loadingLookup, setLoadingLookup] = useState(false);

  const { lookupBarcode } = useLookupBarcode();

  const stockNumber = useWatch({ control, name: "stockNumber" });

  async function handleBarcodeLookup() {
    setLoadingLookup(true);

    return lookupBarcode({ barcode: stockNumber })
      .then((res) => {
        if (!res) {
          return showMessage({
            severity: "error",
            message: BARCODE_LOOKUP_NO_DATA_MESSAGE,
          });
        }
        if (isProductFoundResult(res)) {
          return handleApplyBarcodeLookup(res.product as ProductInfo);
        }

        if (isInsufficientBarcodeLookupCreditsError(res)) {
          return showMessage({
            severity: "error",
            message: BARCODE_LOOKUP_INSUFFICIENT_CREDITS_MESSAGE,
          });
        } else if (isInvalidBarcodeLookupCredentialsError(res)) {
          return showMessage({
            severity: "error",
            message: BARCODE_LOOKUP_INVALID_CREDENTIALS_MESSAGE,
          });
        }
      })
      .catch((e) => {
        showMessage({
          severity: "error",
          message:
            `${GENERIC_BARCODE_LOOKUP_FAILURE_MESSAGE}: ` + e?.toString(),
        });
      })
      .finally(() => {
        setLoadingLookup(false);
      });
  }

  function handleApplyBarcodeLookup(productInfo: ProductInfo) {
    const formValues = productInfoToFormValues(productInfo);
    formValues.name && setValue("name", formValues.name, SET_VALUE_OPTIONS);
    formValues.description &&
      setValue("description", formValues.description, SET_VALUE_OPTIONS);
    formValues.weight !== undefined &&
      setValue("weight", parseFloat(`${formValues.weight}`), SET_VALUE_OPTIONS);
    formValues.UOM && setValue("UOM", formValues.UOM, SET_VALUE_OPTIONS);
  }

  return {
    handleBarcodeLookup,
    loadingLookup,
  };
};

export const useLocationInventoryPricingForm = () => {
  const { control, setValue } = useFormContext<ProductFormValues>();

  const locationsFieldArray = useFieldArray({
    control,
    name: "locationInventoryPricing",
    keyName: "localId",
  });

  function handleGlobalSetValue(
    key: keyof ProductLocationPricingValues,
    value: any
  ) {
    locationsFieldArray.fields.forEach((field, index) => {
      const prefix = `locationInventoryPricing.${index}.` as const;
      const fieldKey = `${prefix}${key}` as const;

      setValue(fieldKey, value, {
        shouldDirty: true,
        shouldValidate: true,
      });
    });
  }

  function makePrefixForField(location: Location) {
    const fieldIndex = locationsFieldArray.fields.findIndex(
      (field) =>
        field.retailerLocationKey === location.retailerLocationKey ||
        field.locationId === location.id
    );

    const prefix = `locationInventoryPricing.${fieldIndex}.`;

    return prefix;
  }

  return {
    handleGlobalSetValue,
    makePrefixForField,
  };
};

export const useEditProductProvider = () => {
  const c = useContext(EditProductContext);
  if (!c)
    throw Error(
      "useEditProductProvider must be used within an EditProductProvider"
    );
  return c;
};
