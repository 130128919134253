import {
  PaymentMethod,
  SelfCheckoutSettings,
} from "@towersystems/roam-common/lib/generated-types";
import React from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { SelfCheckoutSettingsFormProvider } from "../../components/SelfCheckoutSettingsForm/SelfCheckoutSettingsFormProvider";
import { SelfCheckoutSettingsFormValues } from "../../components/SelfCheckoutSettingsForm/types";
import { useFormContext } from "react-hook-form";
import { ContextualSaveBar, Page, Stack } from "../../../../components";
import { SelfCheckoutSettingsForm } from "../../components/SelfCheckoutSettingsForm/SelfCheckoutSettingsForm";
import { useMutationSelfCheckoutSettingsUpdate } from "../../utils/self-checkout-settings";
import { useModal } from "../../../../utilities/use-modal";
import { SettingCard } from "../../components";
import { Box, Divider, Typography } from "@mui/material";
import { Button } from "../../../../components/Button";
import { UploadImageDialogue } from "../../../../components/UploadImageDialogue";
import PlaceTwoToneIcon from "@mui/icons-material/PlaceTwoTone";
import { Badge } from "../../../../components/Badge";
import { NavigateRightButton } from "../../../../components/NavigateRightButton";
import { ButtonBase } from "@mui/material";

export interface SelfCheckoutSettingsPageProps {}

const Content = ({
  selfCheckoutSettings,
  paymentMethods,
}: SelfCheckoutSettingsPageProps & {
  selfCheckoutSettings: SelfCheckoutSettings;
  paymentMethods: PaymentMethod[];
}) => {
  const navigate = useNavigate();

  const {
    formState: { isValid, isSubmitting, isSubmitSuccessful, isDirty, errors },
    setError,
    handleSubmit,
    reset,
    watch,
    getValues,
  } = useFormContext<SelfCheckoutSettingsFormValues>();
  const imageModal = useModal();
  const { mutation } = useMutationSelfCheckoutSettingsUpdate();
  const watchEnabled = watch("enabled");

  const handleOnSubmit = React.useCallback(
    async (values: SelfCheckoutSettingsFormValues) => {
      return mutation({
        enabled: values.enabled,
        paymentMethodId: values.paymentMethodId
          ? values.paymentMethodId
          : undefined,
      })
        .then(async (result) => {})
        .catch((err) => {});
    },
    []
  );

  const handleOnDeleteImage = async () => {
    return mutation({
      logo: null,
    })
      .then(() => {
        navigate(".", { replace: true });
        return true;
      })
      .catch(() => false);
  };

  const handleOnUploadImage = async (file: File) => {
    return mutation({
      logo: file,
    })
      .then((res) => {
        navigate(".", { replace: true });
        return true;
      })
      .catch((err) => false);
  };

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      const values = getValues();
      reset(values);
    }
  }, [reset, isSubmitSuccessful]);

  const disabled = !isValid || !isDirty || isSubmitting;

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit(handleOnSubmit),
        disabled: disabled,
      }}
      cancelAction={{ onAction: () => reset() }}
    />
  ) : undefined;

  const logoMarkup = selfCheckoutSettings.logoURL ? (
    <img
      src={selfCheckoutSettings.logoURL}
      alt="Logo"
      style={{ maxWidth: "200px", maxHeight: "100%" }}
    />
  ) : (
    <div>No logo uploaded</div>
  );

  const checkoutPaymentMethods = paymentMethods.filter(
    (i) =>
      i.paytype.internal === false &&
      i.paytype.channels.includes("self-checkout")
  );

  return (
    <Page title="Self Checkout Settings">
      {contextualSaveBar}
      <Stack spacing={3} divider={<Divider variant="middle" />}>
        <SettingCard
          wrapped
          title="Details"
          description="Update your self checkout settings"
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            maxWidth="100%"
          >
            {logoMarkup ? <Box marginRight={5}>{logoMarkup}</Box> : undefined}

            <Box flex={1} paddingTop={1}>
              <Button onClick={imageModal.onOpen}>Upload Logo</Button>
              <Box>
                <Typography variant="caption">
                  Display a logo in the header of the self checkout app
                </Typography>
              </Box>
            </Box>
          </Stack>
        </SettingCard>
        <SelfCheckoutSettingsForm paymentMethods={checkoutPaymentMethods} />
        {watchEnabled && (
          <SettingCard
            wrapped
            title="Locations"
            description="Manage the locations that are available for self checkout"
            cardSx={{ p: 0 }}
            beforeCard={
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Button
                  onClick={() =>
                    navigate(`/settings/self-checkout/locations/new`)
                  }
                >
                  Add Location
                </Button>
              </Box>
            }
          >
            <Stack divider={<Divider />}>
              {selfCheckoutSettings.locationSettings.map((l) => {
                return (
                  <Box
                    component={ButtonBase}
                    style={{
                      cursor: "pointer",
                    }}
                    display="flex"
                    flexDirection={"row"}
                    alignItems={"center"}
                    key={l.locationId}
                    sx={{ p: 2 }}
                    onClick={() => {
                      navigate(
                        `/settings/self-checkout/locations/${l.locationId}/edit`
                      );
                    }}
                  >
                    <Box>
                      <Box
                        sx={{ mr: 2 }}
                        style={{
                          width: 50,
                          height: 50,
                          border:
                            "1px solid var(--mui-palette-border-standard)",
                          borderRadius: 4,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <PlaceTwoToneIcon />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        flexGrow: 1,
                        textAlign: "left",
                      }}
                    >
                      <Box>
                        <Typography>
                          {l.location?.name || "Unknown Location Name"}
                        </Typography>
                        <Typography variant="caption">
                          {l.terminal &&
                            (l.terminal?.name
                              ? `Terminal: ${l.terminal?.name}`
                              : "Unknown Terminal Name")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Stack direction="row" justifyContent={"flex-end"}>
                        <Badge state={l.enabled ? "active" : "disabled"} />
                        <NavigateRightButton />
                      </Stack>
                    </Box>
                  </Box>
                );
              })}
            </Stack>
          </SettingCard>
        )}
      </Stack>
      {imageModal.open && (
        <UploadImageDialogue
          onDelete={handleOnDeleteImage}
          showDelete={Boolean(selfCheckoutSettings.logoURL)}
          onSubmit={handleOnUploadImage}
          deleteThumbnailSrc={selfCheckoutSettings.logoURL}
          {...imageModal}
        />
      )}
    </Page>
  );
};

export const SelfCheckoutSettingsPage = (
  props: SelfCheckoutSettingsPageProps
) => {
  const prefetchData: any = useLoaderData();

  return (
    <React.Suspense fallback={<div>loading</div>}>
      <Await resolve={prefetchData.data}>
        {([selfCheckoutSettings, paymentMethods]: [
          SelfCheckoutSettings,
          PaymentMethod[]
        ]) => {
          const { logoURL, ...formValues } = selfCheckoutSettings;
          return (
            <SelfCheckoutSettingsFormProvider defaultValues={formValues}>
              <Content
                paymentMethods={paymentMethods}
                selfCheckoutSettings={selfCheckoutSettings}
                {...props}
              />
            </SelfCheckoutSettingsFormProvider>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
