import { gql } from "@apollo/client";
import {
  CategoryNoNestingFragment,
  DepartmentNoNestingFragment,
  MarkupDeepNestingFragment,
  PaginatedListMetaNoNestingFragment,
  ProductFragment,
  ProductImageNoNestingFragment,
  ProductLocationInventoryPricingNoNestingFragment,
  ProductNoNestingFragment,
  ProductOptionGroupTemplateNoNestingFragment,
  ProductOptionNoNestingFragment,
  SelectedOptionNoNestingFragment,
  SupplierNoNestingFragment,
  TaxGroupNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";
import { Uom } from "@towersystems/roam-common/lib/generated-types";
import * as Yup from "yup";

export const GENERIC_BARCODE_FAILURE_MESSAGE = "Failed to generate barcode";

export const QUERY_PRODUCT_LIST = gql`
  ${PaginatedListMetaNoNestingFragment}
  ${ProductNoNestingFragment}
  query products($options: ProductListOptions) {
    products(options: $options) {
      items {
        ...ProductNoNesting
        image {
          id
          path
        }
      }
      meta {
        ...PaginatedListMetaNoNesting
      }
    }
  }
`;

export const QUERY_TAX_GROUP_LIST = gql`
  ${PaginatedListMetaNoNestingFragment}
  ${TaxGroupNoNestingFragment}
  query taxGroups($options: TaxGroupListOptions) {
    taxGroups(options: $options) {
      items {
        ...TaxGroupNoNesting
      }
      meta {
        ...PaginatedListMetaNoNesting
      }
    }
  }
`;

export const QUERY_PRODUCT_VARIANTS = gql`
  ${ProductFragment}
  ${ProductNoNestingFragment}
  ${ProductOptionNoNestingFragment}
  ${ProductLocationInventoryPricingNoNestingFragment}
  ${SelectedOptionNoNestingFragment}
  query productVariants($id: ID!) {
    productVariants(id: $id) {
      ...Product
      variants {
        ...Product
      }
    }
  }
`;

export const MUTATION_CREATE_PRODUCT = gql`
  ${ProductNoNestingFragment}
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      ...ProductNoNesting
    }
  }
`;

export const QUERY_EDIT_PRODUCT = gql`
  ${ProductFragment}
  ${ProductNoNestingFragment}
  ${ProductOptionNoNestingFragment}
  ${ProductLocationInventoryPricingNoNestingFragment}
  ${SelectedOptionNoNestingFragment}
  ${ProductOptionGroupTemplateNoNestingFragment}
  ${SupplierNoNestingFragment}
  ${TaxGroupNoNestingFragment}
  ${CategoryNoNestingFragment}
  ${DepartmentNoNestingFragment}
  ${MarkupDeepNestingFragment}
  ${ProductLocationInventoryPricingNoNestingFragment}
  ${ProductImageNoNestingFragment}
  query product($id: ID!) {
    product(id: $id) {
      ...Product
      variants {
        id
        displayName
        totalInventory
        locationInventoryPricing {
          retailPrice
        }
      }
      department {
        ...DepartmentNoNesting
        markup {
          ...MarkupDeepNesting
        }
      }
      category {
        ...CategoryNoNesting
        markup {
          ...MarkupDeepNesting
        }
      }
      locationInventoryPricing {
        ...ProductLocationInventoryPricingNoNesting
        location {
          name
        }
      }
    }
  }
`;

export const QUERY_PRODUCT = gql`
  ${ProductFragment}
  ${ProductNoNestingFragment}
  ${ProductOptionNoNestingFragment}
  ${ProductLocationInventoryPricingNoNestingFragment}
  ${ProductOptionGroupTemplateNoNestingFragment}
  ${SelectedOptionNoNestingFragment}
  ${SupplierNoNestingFragment}
  ${TaxGroupNoNestingFragment}
  ${CategoryNoNestingFragment}
  ${DepartmentNoNestingFragment}
  ${ProductImageNoNestingFragment}
  query product($id: ID!) {
    product(id: $id) {
      ...Product
      variants {
        id
        displayName
        totalInventory
        locationInventoryPricing {
          retailPrice
        }
      }
      locationInventoryPricing {
        ...ProductLocationInventoryPricingNoNesting
        location {
          name
        }
      }
    }
  }
`;

export const MUTATION_GENERATE_PRODUCT_BARCODE = gql`
  mutation GenerateProductBarcode {
    generateProductBarcode
  }
`;

export const MUTATION_DELETE_PRODUCT = gql`
  mutation softDeleteProduct($id: ID!) {
    softDeleteProduct(id: $id) {
      result
    }
  }
`;

export const MUTATION_UPLOAD_PRODUCT_IMAGE = gql`
  mutation createProductImage($input: CreateProductImageInput!) {
    createProductImage(input: $input) {
      ... on ProductImage {
        id
      }
      ... on MimeTypeError {
        message
      }
    }
  }
`;

export const MUTATION_DELETE_PRODUCT_IMAGE = gql`
  mutation deleteProductImage($imageId: ID!) {
    deleteProductImage(imageId: $imageId) {
      result
    }
  }
`;

export const MUTATION_UPDATE_PRODUCT = gql`
  ${ProductFragment}
  ${ProductNoNestingFragment}
  ${ProductOptionNoNestingFragment}
  ${ProductLocationInventoryPricingNoNestingFragment}
  ${SelectedOptionNoNestingFragment}
  ${SupplierNoNestingFragment}
  ${TaxGroupNoNestingFragment}
  ${CategoryNoNestingFragment}
  ${DepartmentNoNestingFragment}
  ${ProductImageNoNestingFragment}
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      ...Product
      variants {
        id
        displayName
        totalInventory
        locationInventoryPricing {
          retailPrice
        }
      }
      locationInventoryPricing {
        ...ProductLocationInventoryPricingNoNesting
        location {
          name
        }
      }
    }
  }
`;

export const MUTATION_UPDATE_PRODUCT_SUB_PAGE = gql`
  ${ProductFragment}
  ${ProductNoNestingFragment}
  ${ProductOptionNoNestingFragment}
  ${ProductLocationInventoryPricingNoNestingFragment}
  ${SelectedOptionNoNestingFragment}
  ${ProductOptionGroupTemplateNoNestingFragment}
  ${SupplierNoNestingFragment}
  ${TaxGroupNoNestingFragment}
  ${CategoryNoNestingFragment}
  ${MarkupDeepNestingFragment}
  ${DepartmentNoNestingFragment}
  ${ProductImageNoNestingFragment}
  mutation UpdateProductSubPage($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      ...Product
      department {
        ...DepartmentNoNesting
        markup {
          ...MarkupDeepNesting
        }
      }
      category {
        ...CategoryNoNesting
        markup {
          ...MarkupDeepNesting
        }
      }
      locationInventoryPricing {
        ...ProductLocationInventoryPricingNoNesting
      }
    }
  }
`;

export const defaultCreateProductFormValues = {
  stockCode: "",
  stockNumber: "",
  taxable: false,
  name: "",
  description: "",
  variantParentId: "",
  options: undefined,
  uom: Uom.g,
  selectedOptions: undefined,
  serialNumbersArr: false,
  serialNumbersSale: false,
  locationInventoryPricing: undefined,
};

export const defaultPricingFields = {
  costPriceInc: 0,
  costPriceEx: 0,
  retailPrice: 0,
  rrPrice: 0,
  tradePrice: 0,
  taxGroup: undefined,
};

export const defaultLocationInventoryPricingFormValues = {
  stockOnHand: 0,
  ...defaultPricingFields,
  serialNumbers: [],
};

const selectedOptionInputSchema = Yup.object({
  name: Yup.string().required(),
  value: Yup.string().required(),
});

const locationInventoryPricingInputSchema = Yup.object({
  id: Yup.string(),
  retailerLocationKey: Yup.string().optional().notRequired(),
  stockOnHand: Yup.number().integer(),
  costPriceEx: Yup.number(),
  costPriceInc: Yup.number(),
  retailPrice: Yup.number(),
  rrPrice: Yup.number(),
  tradePrice: Yup.number(),
  taxGroupId: Yup.string().optional().notRequired(),
  serialNumbers: Yup.array(Yup.string().required()),
});

const testPriceLookupLetter = (value?: string) => {
  const leadingLetterRegex = /^[a-zA-Z]/;

  if (value?.length && !leadingLetterRegex.test(value)) {
    return false;
  }

  return true;
};

const commonProductFormSchema = Yup.object().shape({
  stockCode: Yup.string(),
  stockNumber: Yup.string().required(),
  priceLookup: Yup.string().test(
    "priceLookup",
    "PLU must begin with a letter",
    testPriceLookupLetter
  ),
  description: Yup.string(),
  departmentId: Yup.string(),
  categoryId: Yup.string(),
  tags: Yup.array(Yup.string()),
  UOM: Yup.string(),
  weight: Yup.number().min(0),
  supplierId: Yup.string(),
  supplierStockCode: Yup.string(),
  taxable: Yup.boolean(),
  active: Yup.boolean(),
  serialNumbersArr: Yup.boolean(),
  serialNumbersSale: Yup.boolean(),
  options: Yup.array(Yup.string().required()),
  selectedOptions: Yup.array(selectedOptionInputSchema.required()),
  locationInventoryPricing: Yup.array(
    locationInventoryPricingInputSchema.required()
  ),
});

export const createProductFormSchema = commonProductFormSchema.shape({});

export const updateProductFormSchema = commonProductFormSchema.shape({});

export const ProductFormSections = {
  DETAILS: "Details",
  PRICING: "Pricing",
  INVENTORY: "Inventory",
  SERIAL_NUMBERS: "Serial Numbers",
} as const;

export enum EditProductTabs {
  DETAILS,
  VARIANTS,
  PRICING,
  INVENTORY,
  SERIAL_NUMBERS,
}
