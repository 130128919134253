import { useCallback, useContext, useMemo } from "react";
import { PricingFormContext } from "./context";
import {
  LockableValue,
  useReactivePricingCalculator,
} from "../../../../utilities/pricing";
import {
  ProductFormValues,
  ProductLocationPricingValues,
  productToPricingFormValues,
} from "../products";
import {
  Location,
  Product,
  TaxGroup,
} from "@towersystems/roam-common/lib/generated-types";

export const usePricingFormProvider = () => {
  const c = useContext(PricingFormContext);

  if (!c) {
    throw new Error(
      "usePricingFormProvider must be used within a PricingFormProvider"
    );
  }

  return c;
};

type UsePricingFormControlledDefaultsProps = {
  product: Product;
  locations: Location[];
  taxGroups: TaxGroup[];
};

const DEFAULT_TAX_RATE = 10;

export const usePricingFormControlledDefaults = ({
  product,
  locations,
  taxGroups,
}: UsePricingFormControlledDefaultsProps): {
  locationInventoryPricing: ProductLocationPricingValues[];
} => {
  const taxGroup = taxGroups.find((taxGroup) => {
    return taxGroup.id === product.taxGroupId;
  });

  const pricingController = useReactivePricingCalculator({
    taxRatePercentage: taxGroup?.salePercentage || DEFAULT_TAX_RATE,
    departmentMarkups: product.department?.markup || [],
    categoryMarkups: product.category?.markup || [],
  });

  const makeDefaultValues = () => {
    const uncontrolledFormValues = productToPricingFormValues(
      product,
      locations
    );

    const controlledDefaults =
      uncontrolledFormValues.locationInventoryPricing.map(
        ({ costPriceEx, retailPrice, ...rest }) => {
          pricingController.reset({
            ...rest,
            costPriceEx: { value: costPriceEx, locked: false },
            retailPrice: { value: retailPrice, locked: false },
            taxRatePercentage: taxGroup?.salePercentage || DEFAULT_TAX_RATE,
            departmentMarkups: product.department?.markup || [],
            categoryMarkups: product.category?.markup || [],
          });

          const valuesOnly = Object.entries(pricingController.getValues()).map(
            ([key, value]) => {
              if (
                value !== undefined &&
                value !== null &&
                isLockableValue(value)
              ) {
                return [key, value.value];
              } else {
                return [key, value];
              }
            }
          );

          const { departmentMarkups, categoryMarkups, ...formValues } =
            Object.fromEntries(valuesOnly);

          return { ...rest, ...formValues };
        }
      );

    return {
      locationInventoryPricing: controlledDefaults,
    };
  };

  const defaultValues = useMemo(
    () => makeDefaultValues(),
    [product, locations, taxGroups]
  );

  return defaultValues;
};

function isLockableValue(value: any): value is LockableValue {
  return value.hasOwnProperty("value") && value.hasOwnProperty("locked");
}
