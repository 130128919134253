import { useMutation } from "@apollo/client";
import { MUTATION_UPDATE_SUPPLIER } from "./constants";
import {
  Supplier,
  UpdateSupplierInput,
} from "@towersystems/roam-common/lib/generated-types";
import React from "react";

export const useMutationUpdateSupplier = () => {
  const [m] = useMutation<{
    updateSupplier: Supplier;
  }>(MUTATION_UPDATE_SUPPLIER);
  const mutation = React.useCallback(
    async (input: UpdateSupplierInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.updateSupplier;
      });
    },
    [m]
  );
  return {
    mutation,
  };
};
