import { Box, IconButton, Typography, useTheme } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefreshIcon from "@mui/icons-material/Refresh";

import { Stack } from "../Stack";

export interface InlineErrorAlertProps {
  message?: string;
  retryAction?: () => void;
}

const DEFAULT_MESSAGE = "An error occurred";

export function InlineErrorAlert({
  message,
  retryAction,
}: InlineErrorAlertProps) {
  const theme = useTheme();

  return (
    <Stack spacing={theme.spacing(2)} sx={{ alignItems: "center" }}>
      <Stack direction="row" spacing={theme.spacing(1)}>
        <ErrorOutlineIcon color="error" />
        <Typography color="error">{message || DEFAULT_MESSAGE}</Typography>
      </Stack>
      {!!retryAction && (
        <Stack
          direction="row"
          sx={{ alignItems: "center" }}
          spacing={theme.spacing(1)}
        >
          <Typography>Try Again</Typography>
          <IconButton aria-label="reload" onClick={retryAction}>
            <RefreshIcon />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
}
