import { Category } from "@towersystems/roam-common/lib/generated-types";
import React from "react";
import { Box, Pagination } from "@mui/material";
import { useScheduler } from "../../../../../../../../utilities/use-scheduler";
import { usePaginatedList } from "../../../../../../../../utilities/paginated-list";
import { CategoryResourceList } from "../../../../../../components/CategoryResourceList";
import { useSearchQueryProvider } from "../../../../../../../../utilities/search/hooks";
import { QUERY_CATEGORY_LIST } from "../../../../../../../../utilities/categories";

export interface AllCategoriesTabViewProps {
  departmentId: string;
}
export const AllCategoriesTabView = ({
  departmentId,
}: AllCategoriesTabViewProps) => {
  const search = useSearchQueryProvider();
  const { schedule } = useScheduler(500);

  const paginatedList = usePaginatedList<Category>({
    query: QUERY_CATEGORY_LIST,
    resultKey: "categories",
    variables: {
      sort: { updatedAt: "DESC" },
      filter: {
        name: { contains: search.query },
        departmentId: {
          eq: departmentId,
        },
      },
    },
  });

  React.useEffect(() => {
    handleOnTermInputChange();
  }, [search.query]);

  const handleOnTermInputChange = React.useCallback(() => {
    schedule(() =>
      paginatedList.setVariables({
        filter: {
          name: { contains: search.query },
          departmentId: {
            eq: departmentId,
          },
        },
      })
    );
  }, [search.query]);

  const handleOnPageChange = React.useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      paginatedList.setPage(value);
    },
    [paginatedList.setPage]
  );

  const items: Category[] = paginatedList.loading
    ? paginatedList.previousResult?.items || []
    : paginatedList.result?.items || [];

  const listMarkup = items ? (
    <CategoryResourceList loading={paginatedList.loading} items={items} />
  ) : undefined;

  const totalPages = paginatedList.maxPage;

  const paginationMarkup = (
    <Box sx={{ p: 2, borderColor: "grey.300" }} borderTop={1}>
      <Pagination
        onChange={handleOnPageChange}
        count={totalPages}
        variant="outlined"
        shape="rounded"
      />
    </Box>
  );

  return (
    <Box>
      {listMarkup}
      {paginationMarkup}
    </Box>
  );
};
