// tslint:disable
// THIS FILE HAS BEEN AUTO-GENERATED BY "graphql-fragments-plugin"
// DO NOT EDIT THIS FILE DIRECTLY

export const AccessTokenSessionNoNestingFragment = `fragment AccessTokenSessionNoNesting on AccessTokenSession {
  createdAt
  deletedAt
  expires
  id
  invalidated
  name
  token
  updatedAt
  userId
}
`

export const AnalyticProductMetaDataItemNoNestingFragment = `fragment AnalyticProductMetaDataItemNoNesting on AnalyticProductMetaDataItem {
  id
  imageUrl
  name
  sku
  value
}
`

export const AuthenticationMethodNoNestingFragment = `fragment AuthenticationMethodNoNesting on AuthenticationMethod {
  createdAt
  id
  strategy
  updatedAt
}
`

export const AuthenticationResultNoNestingFragment = `fragment AuthenticationResultNoNesting on AuthenticationResult {
  talinkId
  token
  userId
}
`

export const BackgroundRemoverInsufficientCreditsErrorNoNestingFragment = `fragment BackgroundRemoverInsufficientCreditsErrorNoNesting on BackgroundRemoverInsufficientCreditsError {
  errorCode
  message
}
`

export const BackgroundRemoverNotConfiguredErrorNoNestingFragment = `fragment BackgroundRemoverNotConfiguredErrorNoNesting on BackgroundRemoverNotConfiguredError {
  errorCode
  message
}
`

export const CashMovementNoNestingFragment = `fragment CashMovementNoNesting on CashMovement {
  amount
  createdAt
  date
  id
  note
  openTerminalClosureId
  type
  updatedAt
  userId
  userIdentifier
}
`

export const CategoryNoNestingFragment = `fragment CategoryNoNesting on Category {
  active
  allowDiscount
  bannerImageUrl
  catCode
  createdAt
  deletedAt
  departmentId
  description
  id
  name
  numberOfProducts
  preferredTaxGroupId
  updatedAt
}
`

export const ConfigArgNoNestingFragment = `fragment ConfigArgNoNesting on ConfigArg {
  name
  value
}
`

export const ConfigArgDefinitionNoNestingFragment = `fragment ConfigArgDefinitionNoNesting on ConfigArgDefinition {
  defaultValue
  description
  label
  list
  name
  required
  type
}
`

export const ConfigurableOperationNoNestingFragment = `fragment ConfigurableOperationNoNesting on ConfigurableOperation {
  code
}
`

export const ConfigurableOperationDefinitionNoNestingFragment = `fragment ConfigurableOperationDefinitionNoNesting on ConfigurableOperationDefinition {
  code
  description
}
`

export const CurrencyTypeNoNestingFragment = `fragment CurrencyTypeNoNesting on CurrencyType {
  code
  symbol
}
`

export const CurrentUserV2NoNestingFragment = `fragment CurrentUserV2NoNesting on CurrentUserV2 {
  id
  identifier
  permissions
  restrictedTerminalIds
  token
}
`

export const CustomerNoNestingFragment = `fragment CustomerNoNesting on Customer {
  amountSpend
  createdAt
  deletedAt
  emailAddress
  firstName
  gender
  id
  invoicedAmount
  isRetailerCusAccount
  lastName
  loyaltyPoints
  mobileNumber
  numberOfOrders
  phoneNumber
  retailerId
  updatedAt
}
`

export const CustomerAddressNoNestingFragment = `fragment CustomerAddressNoNesting on CustomerAddress {
  address1
  address2
  city
  country
  createdAt
  deletedAt
  id
  isPrimary
  postcode
  state
  updatedAt
}
`

export const DeletedNoNestingFragment = `fragment DeletedNoNesting on Deleted {
  result
}
`

export const DeletionResponseNoNestingFragment = `fragment DeletionResponseNoNesting on DeletionResponse {
  reason
  result
}
`

export const DepartmentNoNestingFragment = `fragment DepartmentNoNesting on Department {
  active
  allowDiscount
  bannerImageUrl
  createdAt
  deletedAt
  deptCode
  description
  id
  name
  numberOfCategories
  numberOfProducts
  preferredTaxGroupId
  updatedAt
}
`

export const DocketFilterNoNestingFragment = `fragment DocketFilterNoNesting on DocketFilter {
  type
  values
}
`

export const DocketTemplateNoNestingFragment = `fragment DocketTemplateNoNesting on DocketTemplate {
  automaticallyPrint
  createdAt
  defaultTemplateTypeId
  deletedAt
  enabled
  id
  name
  terminalIds
  updatedAt
}
`

export const DocketTemplateTypeNoNestingFragment = `fragment DocketTemplateTypeNoNesting on DocketTemplateType {
  createdAt
  css
  deletedAt
  html
  id
  updatedAt
  version
}
`

export const DownloadReceiptPdfBase64StringResponseNoNestingFragment = `fragment DownloadReceiptPdfBase64StringResponseNoNesting on DownloadReceiptPdfBase64StringResponse {
  data
  fileName
}
`

export const DownloadStockCountCsvBase64StringResponseNoNestingFragment = `fragment DownloadStockCountCsvBase64StringResponseNoNesting on DownloadStockCountCsvBase64StringResponse {
  data
  fileName
}
`

export const DownloadStocktakeCsvBase64StringResponseNoNestingFragment = `fragment DownloadStocktakeCsvBase64StringResponseNoNesting on DownloadStocktakeCsvBase64StringResponse {
  data
  fileName
}
`

export const EmailAddressConflictErrorNoNestingFragment = `fragment EmailAddressConflictErrorNoNesting on EmailAddressConflictError {
  errorCode
  message
}
`

export const ExportStockCountItemResultNoNestingFragment = `fragment ExportStockCountItemResultNoNesting on ExportStockCountItemResult {
  success
}
`

export const FeaturesListNoNestingFragment = `fragment FeaturesListNoNesting on FeaturesList {
  pointOfSale
  selfCheckout
  stocktake
  utilities
}
`

export const FilterValueMetaNoNestingFragment = `fragment FilterValueMetaNoNesting on FilterValueMeta {
  label
  type
  value
}
`

export const GlobalSettingsNoNestingFragment = `fragment GlobalSettingsNoNesting on GlobalSettings {
  abn
  address1
  address2
  automaticBackgroundRemoval
  automaticOpenTerminalClosure
  automaticallyEmailStockTake
  automaticallyEmailTerminalClosureSummary
  bgRemovalStrategy
  businessName
  cashManagedPaymentMethodId
  countryCode
  defaultLaybyDepositPercentage
  defaultTerminalClosureFloat
  email
  laybyEnabled
  minLaybySpend
  phone
  postcode
  productSurchargeId
  retailerInBoundEnabled
  showExpectedClosureCounts
  stocktakeSendToAddress
  talinkId
  taxPercentage
  terminalClosureSendToAddress
  timezone
  website
}
`

export const InsufficientBarcodeLookupCreditsErrorNoNestingFragment = `fragment InsufficientBarcodeLookupCreditsErrorNoNesting on InsufficientBarcodeLookupCreditsError {
  errorCode
  message
}
`

export const InsufficientStockErrorNoNestingFragment = `fragment InsufficientStockErrorNoNesting on InsufficientStockError {
  errorCode
  message
  quantityAvailable
}
`

export const InvalidBarcodeLookupCredentialsErrorNoNestingFragment = `fragment InvalidBarcodeLookupCredentialsErrorNoNesting on InvalidBarcodeLookupCredentialsError {
  errorCode
  message
}
`

export const InvalidCredentialsErrorNoNestingFragment = `fragment InvalidCredentialsErrorNoNesting on InvalidCredentialsError {
  authenticationError
  errorCode
  message
}
`

export const InvalidLinklyCredentialsErrorNoNestingFragment = `fragment InvalidLinklyCredentialsErrorNoNesting on InvalidLinklyCredentialsError {
  errorCode
  message
}
`

export const InvalidMfaCodeErrorNoNestingFragment = `fragment InvalidMfaCodeErrorNoNesting on InvalidMfaCodeError {
  errorCode
  message
}
`

export const InvalidMfaIdentifierErrorNoNestingFragment = `fragment InvalidMfaIdentifierErrorNoNesting on InvalidMfaIdentifierError {
  errorCode
  message
}
`

export const InvalidMfaStrategyErrorNoNestingFragment = `fragment InvalidMfaStrategyErrorNoNesting on InvalidMfaStrategyError {
  errorCode
  message
}
`

export const InventoryItemNoNestingFragment = `fragment InventoryItemNoNesting on InventoryItem {
  createdAt
  deletedAt
  id
  productId
  tracked
  updatedAt
}
`

export const InventoryLevelNoNestingFragment = `fragment InventoryLevelNoNesting on InventoryLevel {
  createdAt
  deletedAt
  id
  inventoryItemId
  locationId
  onHand
  unitsPerPack
  updatedAt
}
`

export const InventoryLevelMovementNoNestingFragment = `fragment InventoryLevelMovementNoNesting on InventoryLevelMovement {
  adjustment
  createdAt
  deletedAt
  id
  inventoryLevelId
  quantity
  updatedAt
  userId
}
`

export const InventoryMovementNoNestingFragment = `fragment InventoryMovementNoNesting on InventoryMovement {
  cancelledAt
  completedAt
  createdAt
  deletedAt
  dispatchedAt
  dueAt
  id
  inventoryMovementDate
  isFullStocktake
  locationId
  name
  receivedAt
  referenceNo
  sentAt
  status
  targetLocationId
  updatedAt
  userId
}
`

export const InventoryMovementFilterNoNestingFragment = `fragment InventoryMovementFilterNoNesting on InventoryMovementFilter {
  type
  value
}
`

export const InventoryMovementItemNoNestingFragment = `fragment InventoryMovementItemNoNesting on InventoryMovementItem {
  cost
  counted
  createdAt
  deletedAt
  expected
  id
  initialExpected
  inventoryMovementId
  manual
  productId
  status
  stockCode
  updatedAt
}
`

export const InventoryMovementItemSummaryTotalsNoNestingFragment = `fragment InventoryMovementItemSummaryTotalsNoNesting on InventoryMovementItemSummaryTotals {
  counted
  inventoryMovementItemId
  price
  productId
  productName
  received
  stockNumber
  totalCost
}
`

export const InventoryMovementSummaryTotalNoNestingFragment = `fragment InventoryMovementSummaryTotalNoNesting on InventoryMovementSummaryTotal {
  cost
  counted
  received
}
`

export const InventoryMovementTotalsNoNestingFragment = `fragment InventoryMovementTotalsNoNesting on InventoryMovementTotals {
  totalCostGain
  totalCostLoss
  totalCountGain
  totalCountLoss
}
`

export const InvoiceArrivalNoNestingFragment = `fragment InvoiceArrivalNoNesting on InvoiceArrival {
  arrivalDate
  costPriceIncGST
  createdAt
  deletedAt
  dueDate
  formattedGSTCredit
  freightCost
  gstCredit
  id
  invoiceDate
  invoiceNumber
  locationId
  orderNumber
  status
  supplierId
  totalCost
  totalCostExGST
  totalCostIncGST
  totalQuantity
  updatedAt
}
`

export const InvoiceArrivalItemNoNestingFragment = `fragment InvoiceArrivalItemNoNesting on InvoiceArrivalItem {
  barcode
  costPrice
  createdAt
  deletedAt
  formattedGSTCredit
  gstCredit
  id
  invoiceArrivalId
  productId
  quantity
  received
  retailPrice
  serialNumbers
  totalCost
  totalCostExGST
  totalCostIncGST
  tradePrice
  updatedAt
  webPrice
}
`

export const LayoutNoNestingFragment = `fragment LayoutNoNesting on Layout {
  createdAt
  deletedAt
  id
  terminalId
  updatedAt
}
`

export const LayoutNodeNoNestingFragment = `fragment LayoutNodeNoNesting on LayoutNode {
  action
  color
  createdAt
  deletedAt
  id
  label
  layoutId
  position
  productId
  showImage
  type
  updatedAt
}
`

export const LayoutNodeFilterNoNestingFragment = `fragment LayoutNodeFilterNoNesting on LayoutNodeFilter {
  type
  values
}
`

export const LayoutNodeLinkNoNestingFragment = `fragment LayoutNodeLinkNoNesting on LayoutNodeLink {
  newTab
  url
}
`

export const LinklyConfigurationNoNestingFragment = `fragment LinklyConfigurationNoNesting on LinklyConfiguration {
  createdAt
  deletedAt
  id
  paymentMethodId
  receiptAutoPrint
  updatedAt
}
`

export const LinklyErrorNoNestingFragment = `fragment LinklyErrorNoNesting on LinklyError {
  errorCode
  message
}
`

export const LinklyPairedPinpadNoNestingFragment = `fragment LinklyPairedPinpadNoNesting on LinklyPairedPinpad {
  createdAt
  deletedAt
  id
  linklyConfigurationId
  name
  secret
  updatedAt
}
`

export const LocationNoNestingFragment = `fragment LocationNoNesting on Location {
  businessName
  createdAt
  enabled
  id
  name
  retailerLocationKey
  updatedAt
}
`

export const MarkupNoNestingFragment = `fragment MarkupNoNesting on Markup {
  markupPercentage
  markupStyle
  markupType
}
`

export const MfaAlreadySetupErrorNoNestingFragment = `fragment MfaAlreadySetupErrorNoNesting on MfaAlreadySetupError {
  errorCode
  message
}
`

export const MfaMethodNoNestingFragment = `fragment MfaMethodNoNesting on MfaMethod {
  createdAt
  id
  identifier
  strategy
  updatedAt
  verified
}
`

export const MfaRequiredErrorNoNestingFragment = `fragment MfaRequiredErrorNoNesting on MfaRequiredError {
  errorCode
  message
  strategies
}
`

export const MimeTypeErrorNoNestingFragment = `fragment MimeTypeErrorNoNesting on MimeTypeError {
  errorCode
  fileName
  message
  mimeType
}
`

export const MissingPasswordErrorNoNestingFragment = `fragment MissingPasswordErrorNoNesting on MissingPasswordError {
  errorCode
  message
}
`

export const MovementItemProductSummaryNoNestingFragment = `fragment MovementItemProductSummaryNoNesting on MovementItemProductSummary {
  onHand
  productId
  productName
  stockCode
  stockNumber
}
`

export const NativeAuthStrategyErrorNoNestingFragment = `fragment NativeAuthStrategyErrorNoNesting on NativeAuthStrategyError {
  errorCode
  message
}
`

export const NegativeQuantityErrorNoNestingFragment = `fragment NegativeQuantityErrorNoNesting on NegativeQuantityError {
  errorCode
  message
}
`

export const NotVerifiedErrorNoNestingFragment = `fragment NotVerifiedErrorNoNesting on NotVerifiedError {
  errorCode
  message
}
`

export const PaginatedListMetaNoNestingFragment = `fragment PaginatedListMetaNoNesting on PaginatedListMeta {
  currentPage
  itemCount
  totalItems
}
`

export const PasswordAlreadySetErrorNoNestingFragment = `fragment PasswordAlreadySetErrorNoNesting on PasswordAlreadySetError {
  errorCode
  message
}
`

export const PasswordValidationErrorNoNestingFragment = `fragment PasswordValidationErrorNoNesting on PasswordValidationError {
  errorCode
  message
  validationErrorMessage
}
`

export const PayTypeNoNestingFragment = `fragment PayTypeNoNesting on PayType {
  available
  channels
  configurationUrl
  createdAt
  defaultGatewayUrl
  deletedAt
  description
  gateway
  gatewayEditable
  id
  internal
  logo
  name
  onlineOnly
  platforms
  restricted
  subtext
  updatedAt
  version
}
`

export const PayTypeGatewayConfigNoNestingFragment = `fragment PayTypeGatewayConfigNoNesting on PayTypeGatewayConfig {
  url
}
`

export const PayTypeRoundingConfigNoNestingFragment = `fragment PayTypeRoundingConfigNoNesting on PayTypeRoundingConfig {
  rounding
}
`

export const PaymentNoNestingFragment = `fragment PaymentNoNesting on Payment {
  amount
  errorMessage
  id
  label
  metadata
  name
  paymentDate
  paymentMethodId
  paymentTypeId
  state
  transactionId
}
`

export const PaymentMethodNoNestingFragment = `fragment PaymentMethodNoNesting on PaymentMethod {
  createdAt
  deletedAt
  enabled
  id
  name
  payTypeId
  retailerPayCode
  retailerPayTypeId
  updatedAt
}
`

export const ProductNoNestingFragment = `fragment ProductNoNesting on Product {
  UOM
  active
  adjustable
  categoryId
  createdAt
  deletedAt
  departmentId
  description
  discountable
  displayName
  hasVariants
  id
  internal
  loyaltyPointEarnValue
  loyaltyPointRedeemValue
  name
  price
  priceExcludingTax
  priceLookup
  productOptionGroupTemplateId
  serialNumbersArr
  serialNumbersSale
  stockCode
  stockNumber
  supplierId
  supplierStockCode
  tags
  taxGroupId
  taxable
  totalInventory
  totalVariants
  updatedAt
  variantParentId
  webDescription
  webPrice
  weight
}
`

export const ProductFoundResultNoNestingFragment = `fragment ProductFoundResultNoNesting on ProductFoundResult {
  result
}
`

export const ProductIdResultNoNestingFragment = `fragment ProductIdResultNoNesting on ProductIdResult {
  productId
}
`

export const ProductImageNoNestingFragment = `fragment ProductImageNoNesting on ProductImage {
  createdAt
  deletedAt
  height
  id
  mineType
  name
  path
  position
  productId
  updatedAt
  width
}
`

export const ProductInfoNoNestingFragment = `fragment ProductInfoNoNesting on ProductInfo {
  description
  images
  name
  weight
}
`

export const ProductLocationInventoryPricingNoNestingFragment = `fragment ProductLocationInventoryPricingNoNesting on ProductLocationInventoryPricing {
  costPrice
  costPriceExclTax
  id
  locationId
  productId
  retailPrice
  rrPrice
  serialNumbers
  stockOnHand
  taxGroupId
  taxRatePercent
  tradePrice
}
`

export const ProductNotFoundResultNoNestingFragment = `fragment ProductNotFoundResultNoNesting on ProductNotFoundResult {
  result
}
`

export const ProductOptionNoNestingFragment = `fragment ProductOptionNoNesting on ProductOption {
  createdAt
  id
  name
  position
  updatedAt
  values
}
`

export const ProductOptionGroupTemplateNoNestingFragment = `fragment ProductOptionGroupTemplateNoNesting on ProductOptionGroupTemplate {
  createdAt
  id
  identifier
  name
  updatedAt
}
`

export const ReceiptNoNestingFragment = `fragment ReceiptNoNesting on Receipt {
  createdAt
  defaultTemplateType
  deletedAt
  enabled
  id
  name
  updatedAt
}
`

export const ReceiptSettingsNoNestingFragment = `fragment ReceiptSettingsNoNesting on ReceiptSettings {
  displayCustomerDetails
  footer
  header
  logoURL
  name
}
`

export const ReceiptTemplateTypeNoNestingFragment = `fragment ReceiptTemplateTypeNoNesting on ReceiptTemplateType {
  createdAt
  css
  deletedAt
  html
  id
  updatedAt
  version
}
`

export const RemovalAiDetailsNoNestingFragment = `fragment RemovalAiDetailsNoNesting on RemovalAiDetails {
  apiKey
}
`

export const RemovalBgDetailsNoNestingFragment = `fragment RemovalBgDetailsNoNesting on RemovalBgDetails {
  apiKey
}
`

export const RemoveBackgroundBase64ResultNoNestingFragment = `fragment RemoveBackgroundBase64ResultNoNesting on RemoveBackgroundBase64Result {
  base64
}
`

export const RetailerExportCustomerNoNestingFragment = `fragment RetailerExportCustomerNoNesting on RetailerExportCustomer {
  Email
  FirstName
  Phone
  Surname
}
`

export const RetailerExportPaymentNoNestingFragment = `fragment RetailerExportPaymentNoNesting on RetailerExportPayment {
  Amount
  PayCode
  Tendered
}
`

export const RetailerExportSaleNoNestingFragment = `fragment RetailerExportSaleNoNesting on RetailerExportSale {
  CustID
  FreightCharge
  FreightTax
  LocationKey
  OrderNumber
  SaleAmount
  SaleCust
  SaleDate
  SaleDeposit
  SaleDiscount
  SaleId
  SaleRound
  SaleStaff
  SaleTax
  SaleTerm
  SaleTime
  SaleType
}
`

export const RetailerExportSaleItemNoNestingFragment = `fragment RetailerExportSaleItemNoNesting on RetailerExportSaleItem {
  SerialNumber
  TotalPointsEarned
  TotalPointsUsed
  TxnDiscountType
  TxnExtendedAmount
  TxnExtendedDiscount
  TxnExtendedTax
  TxnItem
  TxnQty
  TxnStock
  TxnTotal
  TxnTotalCost
  TxnUnitAmount
  TxnUnitCost
  TxnUnitDiscount
  TxnUnitTax
}
`

export const RetailerPayTypeNoNestingFragment = `fragment RetailerPayTypeNoNesting on RetailerPayType {
  createdAt
  deletedAt
  id
  payCode
  payDescription
  payOver
  payType
  updatedAt
}
`

export const RoleNoNestingFragment = `fragment RoleNoNesting on Role {
  code
  createdAt
  id
  permissions
  updatedAt
}
`

export const SaleNoNestingFragment = `fragment SaleNoNesting on Sale {
  active
  balance
  code
  completedAt
  createdAt
  customerId
  deletedAt
  id
  invoiceNumber
  invoiceSequence
  isReturn
  locationId
  metadata
  note
  purchaseOrderNumber
  quoteAccepted
  receiptConfirmationUrl
  receiptConfirmed
  receiptId
  returnForId
  saleDate
  saleType
  source
  staffId
  state
  terminalId
  totalAdjustmentPercentSaving
  totalAdjustments
  totalCost
  totalPrice
  totalTax
  updatedAt
}
`

export const SaleAddressNoNestingFragment = `fragment SaleAddressNoNesting on SaleAddress {
  city
  company
  country
  countryCode
  fullName
  phoneNumber
  postCode
  state
  street1
  street2
}
`

export const SaleAdjustmentNoNestingFragment = `fragment SaleAdjustmentNoNesting on SaleAdjustment {
  amount
  createdAt
  id
  metadata
  type
  unitLoyaltyPointsUsed
  updatedAt
}
`

export const SaleHistoryEntryNoNestingFragment = `fragment SaleHistoryEntryNoNesting on SaleHistoryEntry {
  createdAt
  deletedAt
  id
  type
  updatedAt
  userId
}
`

export const SaleLineItemNoNestingFragment = `fragment SaleLineItemNoNesting on SaleLineItem {
  basePrice
  basePriceInclTax
  cost
  createdAt
  customerNote
  deletedAt
  id
  loyaltyPointEarned
  metadata
  name
  price
  priceInclTax
  productId
  quantity
  saleId
  serialNumber
  stockCode
  stockNumber
  tax
  taxRatePercent
  totalAdjustments
  totalBasePrice
  totalBasePriceInclTax
  totalCost
  totalPrice
  totalPriceInclusiveTax
  totalTax
  updatedAt
}
`

export const SaleLineItemEntryDataNoNestingFragment = `fragment SaleLineItemEntryDataNoNesting on SaleLineItemEntryData {
  basePrice
  basePriceInclTax
  cost
  loyaltyPointEarned
  metadata
  price
  priceInclTax
  productName
  quantity
  serialNumber
  stockCode
  stockNumber
  tax
  taxRatePercent
  totalAdjustments
  totalBasePrice
  totalBasePriceInclTax
  totalCost
  totalPrice
  totalPriceInclusiveTax
  totalTax
}
`

export const SaleModifiedCustomerAddedEntryDataNoNestingFragment = `fragment SaleModifiedCustomerAddedEntryDataNoNesting on SaleModifiedCustomerAddedEntryData {
  customerId
  emailAddress
}
`

export const SaleModifiedEntryDataNoNestingFragment = `fragment SaleModifiedEntryDataNoNesting on SaleModifiedEntryData {
  input
  oldEntity
}
`

export const SaleNoteEntryDataNoNestingFragment = `fragment SaleNoteEntryDataNoNesting on SaleNoteEntryData {
  note
}
`

export const SaleStateTransitionErrorNoNestingFragment = `fragment SaleStateTransitionErrorNoNesting on SaleStateTransitionError {
  errorCode
  fromState
  message
  toState
  transitionError
}
`

export const SaleStatusTransitionDataNoNestingFragment = `fragment SaleStatusTransitionDataNoNesting on SaleStatusTransitionData {
  from
  to
}
`

export const SaleSummaryProductResultNoNestingFragment = `fragment SaleSummaryProductResultNoNesting on SaleSummaryProductResult {
  completedDate
  count
  productId
  saleId
  state
  total
}
`

export const SearchResultItemNoNestingFragment = `fragment SearchResultItemNoNesting on SearchResultItem {
  id
  type
}
`

export const SelectedOptionNoNestingFragment = `fragment SelectedOptionNoNesting on SelectedOption {
  name
  value
}
`

export const SelfCheckoutLocationSettingsNoNestingFragment = `fragment SelfCheckoutLocationSettingsNoNesting on SelfCheckoutLocationSettings {
  checkoutSuccessHeading
  enabled
  locationId
  offlineCheckoutEnabled
  onlineCheckoutEnabled
  payAtCounterHeading
  payAtCounterSubheading
  terminalId
}
`

export const SelfCheckoutSettingsNoNestingFragment = `fragment SelfCheckoutSettingsNoNesting on SelfCheckoutSettings {
  enabled
  logoURL
  paymentMethodId
}
`

export const SerialNumberNoNestingFragment = `fragment SerialNumberNoNesting on SerialNumber {
  createdAt
  deletedAt
  id
  productLocationInventoryPricingId
  updatedAt
  value
}
`

export const StaffNoNestingFragment = `fragment StaffNoNesting on Staff {
  createdAt
  deletedAt
  emailAddress
  enabled
  fullName
  hasUserAccount
  id
  initials
  terminalIds
  updatedAt
  userId
  username
}
`

export const StockCountNoNestingFragment = `fragment StockCountNoNesting on StockCount {
  createdAt
  deletedAt
  id
  locationId
  name
  retailerStocktakeId
  source
  status
  updatedAt
}
`

export const StockCountItemNoNestingFragment = `fragment StockCountItemNoNesting on StockCountItem {
  counted
  countedAt
  createdAt
  deletedAt
  id
  lineId
  status
  updatedAt
}
`

export const StockCountLineNoNestingFragment = `fragment StockCountLineNoNesting on StockCountLine {
  barcode
  createdAt
  deletedAt
  expected
  id
  initialExpected
  productId
  stockCountId
  updatedAt
}
`

export const StockNumberNotFoundErrorNoNestingFragment = `fragment StockNumberNotFoundErrorNoNesting on StockNumberNotFoundError {
  errorCode
  message
}
`

export const StockOrderNoNestingFragment = `fragment StockOrderNoNesting on StockOrder {
  completedAt
  createdAt
  deletedAt
  formattedOrderNumber
  id
  locationId
  orderCreatedDate
  orderNumber
  status
  totalQuantity
  updatedAt
}
`

export const StockOrderItemNoNestingFragment = `fragment StockOrderItemNoNesting on StockOrderItem {
  barcode
  createdAt
  deletedAt
  id
  productId
  quantity
  stockOrderId
  updatedAt
}
`

export const StocktakeItemNoNestingFragment = `fragment StocktakeItemNoNesting on StocktakeItem {
  cost
  counted
  createdAt
  deletedAt
  expected
  id
  initialExpected
  manual
  product
  status
  stockCode
  stocktakeId
  updatedAt
}
`

export const SuccessNoNestingFragment = `fragment SuccessNoNesting on Success {
  success
}
`

export const SupplierNoNestingFragment = `fragment SupplierNoNesting on Supplier {
  active
  bannerImageUrl
  createdAt
  deletedAt
  description
  id
  name
  suppCode
  updatedAt
}
`

export const TaxGroupNoNestingFragment = `fragment TaxGroupNoNesting on TaxGroup {
  active
  arrivePercentage
  createdAt
  deletedAt
  id
  name
  salePercentage
  shortName
  taxGroupID
  updatedAt
}
`

export const TenantNoNestingFragment = `fragment TenantNoNesting on Tenant {
  identifier
}
`

export const TerminalNoNestingFragment = `fragment TerminalNoNesting on Terminal {
  createdAt
  defaultReceiptTemplateId
  deletedAt
  enabled
  id
  invoiceSequence
  name
  openTerminalClosureId
  paymentMethodIds
  printReceiptOnCheckout
  salePrefix
  saleSufix
  terminalCloseTime
  terminalOpenTime
  updatedAt
}
`

export const TerminalClosureNoNestingFragment = `fragment TerminalClosureNoNesting on TerminalClosure {
  createdAt
  deletedAt
  estimatedCashBalance
  id
  isClosed
  terminalCloseDate
  terminalId
  terminalName
  terminalOpenDate
  updatedAt
}
`

export const TerminalClosurePaymentMethodSummaryNoNestingFragment = `fragment TerminalClosurePaymentMethodSummaryNoNesting on TerminalClosurePaymentMethodSummary {
  difference
  expected
  payTypeId
  paymentMethodId
  paymentTypeName
  total
}
`

export const TerminalClosurePaymentTotalNoNestingFragment = `fragment TerminalClosurePaymentTotalNoNesting on TerminalClosurePaymentTotal {
  counted
  paymentMethodId
}
`

export const TerminalClosurePaymentsSummaryNoNestingFragment = `fragment TerminalClosurePaymentsSummaryNoNesting on TerminalClosurePaymentsSummary {
  terminalClosureId
  terminalId
  terminalOpenTime
}
`

export const TerminalClosureSalesSummaryNoNestingFragment = `fragment TerminalClosureSalesSummaryNoNesting on TerminalClosureSalesSummary {
  totalDiscounts
  totalInvoices
  totalLaybys
  totalPayments
  totalRefunds
  totalSales
  totalTaxes
}
`

export const UserNoNestingFragment = `fragment UserNoNesting on User {
  displayName
  emailAddress
  id
  mobileNumber
  numberAvailableTerminals
  stripeAccountId
  stripeOnboardingComplete
  userAccountType
  username
}
`

export const UserV2NoNestingFragment = `fragment UserV2NoNesting on UserV2 {
  createdAt
  enabled2fa
  id
  identifier
  lastLogin
  updatedAt
  verified
}
`

export const VendorNoNestingFragment = `fragment VendorNoNesting on Vendor {
  active
  id
  numberAvailableTerminals
  stripeOnboardingComplete
  talinkId
  talinkUsername
  userAccountType
}
`


export const AccessTokenSessionFragment = `fragment AccessTokenSession on AccessTokenSession {
  createdAt
  deletedAt
  expires
  id
  invalidated
  name
  token
  updatedAt
  userId
}

`

export const AnalyticProductMetaDataItemFragment = `fragment AnalyticProductMetaDataItem on AnalyticProductMetaDataItem {
  id
  imageUrl
  name
  sku
  value
}

`

export const AuthenticationMethodFragment = `fragment AuthenticationMethod on AuthenticationMethod {
  createdAt
  id
  strategy
  updatedAt
}

`

export const AuthenticationResultFragment = `fragment AuthenticationResult on AuthenticationResult {
  talinkId
  token
  userId
}

`

export const BackgroundRemoverInsufficientCreditsErrorFragment = `fragment BackgroundRemoverInsufficientCreditsError on BackgroundRemoverInsufficientCreditsError {
  errorCode
  message
}

`

export const BackgroundRemoverNotConfiguredErrorFragment = `fragment BackgroundRemoverNotConfiguredError on BackgroundRemoverNotConfiguredError {
  errorCode
  message
}

`

export const CashMovementFragment = `fragment CashMovement on CashMovement {
  amount
  createdAt
  date
  id
  note
  openTerminalClosureId
  type
  updatedAt
  userId
  userIdentifier
}

`

export const CategoryFragment = `fragment Category on Category {
  active
  allowDiscount
  bannerImageUrl
  catCode
  createdAt
  deletedAt
  departmentId
  description
  id
  markup {
    ...MarkupNoNesting
  }
  name
  numberOfProducts
  preferredTaxGroupId
  updatedAt
}

`

export const CategoryListFragment = `fragment CategoryList on CategoryList {
  items {
    ...CategoryNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const ConfigArgFragment = `fragment ConfigArg on ConfigArg {
  name
  value
}

`

export const ConfigArgDefinitionFragment = `fragment ConfigArgDefinition on ConfigArgDefinition {
  defaultValue
  description
  label
  list
  name
  required
  type
}

`

export const ConfigurableOperationFragment = `fragment ConfigurableOperation on ConfigurableOperation {
  args {
    ...ConfigArgNoNesting
  }
  code
}

`

export const ConfigurableOperationDefinitionFragment = `fragment ConfigurableOperationDefinition on ConfigurableOperationDefinition {
  args {
    ...ConfigArgDefinitionNoNesting
  }
  code
  description
}

`

export const CurrencyTypeFragment = `fragment CurrencyType on CurrencyType {
  code
  symbol
}

`

export const CurrentUserAndTenantFragment = `fragment CurrentUserAndTenant on CurrentUserAndTenant {
  currentUser {
    ...CurrentUserV2NoNesting
  }
  tenant {
    ...TenantNoNesting
  }
}

`

export const CurrentUserV2Fragment = `fragment CurrentUserV2 on CurrentUserV2 {
  id
  identifier
  permissions
  restrictedTerminalIds
  token
}

`

export const CustomerFragment = `fragment Customer on Customer {
  addresses {
    ...CustomerAddressNoNesting
  }
  amountSpend
  createdAt
  deletedAt
  emailAddress
  firstName
  gender
  id
  invoicedAmount
  isRetailerCusAccount
  lastName
  loyaltyPoints
  mobileNumber
  numberOfOrders
  phoneNumber
  retailerId
  updatedAt
}

`

export const CustomerAddressFragment = `fragment CustomerAddress on CustomerAddress {
  address1
  address2
  city
  country
  createdAt
  deletedAt
  id
  isPrimary
  postcode
  state
  updatedAt
}

`

export const CustomerListFragment = `fragment CustomerList on CustomerList {
  items {
    ...CustomerNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const DeletedFragment = `fragment Deleted on Deleted {
  result
}

`

export const DeletionResponseFragment = `fragment DeletionResponse on DeletionResponse {
  reason
  result
}

`

export const DepartmentFragment = `fragment Department on Department {
  active
  allowDiscount
  bannerImageUrl
  createdAt
  deletedAt
  deptCode
  description
  id
  markup {
    ...MarkupNoNesting
  }
  name
  numberOfCategories
  numberOfProducts
  preferredTaxGroupId
  updatedAt
}

`

export const DepartmentListFragment = `fragment DepartmentList on DepartmentList {
  items {
    ...DepartmentNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const DocketFilterFragment = `fragment DocketFilter on DocketFilter {
  type
  values
}

`

export const DocketTemplateFragment = `fragment DocketTemplate on DocketTemplate {
  automaticallyPrint
  createdAt
  defaultTemplateTypeId
  deletedAt
  enabled
  filters {
    ...DocketFilterNoNesting
  }
  id
  name
  terminalIds
  updatedAt
}

`

export const DocketTemplateListFragment = `fragment DocketTemplateList on DocketTemplateList {
  items {
    ...DocketTemplateNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const DocketTemplateTypeFragment = `fragment DocketTemplateType on DocketTemplateType {
  createdAt
  css
  deletedAt
  html
  id
  updatedAt
  version
}

`

export const DownloadReceiptPdfBase64StringResponseFragment = `fragment DownloadReceiptPdfBase64StringResponse on DownloadReceiptPdfBase64StringResponse {
  data
  fileName
}

`

export const DownloadStockCountCsvBase64StringResponseFragment = `fragment DownloadStockCountCsvBase64StringResponse on DownloadStockCountCsvBase64StringResponse {
  data
  fileName
}

`

export const DownloadStocktakeCsvBase64StringResponseFragment = `fragment DownloadStocktakeCsvBase64StringResponse on DownloadStocktakeCsvBase64StringResponse {
  data
  fileName
}

`

export const EmailAddressConflictErrorFragment = `fragment EmailAddressConflictError on EmailAddressConflictError {
  errorCode
  message
}

`

export const ExportStockCountItemResultFragment = `fragment ExportStockCountItemResult on ExportStockCountItemResult {
  stockCountItem {
    ...StockCountItemNoNesting
  }
  success
}

`

export const FeaturesListFragment = `fragment FeaturesList on FeaturesList {
  pointOfSale
  selfCheckout
  stocktake
  utilities
}

`

export const FilterValueMetaFragment = `fragment FilterValueMeta on FilterValueMeta {
  label
  type
  value
}

`

export const GlobalSettingsFragment = `fragment GlobalSettings on GlobalSettings {
  abn
  address1
  address2
  automaticBackgroundRemoval
  automaticOpenTerminalClosure
  automaticallyEmailStockTake
  automaticallyEmailTerminalClosureSummary
  bgRemovalStrategy
  businessName
  cashManagedPaymentMethodId
  countryCode
  currency {
    ...CurrencyTypeNoNesting
  }
  defaultLaybyDepositPercentage
  defaultTerminalClosureFloat
  email
  laybyEnabled
  minLaybySpend
  phone
  postcode
  productSurchargeId
  removalAiDetails {
    ...RemovalAiDetailsNoNesting
  }
  removalBgDetails {
    ...RemovalBgDetailsNoNesting
  }
  retailerInBoundEnabled
  showExpectedClosureCounts
  stocktakeSendToAddress
  talinkId
  taxPercentage
  terminalClosureSendToAddress
  timezone
  website
}

`

export const InsufficientBarcodeLookupCreditsErrorFragment = `fragment InsufficientBarcodeLookupCreditsError on InsufficientBarcodeLookupCreditsError {
  errorCode
  message
}

`

export const InsufficientStockErrorFragment = `fragment InsufficientStockError on InsufficientStockError {
  errorCode
  message
  quantityAvailable
  sale {
    ...SaleNoNesting
  }
}

`

export const InvalidBarcodeLookupCredentialsErrorFragment = `fragment InvalidBarcodeLookupCredentialsError on InvalidBarcodeLookupCredentialsError {
  errorCode
  message
}

`

export const InvalidCredentialsErrorFragment = `fragment InvalidCredentialsError on InvalidCredentialsError {
  authenticationError
  errorCode
  message
}

`

export const InvalidLinklyCredentialsErrorFragment = `fragment InvalidLinklyCredentialsError on InvalidLinklyCredentialsError {
  errorCode
  message
}

`

export const InvalidMfaCodeErrorFragment = `fragment InvalidMfaCodeError on InvalidMfaCodeError {
  errorCode
  message
}

`

export const InvalidMfaIdentifierErrorFragment = `fragment InvalidMfaIdentifierError on InvalidMfaIdentifierError {
  errorCode
  message
}

`

export const InvalidMfaStrategyErrorFragment = `fragment InvalidMfaStrategyError on InvalidMfaStrategyError {
  errorCode
  message
}

`

export const InventoryItemFragment = `fragment InventoryItem on InventoryItem {
  createdAt
  deletedAt
  id
  inventoryLevels {
    ...InventoryLevelNoNesting
  }
  productId
  tracked
  updatedAt
}

`

export const InventoryItemListFragment = `fragment InventoryItemList on InventoryItemList {
  items {
    ...InventoryItemNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const InventoryLevelFragment = `fragment InventoryLevel on InventoryLevel {
  createdAt
  deletedAt
  id
  inventoryItemId
  locationId
  onHand
  unitsPerPack
  updatedAt
}

`

export const InventoryLevelListFragment = `fragment InventoryLevelList on InventoryLevelList {
  items {
    ...InventoryLevelNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const InventoryLevelMovementFragment = `fragment InventoryLevelMovement on InventoryLevelMovement {
  adjustment
  createdAt
  deletedAt
  id
  inventoryLevelId
  quantity
  updatedAt
  user {
    ...UserNoNesting
  }
  userId
}

`

export const InventoryLevelMovementListFragment = `fragment InventoryLevelMovementList on InventoryLevelMovementList {
  items {
    ...InventoryLevelMovementNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const InventoryMovementFragment = `fragment InventoryMovement on InventoryMovement {
  cancelledAt
  completedAt
  createdAt
  deletedAt
  dispatchedAt
  dueAt
  filters {
    ...InventoryMovementFilterNoNesting
  }
  id
  inventoryMovementDate
  isFullStocktake
  location {
    ...LocationNoNesting
  }
  locationId
  name
  receivedAt
  referenceNo
  sentAt
  status
  targetLocationId
  totals {
    ...InventoryMovementTotalsNoNesting
  }
  updatedAt
  user {
    ...UserNoNesting
  }
  userId
}

`

export const InventoryMovementFilterFragment = `fragment InventoryMovementFilter on InventoryMovementFilter {
  type
  value
}

`

export const InventoryMovementItemFragment = `fragment InventoryMovementItem on InventoryMovementItem {
  cost
  counted
  createdAt
  deletedAt
  expected
  id
  initialExpected
  inventoryMovementId
  manual
  productId
  status
  stockCode
  updatedAt
}

`

export const InventoryMovementItemListFragment = `fragment InventoryMovementItemList on InventoryMovementItemList {
  items {
    ...InventoryMovementItemNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const InventoryMovementItemSummaryTotalsFragment = `fragment InventoryMovementItemSummaryTotals on InventoryMovementItemSummaryTotals {
  counted
  inventoryMovementItemId
  price
  productId
  productName
  received
  stockNumber
  totalCost
}

`

export const InventoryMovementListFragment = `fragment InventoryMovementList on InventoryMovementList {
  items {
    ...InventoryMovementNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const InventoryMovementSummaryTotalFragment = `fragment InventoryMovementSummaryTotal on InventoryMovementSummaryTotal {
  cost
  counted
  received
}

`

export const InventoryMovementSummaryTotalsFragment = `fragment InventoryMovementSummaryTotals on InventoryMovementSummaryTotals {
  items {
    ...InventoryMovementItemSummaryTotalsNoNesting
  }
  totals {
    ...InventoryMovementSummaryTotalNoNesting
  }
}

`

export const InventoryMovementTotalsFragment = `fragment InventoryMovementTotals on InventoryMovementTotals {
  totalCostGain
  totalCostLoss
  totalCountGain
  totalCountLoss
}

`

export const InvoiceArrivalFragment = `fragment InvoiceArrival on InvoiceArrival {
  arrivalDate
  costPriceIncGST
  createdAt
  deletedAt
  dueDate
  formattedGSTCredit
  freightCost
  gstCredit
  id
  invoiceDate
  invoiceNumber
  items {
    ...InvoiceArrivalItemNoNesting
  }
  location {
    ...LocationNoNesting
  }
  locationId
  orderNumber
  status
  supplier {
    ...SupplierNoNesting
  }
  supplierId
  totalCost
  totalCostExGST
  totalCostIncGST
  totalQuantity
  updatedAt
}

`

export const InvoiceArrivalItemFragment = `fragment InvoiceArrivalItem on InvoiceArrivalItem {
  barcode
  costPrice
  createdAt
  deletedAt
  formattedGSTCredit
  gstCredit
  id
  invoiceArrivalId
  product {
    ...ProductNoNesting
  }
  productId
  quantity
  received
  retailPrice
  serialNumbers
  totalCost
  totalCostExGST
  totalCostIncGST
  tradePrice
  updatedAt
  webPrice
}

`

export const InvoiceArrivalListFragment = `fragment InvoiceArrivalList on InvoiceArrivalList {
  items {
    ...InvoiceArrivalNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const LayoutFragment = `fragment Layout on Layout {
  createdAt
  deletedAt
  id
  nodes {
    ...LayoutNodeNoNesting
  }
  terminalId
  updatedAt
}

`

export const LayoutListFragment = `fragment LayoutList on LayoutList {
  items {
    ...LayoutNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const LayoutNodeFragment = `fragment LayoutNode on LayoutNode {
  action
  color
  createdAt
  deletedAt
  filter {
    ...LayoutNodeFilterNoNesting
  }
  id
  label
  layoutId
  link {
    ...LayoutNodeLinkNoNesting
  }
  position
  productId
  showImage
  type
  updatedAt
}

`

export const LayoutNodeFilterFragment = `fragment LayoutNodeFilter on LayoutNodeFilter {
  type
  values
}

`

export const LayoutNodeLinkFragment = `fragment LayoutNodeLink on LayoutNodeLink {
  newTab
  url
}

`

export const LayoutNodeListFragment = `fragment LayoutNodeList on LayoutNodeList {
  items {
    ...LayoutNodeNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const LinklyConfigurationFragment = `fragment LinklyConfiguration on LinklyConfiguration {
  createdAt
  deletedAt
  id
  pairedPinpads {
    ...LinklyPairedPinpadNoNesting
  }
  paymentMethodId
  receiptAutoPrint
  updatedAt
}

`

export const LinklyErrorFragment = `fragment LinklyError on LinklyError {
  errorCode
  message
}

`

export const LinklyPairedPinpadFragment = `fragment LinklyPairedPinpad on LinklyPairedPinpad {
  createdAt
  deletedAt
  id
  linklyConfigurationId
  name
  secret
  updatedAt
}

`

export const LocationFragment = `fragment Location on Location {
  businessName
  createdAt
  enabled
  id
  name
  retailerLocationKey
  updatedAt
}

`

export const LocationListFragment = `fragment LocationList on LocationList {
  items {
    ...LocationNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const MarkupFragment = `fragment Markup on Markup {
  markupPercentage
  markupStyle
  markupType
}

`

export const MfaAlreadySetupErrorFragment = `fragment MfaAlreadySetupError on MfaAlreadySetupError {
  errorCode
  message
}

`

export const MfaMethodFragment = `fragment MfaMethod on MfaMethod {
  createdAt
  id
  identifier
  strategy
  updatedAt
  verified
}

`

export const MfaRequiredErrorFragment = `fragment MfaRequiredError on MfaRequiredError {
  errorCode
  message
  strategies
}

`

export const MimeTypeErrorFragment = `fragment MimeTypeError on MimeTypeError {
  errorCode
  fileName
  message
  mimeType
}

`

export const MissingPasswordErrorFragment = `fragment MissingPasswordError on MissingPasswordError {
  errorCode
  message
}

`

export const MovementItemProductSummaryFragment = `fragment MovementItemProductSummary on MovementItemProductSummary {
  onHand
  productId
  productName
  stockCode
  stockNumber
}

`

export const NativeAuthStrategyErrorFragment = `fragment NativeAuthStrategyError on NativeAuthStrategyError {
  errorCode
  message
}

`

export const NegativeQuantityErrorFragment = `fragment NegativeQuantityError on NegativeQuantityError {
  errorCode
  message
}

`

export const NotVerifiedErrorFragment = `fragment NotVerifiedError on NotVerifiedError {
  errorCode
  message
}

`

export const PaginatedListMetaFragment = `fragment PaginatedListMeta on PaginatedListMeta {
  currentPage
  itemCount
  totalItems
}

`

export const PasswordAlreadySetErrorFragment = `fragment PasswordAlreadySetError on PasswordAlreadySetError {
  errorCode
  message
}

`

export const PasswordValidationErrorFragment = `fragment PasswordValidationError on PasswordValidationError {
  errorCode
  message
  validationErrorMessage
}

`

export const PayTypeFragment = `fragment PayType on PayType {
  available
  channels
  configurationUrl
  createdAt
  defaultGatewayUrl
  deletedAt
  description
  gateway
  gatewayEditable
  id
  internal
  logo
  name
  onlineOnly
  platforms
  restricted
  subtext
  updatedAt
  version
}

`

export const PayTypeGatewayConfigFragment = `fragment PayTypeGatewayConfig on PayTypeGatewayConfig {
  url
}

`

export const PayTypeRoundingConfigFragment = `fragment PayTypeRoundingConfig on PayTypeRoundingConfig {
  rounding
}

`

export const PaymentFragment = `fragment Payment on Payment {
  amount
  errorMessage
  id
  label
  metadata
  name
  paymentDate
  paymentMethodId
  paymentTypeId
  state
  transactionId
}

`

export const PaymentMethodFragment = `fragment PaymentMethod on PaymentMethod {
  createdAt
  deletedAt
  enabled
  id
  name
  payTypeId
  paytype {
    ...PayTypeNoNesting
  }
  retailerPayCode
  retailerPayTypeId
  updatedAt
}

`

export const PaymentMethodListFragment = `fragment PaymentMethodList on PaymentMethodList {
  items {
    ...PaymentMethodNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const ProductFragment = `fragment Product on Product {
  UOM
  active
  adjustable
  category {
    ...CategoryNoNesting
  }
  categoryId
  createdAt
  deletedAt
  department {
    ...DepartmentNoNesting
  }
  departmentId
  description
  discountable
  displayName
  hasVariants
  id
  image {
    ...ProductImageNoNesting
  }
  images {
    ...ProductImageNoNesting
  }
  internal
  locationInventoryPricing {
    ...ProductLocationInventoryPricingNoNesting
  }
  loyaltyPointEarnValue
  loyaltyPointRedeemValue
  name
  options {
    ...ProductOptionNoNesting
  }
  price
  priceExcludingTax
  priceLookup
  productOptionGroupTemplate {
    ...ProductOptionGroupTemplateNoNesting
  }
  productOptionGroupTemplateId
  selectedOptions {
    ...SelectedOptionNoNesting
  }
  serialNumbersArr
  serialNumbersSale
  stockCode
  stockNumber
  supplier {
    ...SupplierNoNesting
  }
  supplierId
  supplierStockCode
  tags
  taxGroup {
    ...TaxGroupNoNesting
  }
  taxGroupId
  taxable
  totalInventory
  totalVariants
  updatedAt
  variantParent {
    ...ProductNoNesting
  }
  variantParentId
  variants {
    ...ProductNoNesting
  }
  webDescription
  webPrice
  weight
}

`

export const ProductFoundResultFragment = `fragment ProductFoundResult on ProductFoundResult {
  product {
    ...ProductInfoNoNesting
  }
  result
}

`

export const ProductIdResultFragment = `fragment ProductIdResult on ProductIdResult {
  productId
}

`

export const ProductImageFragment = `fragment ProductImage on ProductImage {
  createdAt
  deletedAt
  height
  id
  mineType
  name
  path
  position
  productId
  updatedAt
  width
}

`

export const ProductInfoFragment = `fragment ProductInfo on ProductInfo {
  description
  images
  name
  weight
}

`

export const ProductListFragment = `fragment ProductList on ProductList {
  items {
    ...ProductNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const ProductLocationInventoryPricingFragment = `fragment ProductLocationInventoryPricing on ProductLocationInventoryPricing {
  costPrice
  costPriceExclTax
  id
  location {
    ...LocationNoNesting
  }
  locationId
  productId
  retailPrice
  rrPrice
  serialNumbers
  stockOnHand
  taxGroupId
  taxRatePercent
  tradePrice
}

`

export const ProductNotFoundResultFragment = `fragment ProductNotFoundResult on ProductNotFoundResult {
  result
}

`

export const ProductOptionFragment = `fragment ProductOption on ProductOption {
  createdAt
  id
  name
  position
  updatedAt
  values
}

`

export const ProductOptionGroupTemplateFragment = `fragment ProductOptionGroupTemplate on ProductOptionGroupTemplate {
  createdAt
  id
  identifier
  name
  options {
    ...ProductOptionNoNesting
  }
  updatedAt
}

`

export const ProductOptionGroupTemplateListFragment = `fragment ProductOptionGroupTemplateList on ProductOptionGroupTemplateList {
  items {
    ...ProductOptionGroupTemplateNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const ReceiptFragment = `fragment Receipt on Receipt {
  createdAt
  defaultTemplateType
  deletedAt
  enabled
  id
  name
  settings {
    ...ReceiptSettingsNoNesting
  }
  updatedAt
}

`

export const ReceiptListFragment = `fragment ReceiptList on ReceiptList {
  items {
    ...ReceiptNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const ReceiptSettingsFragment = `fragment ReceiptSettings on ReceiptSettings {
  displayCustomerDetails
  footer
  header
  logoURL
  name
}

`

export const ReceiptTemplateTypeFragment = `fragment ReceiptTemplateType on ReceiptTemplateType {
  createdAt
  css
  deletedAt
  html
  id
  updatedAt
  version
}

`

export const RemovalAiDetailsFragment = `fragment RemovalAiDetails on RemovalAiDetails {
  apiKey
}

`

export const RemovalBgDetailsFragment = `fragment RemovalBgDetails on RemovalBgDetails {
  apiKey
}

`

export const RemoveBackgroundBase64ResultFragment = `fragment RemoveBackgroundBase64Result on RemoveBackgroundBase64Result {
  base64
}

`

export const RetailerExportCustomerFragment = `fragment RetailerExportCustomer on RetailerExportCustomer {
  Email
  FirstName
  Phone
  Surname
}

`

export const RetailerExportPaymentFragment = `fragment RetailerExportPayment on RetailerExportPayment {
  Amount
  PayCode
  Tendered
}

`

export const RetailerExportSaleFragment = `fragment RetailerExportSale on RetailerExportSale {
  CustID
  Customer {
    ...RetailerExportCustomerNoNesting
  }
  FreightCharge
  FreightTax
  LocationKey
  OrderNumber
  Payments {
    ...RetailerExportPaymentNoNesting
  }
  SaleAmount
  SaleCust
  SaleDate
  SaleDeposit
  SaleDiscount
  SaleId
  SaleItems {
    ...RetailerExportSaleItemNoNesting
  }
  SaleRound
  SaleStaff
  SaleTax
  SaleTerm
  SaleTime
  SaleType
}

`

export const RetailerExportSaleItemFragment = `fragment RetailerExportSaleItem on RetailerExportSaleItem {
  SerialNumber
  TotalPointsEarned
  TotalPointsUsed
  TxnDiscountType
  TxnExtendedAmount
  TxnExtendedDiscount
  TxnExtendedTax
  TxnItem
  TxnQty
  TxnStock
  TxnTotal
  TxnTotalCost
  TxnUnitAmount
  TxnUnitCost
  TxnUnitDiscount
  TxnUnitTax
}

`

export const RetailerPayTypeFragment = `fragment RetailerPayType on RetailerPayType {
  createdAt
  deletedAt
  id
  payCode
  payDescription
  payOver
  payType
  updatedAt
}

`

export const RetailerPayTypeListFragment = `fragment RetailerPayTypeList on RetailerPayTypeList {
  items {
    ...RetailerPayTypeNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const RoleFragment = `fragment Role on Role {
  code
  createdAt
  id
  permissions
  updatedAt
}

`

export const SaleFragment = `fragment Sale on Sale {
  active
  balance
  code
  completedAt
  createdAt
  customer {
    ...CustomerNoNesting
  }
  customerId
  deletedAt
  id
  invoiceNumber
  invoiceSequence
  isReturn
  lineItems {
    ...SaleLineItemNoNesting
  }
  location {
    ...LocationNoNesting
  }
  locationId
  metadata
  note
  payments {
    ...PaymentNoNesting
  }
  purchaseOrderNumber
  quoteAccepted
  receiptConfirmationUrl
  receiptConfirmed
  receiptId
  returnForId
  saleDate
  saleType
  source
  staff {
    ...StaffNoNesting
  }
  staffId
  state
  terminal {
    ...TerminalNoNesting
  }
  terminalId
  totalAdjustmentPercentSaving
  totalAdjustments
  totalCost
  totalPrice
  totalTax
  updatedAt
}

`

export const SaleAddressFragment = `fragment SaleAddress on SaleAddress {
  city
  company
  country
  countryCode
  fullName
  phoneNumber
  postCode
  state
  street1
  street2
}

`

export const SaleAdjustmentFragment = `fragment SaleAdjustment on SaleAdjustment {
  amount
  createdAt
  id
  metadata
  type
  unitLoyaltyPointsUsed
  updatedAt
}

`

export const SaleHistoryEntryFragment = `fragment SaleHistoryEntry on SaleHistoryEntry {
  createdAt
  deletedAt
  id
  type
  updatedAt
  user {
    ...UserNoNesting
  }
  userId
}

`

export const SaleLineItemFragment = `fragment SaleLineItem on SaleLineItem {
  adjustments {
    ...SaleAdjustmentNoNesting
  }
  basePrice
  basePriceInclTax
  billingAddress {
    ...SaleAddressNoNesting
  }
  cost
  createdAt
  customerNote
  deletedAt
  id
  loyaltyPointEarned
  metadata
  name
  price
  priceInclTax
  product {
    ...ProductNoNesting
  }
  productId
  quantity
  saleId
  serialNumber
  shippingAddress {
    ...SaleAddressNoNesting
  }
  stockCode
  stockNumber
  tax
  taxRatePercent
  totalAdjustments
  totalBasePrice
  totalBasePriceInclTax
  totalCost
  totalPrice
  totalPriceInclusiveTax
  totalTax
  updatedAt
}

`

export const SaleLineItemEntryDataFragment = `fragment SaleLineItemEntryData on SaleLineItemEntryData {
  adjustments {
    ...SaleAdjustmentNoNesting
  }
  basePrice
  basePriceInclTax
  cost
  loyaltyPointEarned
  metadata
  price
  priceInclTax
  productName
  quantity
  serialNumber
  stockCode
  stockNumber
  tax
  taxRatePercent
  totalAdjustments
  totalBasePrice
  totalBasePriceInclTax
  totalCost
  totalPrice
  totalPriceInclusiveTax
  totalTax
}

`

export const SaleListFragment = `fragment SaleList on SaleList {
  items {
    ...SaleNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const SaleModifiedCustomerAddedEntryDataFragment = `fragment SaleModifiedCustomerAddedEntryData on SaleModifiedCustomerAddedEntryData {
  customerId
  emailAddress
}

`

export const SaleModifiedEntryDataFragment = `fragment SaleModifiedEntryData on SaleModifiedEntryData {
  input
  oldEntity
}

`

export const SaleNoteEntryDataFragment = `fragment SaleNoteEntryData on SaleNoteEntryData {
  note
}

`

export const SaleStateTransitionErrorFragment = `fragment SaleStateTransitionError on SaleStateTransitionError {
  errorCode
  fromState
  message
  toState
  transitionError
}

`

export const SaleStatusTransitionDataFragment = `fragment SaleStatusTransitionData on SaleStatusTransitionData {
  from
  to
}

`

export const SaleSummaryProductResultFragment = `fragment SaleSummaryProductResult on SaleSummaryProductResult {
  completedDate
  count
  product {
    ...ProductNoNesting
  }
  productId
  saleId
  state
  total
}

`

export const SaleWithHistoryEntriesFragment = `fragment SaleWithHistoryEntries on SaleWithHistoryEntries {
  historyEntries {
    ...SaleHistoryEntryNoNesting
  }
  sale {
    ...SaleNoNesting
  }
}

`

export const SaleWithHistoryEntriesListFragment = `fragment SaleWithHistoryEntriesList on SaleWithHistoryEntriesList {
  items {
    ...SaleWithHistoryEntriesNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const SearchResponseFragment = `fragment SearchResponse on SearchResponse {
  items {
    ...SearchResultItemNoNesting
  }
}

`

export const SearchResultItemFragment = `fragment SearchResultItem on SearchResultItem {
  id
  type
}

`

export const SelectedOptionFragment = `fragment SelectedOption on SelectedOption {
  name
  value
}

`

export const SelfCheckoutLocationSettingsFragment = `fragment SelfCheckoutLocationSettings on SelfCheckoutLocationSettings {
  checkoutSuccessHeading
  enabled
  location {
    ...LocationNoNesting
  }
  locationId
  offlineCheckoutEnabled
  onlineCheckoutEnabled
  payAtCounterHeading
  payAtCounterSubheading
  terminal {
    ...TerminalNoNesting
  }
  terminalId
}

`

export const SelfCheckoutSettingsFragment = `fragment SelfCheckoutSettings on SelfCheckoutSettings {
  enabled
  locationSettings {
    ...SelfCheckoutLocationSettingsNoNesting
  }
  logoURL
  paymentMethod {
    ...PaymentMethodNoNesting
  }
  paymentMethodId
}

`

export const SerialNumberFragment = `fragment SerialNumber on SerialNumber {
  createdAt
  deletedAt
  id
  productLocationInventoryPricingId
  updatedAt
  value
}

`

export const SerialNumberListFragment = `fragment SerialNumberList on SerialNumberList {
  items {
    ...SerialNumberNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const ServerConfigFragment = `fragment ServerConfig on ServerConfig {
  availablePayTypes {
    ...PayTypeNoNesting
  }
  internalPayTypes {
    ...PayTypeNoNesting
  }
  receiptTemplateTypes {
    ...ReceiptTemplateTypeNoNesting
  }
}

`

export const StaffFragment = `fragment Staff on Staff {
  createdAt
  deletedAt
  emailAddress
  enabled
  fullName
  hasUserAccount
  id
  initials
  terminalIds
  terminals {
    ...TerminalNoNesting
  }
  updatedAt
  user {
    ...UserV2NoNesting
  }
  userId
  username
}

`

export const StaffListFragment = `fragment StaffList on StaffList {
  items {
    ...StaffNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const StockCountFragment = `fragment StockCount on StockCount {
  createdAt
  deletedAt
  id
  location {
    ...LocationNoNesting
  }
  locationId
  name
  retailerStocktakeId
  source
  status
  updatedAt
}

`

export const StockCountItemFragment = `fragment StockCountItem on StockCountItem {
  counted
  countedAt
  createdAt
  deletedAt
  id
  lineId
  status
  updatedAt
}

`

export const StockCountLineFragment = `fragment StockCountLine on StockCountLine {
  barcode
  createdAt
  deletedAt
  expected
  id
  initialExpected
  items {
    ...StockCountItemNoNesting
  }
  product {
    ...ProductNoNesting
  }
  productId
  stockCountId
  updatedAt
}

`

export const StockCountLineListFragment = `fragment StockCountLineList on StockCountLineList {
  items {
    ...StockCountLineNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const StockCountListFragment = `fragment StockCountList on StockCountList {
  items {
    ...StockCountNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const StockNumberNotFoundErrorFragment = `fragment StockNumberNotFoundError on StockNumberNotFoundError {
  errorCode
  message
}

`

export const StockOrderFragment = `fragment StockOrder on StockOrder {
  completedAt
  createdAt
  deletedAt
  formattedOrderNumber
  id
  items {
    ...StockOrderItemNoNesting
  }
  location {
    ...LocationNoNesting
  }
  locationId
  orderCreatedDate
  orderNumber
  status
  totalQuantity
  updatedAt
}

`

export const StockOrderItemFragment = `fragment StockOrderItem on StockOrderItem {
  barcode
  createdAt
  deletedAt
  id
  product {
    ...ProductNoNesting
  }
  productId
  quantity
  stockOrderId
  updatedAt
}

`

export const StockOrderListFragment = `fragment StockOrderList on StockOrderList {
  items {
    ...StockOrderNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const StocktakeItemFragment = `fragment StocktakeItem on StocktakeItem {
  cost
  counted
  createdAt
  deletedAt
  expected
  id
  initialExpected
  manual
  product
  status
  stockCode
  stocktakeId
  updatedAt
}

`

export const StocktakeItemListFragment = `fragment StocktakeItemList on StocktakeItemList {
  items {
    ...StocktakeItemNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const SuccessFragment = `fragment Success on Success {
  success
}

`

export const SupplierFragment = `fragment Supplier on Supplier {
  active
  bannerImageUrl
  createdAt
  deletedAt
  description
  id
  name
  suppCode
  updatedAt
}

`

export const SupplierListFragment = `fragment SupplierList on SupplierList {
  items {
    ...SupplierNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const SwapLayoutNodesResultFragment = `fragment SwapLayoutNodesResult on SwapLayoutNodesResult {
  firstNode {
    ...LayoutNodeNoNesting
  }
  secondNode {
    ...LayoutNodeNoNesting
  }
}

`

export const TaxGroupFragment = `fragment TaxGroup on TaxGroup {
  active
  arrivePercentage
  createdAt
  deletedAt
  id
  name
  salePercentage
  shortName
  taxGroupID
  updatedAt
}

`

export const TaxGroupListFragment = `fragment TaxGroupList on TaxGroupList {
  items {
    ...TaxGroupNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const TenantFragment = `fragment Tenant on Tenant {
  identifier
}

`

export const TerminalFragment = `fragment Terminal on Terminal {
  createdAt
  defaultReceiptTemplateId
  deletedAt
  enabled
  id
  invoiceSequence
  name
  openTerminalClosure {
    ...TerminalClosureNoNesting
  }
  openTerminalClosureId
  paymentMethodIds
  printReceiptOnCheckout
  salePrefix
  saleSufix
  terminalCloseTime
  terminalOpenTime
  updatedAt
}

`

export const TerminalClosureFragment = `fragment TerminalClosure on TerminalClosure {
  cashMovements {
    ...CashMovementNoNesting
  }
  countedPaymentsSummary {
    ...TerminalClosurePaymentMethodSummaryNoNesting
  }
  createdAt
  deletedAt
  estimatedCashBalance
  id
  isClosed
  payments {
    ...TerminalClosurePaymentTotalNoNesting
  }
  saleSummary {
    ...TerminalClosureSalesSummaryNoNesting
  }
  terminalCloseDate
  terminalId
  terminalName
  terminalOpenDate
  updatedAt
}

`

export const TerminalClosureListFragment = `fragment TerminalClosureList on TerminalClosureList {
  items {
    ...TerminalClosureNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const TerminalClosurePaymentMethodSummaryFragment = `fragment TerminalClosurePaymentMethodSummary on TerminalClosurePaymentMethodSummary {
  difference
  expected
  payTypeId
  paymentMethodId
  paymentTypeName
  total
}

`

export const TerminalClosurePaymentTotalFragment = `fragment TerminalClosurePaymentTotal on TerminalClosurePaymentTotal {
  counted
  paymentMethodId
}

`

export const TerminalClosurePaymentsSummaryFragment = `fragment TerminalClosurePaymentsSummary on TerminalClosurePaymentsSummary {
  payments {
    ...TerminalClosurePaymentMethodSummaryNoNesting
  }
  terminalClosureId
  terminalId
  terminalOpenTime
}

`

export const TerminalClosureSalesSummaryFragment = `fragment TerminalClosureSalesSummary on TerminalClosureSalesSummary {
  totalDiscounts
  totalInvoices
  totalLaybys
  totalPayments
  totalRefunds
  totalSales
  totalTaxes
}

`

export const TerminalListFragment = `fragment TerminalList on TerminalList {
  items {
    ...TerminalNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const UserFragment = `fragment User on User {
  displayName
  emailAddress
  id
  mobileNumber
  numberAvailableTerminals
  stripeAccountId
  stripeOnboardingComplete
  userAccountType
  username
}

`

export const UserV2Fragment = `fragment UserV2 on UserV2 {
  authenticationMethods {
    ...AuthenticationMethodNoNesting
  }
  createdAt
  enabled2fa
  id
  identifier
  lastLogin
  mfaMethods {
    ...MfaMethodNoNesting
  }
  roles {
    ...RoleNoNesting
  }
  updatedAt
  verified
}

`

export const VendorFragment = `fragment Vendor on Vendor {
  active
  id
  numberAvailableTerminals
  stripeOnboardingComplete
  talinkId
  talinkUsername
  userAccountType
}

`


export const AccessTokenSessionDeepNestingFragment = `fragment AccessTokenSessionDeepNesting on AccessTokenSession {
  createdAt
  deletedAt
  expires
  id
  invalidated
  name
  token
  updatedAt
  userId
}

`

export const AnalyticProductMetaDataItemDeepNestingFragment = `fragment AnalyticProductMetaDataItemDeepNesting on AnalyticProductMetaDataItem {
  id
  imageUrl
  name
  sku
  value
}

`

export const AuthenticationMethodDeepNestingFragment = `fragment AuthenticationMethodDeepNesting on AuthenticationMethod {
  createdAt
  id
  strategy
  updatedAt
}

`

export const AuthenticationResultDeepNestingFragment = `fragment AuthenticationResultDeepNesting on AuthenticationResult {
  talinkId
  token
  userId
}

`

export const BackgroundRemoverInsufficientCreditsErrorDeepNestingFragment = `fragment BackgroundRemoverInsufficientCreditsErrorDeepNesting on BackgroundRemoverInsufficientCreditsError {
  errorCode
  message
}

`

export const BackgroundRemoverNotConfiguredErrorDeepNestingFragment = `fragment BackgroundRemoverNotConfiguredErrorDeepNesting on BackgroundRemoverNotConfiguredError {
  errorCode
  message
}

`

export const CashMovementDeepNestingFragment = `fragment CashMovementDeepNesting on CashMovement {
  amount
  createdAt
  date
  id
  note
  openTerminalClosureId
  type
  updatedAt
  userId
  userIdentifier
}

`

export const CategoryDeepNestingFragment = `fragment CategoryDeepNesting on Category {
  active
  allowDiscount
  bannerImageUrl
  catCode
  createdAt
  deletedAt
  departmentId
  description
  id
  markup {
    ...MarkupDeepNesting
  }
  name
  numberOfProducts
  preferredTaxGroupId
  updatedAt
}

`

export const CategoryListDeepNestingFragment = `fragment CategoryListDeepNesting on CategoryList {
  items {
    ...CategoryDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const ConfigArgDeepNestingFragment = `fragment ConfigArgDeepNesting on ConfigArg {
  name
  value
}

`

export const ConfigArgDefinitionDeepNestingFragment = `fragment ConfigArgDefinitionDeepNesting on ConfigArgDefinition {
  defaultValue
  description
  label
  list
  name
  required
  type
}

`

export const ConfigurableOperationDeepNestingFragment = `fragment ConfigurableOperationDeepNesting on ConfigurableOperation {
  args {
    ...ConfigArgDeepNesting
  }
  code
}

`

export const ConfigurableOperationDefinitionDeepNestingFragment = `fragment ConfigurableOperationDefinitionDeepNesting on ConfigurableOperationDefinition {
  args {
    ...ConfigArgDefinitionDeepNesting
  }
  code
  description
}

`

export const CurrencyTypeDeepNestingFragment = `fragment CurrencyTypeDeepNesting on CurrencyType {
  code
  symbol
}

`

export const CurrentUserAndTenantDeepNestingFragment = `fragment CurrentUserAndTenantDeepNesting on CurrentUserAndTenant {
  currentUser {
    ...CurrentUserV2DeepNesting
  }
  tenant {
    ...TenantDeepNesting
  }
}

`

export const CurrentUserV2DeepNestingFragment = `fragment CurrentUserV2DeepNesting on CurrentUserV2 {
  id
  identifier
  permissions
  restrictedTerminalIds
  token
}

`

export const CustomerDeepNestingFragment = `fragment CustomerDeepNesting on Customer {
  addresses {
    ...CustomerAddressDeepNesting
  }
  amountSpend
  createdAt
  deletedAt
  emailAddress
  firstName
  gender
  id
  invoicedAmount
  isRetailerCusAccount
  lastName
  loyaltyPoints
  mobileNumber
  numberOfOrders
  phoneNumber
  retailerId
  updatedAt
}

`

export const CustomerAddressDeepNestingFragment = `fragment CustomerAddressDeepNesting on CustomerAddress {
  address1
  address2
  city
  country
  createdAt
  deletedAt
  id
  isPrimary
  postcode
  state
  updatedAt
}

`

export const CustomerListDeepNestingFragment = `fragment CustomerListDeepNesting on CustomerList {
  items {
    ...CustomerDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const DeletedDeepNestingFragment = `fragment DeletedDeepNesting on Deleted {
  result
}

`

export const DeletionResponseDeepNestingFragment = `fragment DeletionResponseDeepNesting on DeletionResponse {
  reason
  result
}

`

export const DepartmentDeepNestingFragment = `fragment DepartmentDeepNesting on Department {
  active
  allowDiscount
  bannerImageUrl
  createdAt
  deletedAt
  deptCode
  description
  id
  markup {
    ...MarkupDeepNesting
  }
  name
  numberOfCategories
  numberOfProducts
  preferredTaxGroupId
  updatedAt
}

`

export const DepartmentListDeepNestingFragment = `fragment DepartmentListDeepNesting on DepartmentList {
  items {
    ...DepartmentDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const DocketFilterDeepNestingFragment = `fragment DocketFilterDeepNesting on DocketFilter {
  type
  values
}

`

export const DocketTemplateDeepNestingFragment = `fragment DocketTemplateDeepNesting on DocketTemplate {
  automaticallyPrint
  createdAt
  defaultTemplateTypeId
  deletedAt
  enabled
  filters {
    ...DocketFilterDeepNesting
  }
  id
  name
  terminalIds
  updatedAt
}

`

export const DocketTemplateListDeepNestingFragment = `fragment DocketTemplateListDeepNesting on DocketTemplateList {
  items {
    ...DocketTemplateDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const DocketTemplateTypeDeepNestingFragment = `fragment DocketTemplateTypeDeepNesting on DocketTemplateType {
  createdAt
  css
  deletedAt
  html
  id
  updatedAt
  version
}

`

export const DownloadReceiptPdfBase64StringResponseDeepNestingFragment = `fragment DownloadReceiptPdfBase64StringResponseDeepNesting on DownloadReceiptPdfBase64StringResponse {
  data
  fileName
}

`

export const DownloadStockCountCsvBase64StringResponseDeepNestingFragment = `fragment DownloadStockCountCsvBase64StringResponseDeepNesting on DownloadStockCountCsvBase64StringResponse {
  data
  fileName
}

`

export const DownloadStocktakeCsvBase64StringResponseDeepNestingFragment = `fragment DownloadStocktakeCsvBase64StringResponseDeepNesting on DownloadStocktakeCsvBase64StringResponse {
  data
  fileName
}

`

export const EmailAddressConflictErrorDeepNestingFragment = `fragment EmailAddressConflictErrorDeepNesting on EmailAddressConflictError {
  errorCode
  message
}

`

export const ExportStockCountItemResultDeepNestingFragment = `fragment ExportStockCountItemResultDeepNesting on ExportStockCountItemResult {
  stockCountItem {
    ...StockCountItemDeepNesting
  }
  success
}

`

export const FeaturesListDeepNestingFragment = `fragment FeaturesListDeepNesting on FeaturesList {
  pointOfSale
  selfCheckout
  stocktake
  utilities
}

`

export const FilterValueMetaDeepNestingFragment = `fragment FilterValueMetaDeepNesting on FilterValueMeta {
  label
  type
  value
}

`

export const GlobalSettingsDeepNestingFragment = `fragment GlobalSettingsDeepNesting on GlobalSettings {
  abn
  address1
  address2
  automaticBackgroundRemoval
  automaticOpenTerminalClosure
  automaticallyEmailStockTake
  automaticallyEmailTerminalClosureSummary
  bgRemovalStrategy
  businessName
  cashManagedPaymentMethodId
  countryCode
  currency {
    ...CurrencyTypeDeepNesting
  }
  defaultLaybyDepositPercentage
  defaultTerminalClosureFloat
  email
  laybyEnabled
  minLaybySpend
  phone
  postcode
  productSurchargeId
  removalAiDetails {
    ...RemovalAiDetailsDeepNesting
  }
  removalBgDetails {
    ...RemovalBgDetailsDeepNesting
  }
  retailerInBoundEnabled
  showExpectedClosureCounts
  stocktakeSendToAddress
  talinkId
  taxPercentage
  terminalClosureSendToAddress
  timezone
  website
}

`

export const InsufficientBarcodeLookupCreditsErrorDeepNestingFragment = `fragment InsufficientBarcodeLookupCreditsErrorDeepNesting on InsufficientBarcodeLookupCreditsError {
  errorCode
  message
}

`

export const InsufficientStockErrorDeepNestingFragment = `fragment InsufficientStockErrorDeepNesting on InsufficientStockError {
  errorCode
  message
  quantityAvailable
  sale {
    ...SaleDeepNesting
  }
}

`

export const InvalidBarcodeLookupCredentialsErrorDeepNestingFragment = `fragment InvalidBarcodeLookupCredentialsErrorDeepNesting on InvalidBarcodeLookupCredentialsError {
  errorCode
  message
}

`

export const InvalidCredentialsErrorDeepNestingFragment = `fragment InvalidCredentialsErrorDeepNesting on InvalidCredentialsError {
  authenticationError
  errorCode
  message
}

`

export const InvalidLinklyCredentialsErrorDeepNestingFragment = `fragment InvalidLinklyCredentialsErrorDeepNesting on InvalidLinklyCredentialsError {
  errorCode
  message
}

`

export const InvalidMfaCodeErrorDeepNestingFragment = `fragment InvalidMfaCodeErrorDeepNesting on InvalidMfaCodeError {
  errorCode
  message
}

`

export const InvalidMfaIdentifierErrorDeepNestingFragment = `fragment InvalidMfaIdentifierErrorDeepNesting on InvalidMfaIdentifierError {
  errorCode
  message
}

`

export const InvalidMfaStrategyErrorDeepNestingFragment = `fragment InvalidMfaStrategyErrorDeepNesting on InvalidMfaStrategyError {
  errorCode
  message
}

`

export const InventoryItemDeepNestingFragment = `fragment InventoryItemDeepNesting on InventoryItem {
  createdAt
  deletedAt
  id
  inventoryLevels {
    ...InventoryLevelDeepNesting
  }
  productId
  tracked
  updatedAt
}

`

export const InventoryItemListDeepNestingFragment = `fragment InventoryItemListDeepNesting on InventoryItemList {
  items {
    ...InventoryItemDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const InventoryLevelDeepNestingFragment = `fragment InventoryLevelDeepNesting on InventoryLevel {
  createdAt
  deletedAt
  id
  inventoryItemId
  locationId
  onHand
  unitsPerPack
  updatedAt
}

`

export const InventoryLevelListDeepNestingFragment = `fragment InventoryLevelListDeepNesting on InventoryLevelList {
  items {
    ...InventoryLevelDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const InventoryLevelMovementDeepNestingFragment = `fragment InventoryLevelMovementDeepNesting on InventoryLevelMovement {
  adjustment
  createdAt
  deletedAt
  id
  inventoryLevelId
  quantity
  updatedAt
  user {
    ...UserDeepNesting
  }
  userId
}

`

export const InventoryLevelMovementListDeepNestingFragment = `fragment InventoryLevelMovementListDeepNesting on InventoryLevelMovementList {
  items {
    ...InventoryLevelMovementDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const InventoryMovementDeepNestingFragment = `fragment InventoryMovementDeepNesting on InventoryMovement {
  cancelledAt
  completedAt
  createdAt
  deletedAt
  dispatchedAt
  dueAt
  filters {
    ...InventoryMovementFilterDeepNesting
  }
  id
  inventoryMovementDate
  isFullStocktake
  location {
    ...LocationDeepNesting
  }
  locationId
  name
  receivedAt
  referenceNo
  sentAt
  status
  targetLocationId
  totals {
    ...InventoryMovementTotalsDeepNesting
  }
  updatedAt
  user {
    ...UserDeepNesting
  }
  userId
}

`

export const InventoryMovementFilterDeepNestingFragment = `fragment InventoryMovementFilterDeepNesting on InventoryMovementFilter {
  type
  value
}

`

export const InventoryMovementItemDeepNestingFragment = `fragment InventoryMovementItemDeepNesting on InventoryMovementItem {
  cost
  counted
  createdAt
  deletedAt
  expected
  id
  initialExpected
  inventoryMovementId
  manual
  productId
  status
  stockCode
  updatedAt
}

`

export const InventoryMovementItemListDeepNestingFragment = `fragment InventoryMovementItemListDeepNesting on InventoryMovementItemList {
  items {
    ...InventoryMovementItemDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const InventoryMovementItemSummaryTotalsDeepNestingFragment = `fragment InventoryMovementItemSummaryTotalsDeepNesting on InventoryMovementItemSummaryTotals {
  counted
  inventoryMovementItemId
  price
  productId
  productName
  received
  stockNumber
  totalCost
}

`

export const InventoryMovementListDeepNestingFragment = `fragment InventoryMovementListDeepNesting on InventoryMovementList {
  items {
    ...InventoryMovementDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const InventoryMovementSummaryTotalDeepNestingFragment = `fragment InventoryMovementSummaryTotalDeepNesting on InventoryMovementSummaryTotal {
  cost
  counted
  received
}

`

export const InventoryMovementSummaryTotalsDeepNestingFragment = `fragment InventoryMovementSummaryTotalsDeepNesting on InventoryMovementSummaryTotals {
  items {
    ...InventoryMovementItemSummaryTotalsDeepNesting
  }
  totals {
    ...InventoryMovementSummaryTotalDeepNesting
  }
}

`

export const InventoryMovementTotalsDeepNestingFragment = `fragment InventoryMovementTotalsDeepNesting on InventoryMovementTotals {
  totalCostGain
  totalCostLoss
  totalCountGain
  totalCountLoss
}

`

export const InvoiceArrivalDeepNestingFragment = `fragment InvoiceArrivalDeepNesting on InvoiceArrival {
  arrivalDate
  costPriceIncGST
  createdAt
  deletedAt
  dueDate
  formattedGSTCredit
  freightCost
  gstCredit
  id
  invoiceDate
  invoiceNumber
  items {
    ...InvoiceArrivalItemDeepNesting
  }
  location {
    ...LocationDeepNesting
  }
  locationId
  orderNumber
  status
  supplier {
    ...SupplierDeepNesting
  }
  supplierId
  totalCost
  totalCostExGST
  totalCostIncGST
  totalQuantity
  updatedAt
}

`

export const InvoiceArrivalItemDeepNestingFragment = `fragment InvoiceArrivalItemDeepNesting on InvoiceArrivalItem {
  barcode
  costPrice
  createdAt
  deletedAt
  formattedGSTCredit
  gstCredit
  id
  invoiceArrivalId
  product {
    ...ProductDeepNesting
  }
  productId
  quantity
  received
  retailPrice
  serialNumbers
  totalCost
  totalCostExGST
  totalCostIncGST
  tradePrice
  updatedAt
  webPrice
}

`

export const InvoiceArrivalListDeepNestingFragment = `fragment InvoiceArrivalListDeepNesting on InvoiceArrivalList {
  items {
    ...InvoiceArrivalDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const LayoutDeepNestingFragment = `fragment LayoutDeepNesting on Layout {
  createdAt
  deletedAt
  id
  nodes {
    ...LayoutNodeDeepNesting
  }
  terminalId
  updatedAt
}

`

export const LayoutListDeepNestingFragment = `fragment LayoutListDeepNesting on LayoutList {
  items {
    ...LayoutDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const LayoutNodeDeepNestingFragment = `fragment LayoutNodeDeepNesting on LayoutNode {
  action
  color
  createdAt
  deletedAt
  filter {
    ...LayoutNodeFilterDeepNesting
  }
  id
  label
  layoutId
  link {
    ...LayoutNodeLinkDeepNesting
  }
  position
  productId
  showImage
  type
  updatedAt
}

`

export const LayoutNodeFilterDeepNestingFragment = `fragment LayoutNodeFilterDeepNesting on LayoutNodeFilter {
  type
  values
}

`

export const LayoutNodeLinkDeepNestingFragment = `fragment LayoutNodeLinkDeepNesting on LayoutNodeLink {
  newTab
  url
}

`

export const LayoutNodeListDeepNestingFragment = `fragment LayoutNodeListDeepNesting on LayoutNodeList {
  items {
    ...LayoutNodeDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const LinklyConfigurationDeepNestingFragment = `fragment LinklyConfigurationDeepNesting on LinklyConfiguration {
  createdAt
  deletedAt
  id
  pairedPinpads {
    ...LinklyPairedPinpadDeepNesting
  }
  paymentMethodId
  receiptAutoPrint
  updatedAt
}

`

export const LinklyErrorDeepNestingFragment = `fragment LinklyErrorDeepNesting on LinklyError {
  errorCode
  message
}

`

export const LinklyPairedPinpadDeepNestingFragment = `fragment LinklyPairedPinpadDeepNesting on LinklyPairedPinpad {
  createdAt
  deletedAt
  id
  linklyConfigurationId
  name
  secret
  updatedAt
}

`

export const LocationDeepNestingFragment = `fragment LocationDeepNesting on Location {
  businessName
  createdAt
  enabled
  id
  name
  retailerLocationKey
  updatedAt
}

`

export const LocationListDeepNestingFragment = `fragment LocationListDeepNesting on LocationList {
  items {
    ...LocationDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const MarkupDeepNestingFragment = `fragment MarkupDeepNesting on Markup {
  markupPercentage
  markupStyle
  markupType
}

`

export const MfaAlreadySetupErrorDeepNestingFragment = `fragment MfaAlreadySetupErrorDeepNesting on MfaAlreadySetupError {
  errorCode
  message
}

`

export const MfaMethodDeepNestingFragment = `fragment MfaMethodDeepNesting on MfaMethod {
  createdAt
  id
  identifier
  strategy
  updatedAt
  verified
}

`

export const MfaRequiredErrorDeepNestingFragment = `fragment MfaRequiredErrorDeepNesting on MfaRequiredError {
  errorCode
  message
  strategies
}

`

export const MimeTypeErrorDeepNestingFragment = `fragment MimeTypeErrorDeepNesting on MimeTypeError {
  errorCode
  fileName
  message
  mimeType
}

`

export const MissingPasswordErrorDeepNestingFragment = `fragment MissingPasswordErrorDeepNesting on MissingPasswordError {
  errorCode
  message
}

`

export const MovementItemProductSummaryDeepNestingFragment = `fragment MovementItemProductSummaryDeepNesting on MovementItemProductSummary {
  onHand
  productId
  productName
  stockCode
  stockNumber
}

`

export const NativeAuthStrategyErrorDeepNestingFragment = `fragment NativeAuthStrategyErrorDeepNesting on NativeAuthStrategyError {
  errorCode
  message
}

`

export const NegativeQuantityErrorDeepNestingFragment = `fragment NegativeQuantityErrorDeepNesting on NegativeQuantityError {
  errorCode
  message
}

`

export const NotVerifiedErrorDeepNestingFragment = `fragment NotVerifiedErrorDeepNesting on NotVerifiedError {
  errorCode
  message
}

`

export const PaginatedListMetaDeepNestingFragment = `fragment PaginatedListMetaDeepNesting on PaginatedListMeta {
  currentPage
  itemCount
  totalItems
}

`

export const PasswordAlreadySetErrorDeepNestingFragment = `fragment PasswordAlreadySetErrorDeepNesting on PasswordAlreadySetError {
  errorCode
  message
}

`

export const PasswordValidationErrorDeepNestingFragment = `fragment PasswordValidationErrorDeepNesting on PasswordValidationError {
  errorCode
  message
  validationErrorMessage
}

`

export const PayTypeDeepNestingFragment = `fragment PayTypeDeepNesting on PayType {
  available
  channels
  configurationUrl
  createdAt
  defaultGatewayUrl
  deletedAt
  description
  gateway
  gatewayEditable
  id
  internal
  logo
  name
  onlineOnly
  platforms
  restricted
  subtext
  updatedAt
  version
}

`

export const PayTypeGatewayConfigDeepNestingFragment = `fragment PayTypeGatewayConfigDeepNesting on PayTypeGatewayConfig {
  url
}

`

export const PayTypeRoundingConfigDeepNestingFragment = `fragment PayTypeRoundingConfigDeepNesting on PayTypeRoundingConfig {
  rounding
}

`

export const PaymentDeepNestingFragment = `fragment PaymentDeepNesting on Payment {
  amount
  errorMessage
  id
  label
  metadata
  name
  paymentDate
  paymentMethodId
  paymentTypeId
  state
  transactionId
}

`

export const PaymentMethodDeepNestingFragment = `fragment PaymentMethodDeepNesting on PaymentMethod {
  createdAt
  deletedAt
  enabled
  id
  name
  payTypeId
  paytype {
    ...PayTypeDeepNesting
  }
  retailerPayCode
  retailerPayTypeId
  updatedAt
}

`

export const PaymentMethodListDeepNestingFragment = `fragment PaymentMethodListDeepNesting on PaymentMethodList {
  items {
    ...PaymentMethodDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const ProductDeepNestingFragment = `fragment ProductDeepNesting on Product {
  UOM
  active
  adjustable
  category {
    ...CategoryDeepNesting
  }
  categoryId
  createdAt
  deletedAt
  department {
    ...DepartmentDeepNesting
  }
  departmentId
  description
  discountable
  displayName
  hasVariants
  id
  image {
    ...ProductImageDeepNesting
  }
  images {
    ...ProductImageDeepNesting
  }
  internal
  locationInventoryPricing {
    ...ProductLocationInventoryPricingDeepNesting
  }
  loyaltyPointEarnValue
  loyaltyPointRedeemValue
  name
  options {
    ...ProductOptionDeepNesting
  }
  price
  priceExcludingTax
  priceLookup
  productOptionGroupTemplate {
    ...ProductOptionGroupTemplateDeepNesting
  }
  productOptionGroupTemplateId
  selectedOptions {
    ...SelectedOptionDeepNesting
  }
  serialNumbersArr
  serialNumbersSale
  stockCode
  stockNumber
  supplier {
    ...SupplierDeepNesting
  }
  supplierId
  supplierStockCode
  tags
  taxGroup {
    ...TaxGroupDeepNesting
  }
  taxGroupId
  taxable
  totalInventory
  totalVariants
  updatedAt
  variantParent {
    ...ProductDeepNesting
  }
  variantParentId
  variants {
    ...ProductDeepNesting
  }
  webDescription
  webPrice
  weight
}

`

export const ProductFoundResultDeepNestingFragment = `fragment ProductFoundResultDeepNesting on ProductFoundResult {
  product {
    ...ProductInfoDeepNesting
  }
  result
}

`

export const ProductIdResultDeepNestingFragment = `fragment ProductIdResultDeepNesting on ProductIdResult {
  productId
}

`

export const ProductImageDeepNestingFragment = `fragment ProductImageDeepNesting on ProductImage {
  createdAt
  deletedAt
  height
  id
  mineType
  name
  path
  position
  productId
  updatedAt
  width
}

`

export const ProductInfoDeepNestingFragment = `fragment ProductInfoDeepNesting on ProductInfo {
  description
  images
  name
  weight
}

`

export const ProductListDeepNestingFragment = `fragment ProductListDeepNesting on ProductList {
  items {
    ...ProductDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const ProductLocationInventoryPricingDeepNestingFragment = `fragment ProductLocationInventoryPricingDeepNesting on ProductLocationInventoryPricing {
  costPrice
  costPriceExclTax
  id
  location {
    ...LocationDeepNesting
  }
  locationId
  productId
  retailPrice
  rrPrice
  serialNumbers
  stockOnHand
  taxGroupId
  taxRatePercent
  tradePrice
}

`

export const ProductNotFoundResultDeepNestingFragment = `fragment ProductNotFoundResultDeepNesting on ProductNotFoundResult {
  result
}

`

export const ProductOptionDeepNestingFragment = `fragment ProductOptionDeepNesting on ProductOption {
  createdAt
  id
  name
  position
  updatedAt
  values
}

`

export const ProductOptionGroupTemplateDeepNestingFragment = `fragment ProductOptionGroupTemplateDeepNesting on ProductOptionGroupTemplate {
  createdAt
  id
  identifier
  name
  options {
    ...ProductOptionDeepNesting
  }
  updatedAt
}

`

export const ProductOptionGroupTemplateListDeepNestingFragment = `fragment ProductOptionGroupTemplateListDeepNesting on ProductOptionGroupTemplateList {
  items {
    ...ProductOptionGroupTemplateDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const ReceiptDeepNestingFragment = `fragment ReceiptDeepNesting on Receipt {
  createdAt
  defaultTemplateType
  deletedAt
  enabled
  id
  name
  settings {
    ...ReceiptSettingsDeepNesting
  }
  updatedAt
}

`

export const ReceiptListDeepNestingFragment = `fragment ReceiptListDeepNesting on ReceiptList {
  items {
    ...ReceiptDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const ReceiptSettingsDeepNestingFragment = `fragment ReceiptSettingsDeepNesting on ReceiptSettings {
  displayCustomerDetails
  footer
  header
  logoURL
  name
}

`

export const ReceiptTemplateTypeDeepNestingFragment = `fragment ReceiptTemplateTypeDeepNesting on ReceiptTemplateType {
  createdAt
  css
  deletedAt
  html
  id
  updatedAt
  version
}

`

export const RemovalAiDetailsDeepNestingFragment = `fragment RemovalAiDetailsDeepNesting on RemovalAiDetails {
  apiKey
}

`

export const RemovalBgDetailsDeepNestingFragment = `fragment RemovalBgDetailsDeepNesting on RemovalBgDetails {
  apiKey
}

`

export const RemoveBackgroundBase64ResultDeepNestingFragment = `fragment RemoveBackgroundBase64ResultDeepNesting on RemoveBackgroundBase64Result {
  base64
}

`

export const RetailerExportCustomerDeepNestingFragment = `fragment RetailerExportCustomerDeepNesting on RetailerExportCustomer {
  Email
  FirstName
  Phone
  Surname
}

`

export const RetailerExportPaymentDeepNestingFragment = `fragment RetailerExportPaymentDeepNesting on RetailerExportPayment {
  Amount
  PayCode
  Tendered
}

`

export const RetailerExportSaleDeepNestingFragment = `fragment RetailerExportSaleDeepNesting on RetailerExportSale {
  CustID
  Customer {
    ...RetailerExportCustomerDeepNesting
  }
  FreightCharge
  FreightTax
  LocationKey
  OrderNumber
  Payments {
    ...RetailerExportPaymentDeepNesting
  }
  SaleAmount
  SaleCust
  SaleDate
  SaleDeposit
  SaleDiscount
  SaleId
  SaleItems {
    ...RetailerExportSaleItemDeepNesting
  }
  SaleRound
  SaleStaff
  SaleTax
  SaleTerm
  SaleTime
  SaleType
}

`

export const RetailerExportSaleItemDeepNestingFragment = `fragment RetailerExportSaleItemDeepNesting on RetailerExportSaleItem {
  SerialNumber
  TotalPointsEarned
  TotalPointsUsed
  TxnDiscountType
  TxnExtendedAmount
  TxnExtendedDiscount
  TxnExtendedTax
  TxnItem
  TxnQty
  TxnStock
  TxnTotal
  TxnTotalCost
  TxnUnitAmount
  TxnUnitCost
  TxnUnitDiscount
  TxnUnitTax
}

`

export const RetailerPayTypeDeepNestingFragment = `fragment RetailerPayTypeDeepNesting on RetailerPayType {
  createdAt
  deletedAt
  id
  payCode
  payDescription
  payOver
  payType
  updatedAt
}

`

export const RetailerPayTypeListDeepNestingFragment = `fragment RetailerPayTypeListDeepNesting on RetailerPayTypeList {
  items {
    ...RetailerPayTypeDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const RoleDeepNestingFragment = `fragment RoleDeepNesting on Role {
  code
  createdAt
  id
  permissions
  updatedAt
}

`

export const SaleDeepNestingFragment = `fragment SaleDeepNesting on Sale {
  active
  balance
  code
  completedAt
  createdAt
  customer {
    ...CustomerDeepNesting
  }
  customerId
  deletedAt
  id
  invoiceNumber
  invoiceSequence
  isReturn
  lineItems {
    ...SaleLineItemDeepNesting
  }
  location {
    ...LocationDeepNesting
  }
  locationId
  metadata
  note
  payments {
    ...PaymentDeepNesting
  }
  purchaseOrderNumber
  quoteAccepted
  receiptConfirmationUrl
  receiptConfirmed
  receiptId
  returnForId
  saleDate
  saleType
  source
  staff {
    ...StaffDeepNesting
  }
  staffId
  state
  terminal {
    ...TerminalDeepNesting
  }
  terminalId
  totalAdjustmentPercentSaving
  totalAdjustments
  totalCost
  totalPrice
  totalTax
  updatedAt
}

`

export const SaleAddressDeepNestingFragment = `fragment SaleAddressDeepNesting on SaleAddress {
  city
  company
  country
  countryCode
  fullName
  phoneNumber
  postCode
  state
  street1
  street2
}

`

export const SaleAdjustmentDeepNestingFragment = `fragment SaleAdjustmentDeepNesting on SaleAdjustment {
  amount
  createdAt
  id
  metadata
  type
  unitLoyaltyPointsUsed
  updatedAt
}

`

export const SaleHistoryEntryDeepNestingFragment = `fragment SaleHistoryEntryDeepNesting on SaleHistoryEntry {
  createdAt
  deletedAt
  id
  type
  updatedAt
  user {
    ...UserDeepNesting
  }
  userId
}

`

export const SaleLineItemDeepNestingFragment = `fragment SaleLineItemDeepNesting on SaleLineItem {
  adjustments {
    ...SaleAdjustmentDeepNesting
  }
  basePrice
  basePriceInclTax
  billingAddress {
    ...SaleAddressDeepNesting
  }
  cost
  createdAt
  customerNote
  deletedAt
  id
  loyaltyPointEarned
  metadata
  name
  price
  priceInclTax
  product {
    ...ProductDeepNesting
  }
  productId
  quantity
  saleId
  serialNumber
  shippingAddress {
    ...SaleAddressDeepNesting
  }
  stockCode
  stockNumber
  tax
  taxRatePercent
  totalAdjustments
  totalBasePrice
  totalBasePriceInclTax
  totalCost
  totalPrice
  totalPriceInclusiveTax
  totalTax
  updatedAt
}

`

export const SaleLineItemEntryDataDeepNestingFragment = `fragment SaleLineItemEntryDataDeepNesting on SaleLineItemEntryData {
  adjustments {
    ...SaleAdjustmentDeepNesting
  }
  basePrice
  basePriceInclTax
  cost
  loyaltyPointEarned
  metadata
  price
  priceInclTax
  productName
  quantity
  serialNumber
  stockCode
  stockNumber
  tax
  taxRatePercent
  totalAdjustments
  totalBasePrice
  totalBasePriceInclTax
  totalCost
  totalPrice
  totalPriceInclusiveTax
  totalTax
}

`

export const SaleListDeepNestingFragment = `fragment SaleListDeepNesting on SaleList {
  items {
    ...SaleDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const SaleModifiedCustomerAddedEntryDataDeepNestingFragment = `fragment SaleModifiedCustomerAddedEntryDataDeepNesting on SaleModifiedCustomerAddedEntryData {
  customerId
  emailAddress
}

`

export const SaleModifiedEntryDataDeepNestingFragment = `fragment SaleModifiedEntryDataDeepNesting on SaleModifiedEntryData {
  input
  oldEntity
}

`

export const SaleNoteEntryDataDeepNestingFragment = `fragment SaleNoteEntryDataDeepNesting on SaleNoteEntryData {
  note
}

`

export const SaleStateTransitionErrorDeepNestingFragment = `fragment SaleStateTransitionErrorDeepNesting on SaleStateTransitionError {
  errorCode
  fromState
  message
  toState
  transitionError
}

`

export const SaleStatusTransitionDataDeepNestingFragment = `fragment SaleStatusTransitionDataDeepNesting on SaleStatusTransitionData {
  from
  to
}

`

export const SaleSummaryProductResultDeepNestingFragment = `fragment SaleSummaryProductResultDeepNesting on SaleSummaryProductResult {
  completedDate
  count
  product {
    ...ProductDeepNesting
  }
  productId
  saleId
  state
  total
}

`

export const SaleWithHistoryEntriesDeepNestingFragment = `fragment SaleWithHistoryEntriesDeepNesting on SaleWithHistoryEntries {
  historyEntries {
    ...SaleHistoryEntryDeepNesting
  }
  sale {
    ...SaleDeepNesting
  }
}

`

export const SaleWithHistoryEntriesListDeepNestingFragment = `fragment SaleWithHistoryEntriesListDeepNesting on SaleWithHistoryEntriesList {
  items {
    ...SaleWithHistoryEntriesDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const SearchResponseDeepNestingFragment = `fragment SearchResponseDeepNesting on SearchResponse {
  items {
    ...SearchResultItemDeepNesting
  }
}

`

export const SearchResultItemDeepNestingFragment = `fragment SearchResultItemDeepNesting on SearchResultItem {
  id
  type
}

`

export const SelectedOptionDeepNestingFragment = `fragment SelectedOptionDeepNesting on SelectedOption {
  name
  value
}

`

export const SelfCheckoutLocationSettingsDeepNestingFragment = `fragment SelfCheckoutLocationSettingsDeepNesting on SelfCheckoutLocationSettings {
  checkoutSuccessHeading
  enabled
  location {
    ...LocationDeepNesting
  }
  locationId
  offlineCheckoutEnabled
  onlineCheckoutEnabled
  payAtCounterHeading
  payAtCounterSubheading
  terminal {
    ...TerminalDeepNesting
  }
  terminalId
}

`

export const SelfCheckoutSettingsDeepNestingFragment = `fragment SelfCheckoutSettingsDeepNesting on SelfCheckoutSettings {
  enabled
  locationSettings {
    ...SelfCheckoutLocationSettingsDeepNesting
  }
  logoURL
  paymentMethod {
    ...PaymentMethodDeepNesting
  }
  paymentMethodId
}

`

export const SerialNumberDeepNestingFragment = `fragment SerialNumberDeepNesting on SerialNumber {
  createdAt
  deletedAt
  id
  productLocationInventoryPricingId
  updatedAt
  value
}

`

export const SerialNumberListDeepNestingFragment = `fragment SerialNumberListDeepNesting on SerialNumberList {
  items {
    ...SerialNumberDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const ServerConfigDeepNestingFragment = `fragment ServerConfigDeepNesting on ServerConfig {
  availablePayTypes {
    ...PayTypeDeepNesting
  }
  internalPayTypes {
    ...PayTypeDeepNesting
  }
  receiptTemplateTypes {
    ...ReceiptTemplateTypeDeepNesting
  }
}

`

export const StaffDeepNestingFragment = `fragment StaffDeepNesting on Staff {
  createdAt
  deletedAt
  emailAddress
  enabled
  fullName
  hasUserAccount
  id
  initials
  terminalIds
  terminals {
    ...TerminalDeepNesting
  }
  updatedAt
  user {
    ...UserV2DeepNesting
  }
  userId
  username
}

`

export const StaffListDeepNestingFragment = `fragment StaffListDeepNesting on StaffList {
  items {
    ...StaffDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const StockCountDeepNestingFragment = `fragment StockCountDeepNesting on StockCount {
  createdAt
  deletedAt
  id
  location {
    ...LocationDeepNesting
  }
  locationId
  name
  retailerStocktakeId
  source
  status
  updatedAt
}

`

export const StockCountItemDeepNestingFragment = `fragment StockCountItemDeepNesting on StockCountItem {
  counted
  countedAt
  createdAt
  deletedAt
  id
  lineId
  status
  updatedAt
}

`

export const StockCountLineDeepNestingFragment = `fragment StockCountLineDeepNesting on StockCountLine {
  barcode
  createdAt
  deletedAt
  expected
  id
  initialExpected
  items {
    ...StockCountItemDeepNesting
  }
  product {
    ...ProductDeepNesting
  }
  productId
  stockCountId
  updatedAt
}

`

export const StockCountLineListDeepNestingFragment = `fragment StockCountLineListDeepNesting on StockCountLineList {
  items {
    ...StockCountLineDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const StockCountListDeepNestingFragment = `fragment StockCountListDeepNesting on StockCountList {
  items {
    ...StockCountDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const StockNumberNotFoundErrorDeepNestingFragment = `fragment StockNumberNotFoundErrorDeepNesting on StockNumberNotFoundError {
  errorCode
  message
}

`

export const StockOrderDeepNestingFragment = `fragment StockOrderDeepNesting on StockOrder {
  completedAt
  createdAt
  deletedAt
  formattedOrderNumber
  id
  items {
    ...StockOrderItemDeepNesting
  }
  location {
    ...LocationDeepNesting
  }
  locationId
  orderCreatedDate
  orderNumber
  status
  totalQuantity
  updatedAt
}

`

export const StockOrderItemDeepNestingFragment = `fragment StockOrderItemDeepNesting on StockOrderItem {
  barcode
  createdAt
  deletedAt
  id
  product {
    ...ProductDeepNesting
  }
  productId
  quantity
  stockOrderId
  updatedAt
}

`

export const StockOrderListDeepNestingFragment = `fragment StockOrderListDeepNesting on StockOrderList {
  items {
    ...StockOrderDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const StocktakeItemDeepNestingFragment = `fragment StocktakeItemDeepNesting on StocktakeItem {
  cost
  counted
  createdAt
  deletedAt
  expected
  id
  initialExpected
  manual
  product
  status
  stockCode
  stocktakeId
  updatedAt
}

`

export const StocktakeItemListDeepNestingFragment = `fragment StocktakeItemListDeepNesting on StocktakeItemList {
  items {
    ...StocktakeItemDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const SuccessDeepNestingFragment = `fragment SuccessDeepNesting on Success {
  success
}

`

export const SupplierDeepNestingFragment = `fragment SupplierDeepNesting on Supplier {
  active
  bannerImageUrl
  createdAt
  deletedAt
  description
  id
  name
  suppCode
  updatedAt
}

`

export const SupplierListDeepNestingFragment = `fragment SupplierListDeepNesting on SupplierList {
  items {
    ...SupplierDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const SwapLayoutNodesResultDeepNestingFragment = `fragment SwapLayoutNodesResultDeepNesting on SwapLayoutNodesResult {
  firstNode {
    ...LayoutNodeDeepNesting
  }
  secondNode {
    ...LayoutNodeDeepNesting
  }
}

`

export const TaxGroupDeepNestingFragment = `fragment TaxGroupDeepNesting on TaxGroup {
  active
  arrivePercentage
  createdAt
  deletedAt
  id
  name
  salePercentage
  shortName
  taxGroupID
  updatedAt
}

`

export const TaxGroupListDeepNestingFragment = `fragment TaxGroupListDeepNesting on TaxGroupList {
  items {
    ...TaxGroupDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const TenantDeepNestingFragment = `fragment TenantDeepNesting on Tenant {
  identifier
}

`

export const TerminalDeepNestingFragment = `fragment TerminalDeepNesting on Terminal {
  createdAt
  defaultReceiptTemplateId
  deletedAt
  enabled
  id
  invoiceSequence
  name
  openTerminalClosure {
    ...TerminalClosureDeepNesting
  }
  openTerminalClosureId
  paymentMethodIds
  printReceiptOnCheckout
  salePrefix
  saleSufix
  terminalCloseTime
  terminalOpenTime
  updatedAt
}

`

export const TerminalClosureDeepNestingFragment = `fragment TerminalClosureDeepNesting on TerminalClosure {
  cashMovements {
    ...CashMovementDeepNesting
  }
  countedPaymentsSummary {
    ...TerminalClosurePaymentMethodSummaryDeepNesting
  }
  createdAt
  deletedAt
  estimatedCashBalance
  id
  isClosed
  payments {
    ...TerminalClosurePaymentTotalDeepNesting
  }
  saleSummary {
    ...TerminalClosureSalesSummaryDeepNesting
  }
  terminalCloseDate
  terminalId
  terminalName
  terminalOpenDate
  updatedAt
}

`

export const TerminalClosureListDeepNestingFragment = `fragment TerminalClosureListDeepNesting on TerminalClosureList {
  items {
    ...TerminalClosureDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const TerminalClosurePaymentMethodSummaryDeepNestingFragment = `fragment TerminalClosurePaymentMethodSummaryDeepNesting on TerminalClosurePaymentMethodSummary {
  difference
  expected
  payTypeId
  paymentMethodId
  paymentTypeName
  total
}

`

export const TerminalClosurePaymentTotalDeepNestingFragment = `fragment TerminalClosurePaymentTotalDeepNesting on TerminalClosurePaymentTotal {
  counted
  paymentMethodId
}

`

export const TerminalClosurePaymentsSummaryDeepNestingFragment = `fragment TerminalClosurePaymentsSummaryDeepNesting on TerminalClosurePaymentsSummary {
  payments {
    ...TerminalClosurePaymentMethodSummaryDeepNesting
  }
  terminalClosureId
  terminalId
  terminalOpenTime
}

`

export const TerminalClosureSalesSummaryDeepNestingFragment = `fragment TerminalClosureSalesSummaryDeepNesting on TerminalClosureSalesSummary {
  totalDiscounts
  totalInvoices
  totalLaybys
  totalPayments
  totalRefunds
  totalSales
  totalTaxes
}

`

export const TerminalListDeepNestingFragment = `fragment TerminalListDeepNesting on TerminalList {
  items {
    ...TerminalDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const UserDeepNestingFragment = `fragment UserDeepNesting on User {
  displayName
  emailAddress
  id
  mobileNumber
  numberAvailableTerminals
  stripeAccountId
  stripeOnboardingComplete
  userAccountType
  username
}

`

export const UserV2DeepNestingFragment = `fragment UserV2DeepNesting on UserV2 {
  authenticationMethods {
    ...AuthenticationMethodDeepNesting
  }
  createdAt
  enabled2fa
  id
  identifier
  lastLogin
  mfaMethods {
    ...MfaMethodDeepNesting
  }
  roles {
    ...RoleDeepNesting
  }
  updatedAt
  verified
}

`

export const VendorDeepNestingFragment = `fragment VendorDeepNesting on Vendor {
  active
  id
  numberAvailableTerminals
  stripeOnboardingComplete
  talinkId
  talinkUsername
  userAccountType
}

`

