import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, Paper, TextField } from "@mui/material";
import { Page } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { SearchContext } from "../../../../utilities/search/context";
import {
  useSearch,
  useSearchQueryProvider,
} from "../../../../utilities/search/hooks";
import { useModal } from "../../../../utilities/use-modal";
import { CreateProductSheet } from "../../components";
import { AllProductsTabView } from "./components";
import { InactiveProductsTabView } from "./components/InactiveProductsTabView";
import { useAllProductsController } from "./utils";

export interface ProductListPageProps {
  addProduct?: boolean;
}

const ProductListPageInner = ({ addProduct = false }: ProductListPageProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const modal = useModal(addProduct);

  const search = useSearchQueryProvider();

  const allProductsController = useAllProductsController();

  const toolbarMarkup = (
    <Box>
      <TextField
        value={search.query}
        onChange={(e) => search.setQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search products"
        fullWidth
        className="CardSearchInput"
        variant="standard"
      ></TextField>
    </Box>
  );

  function handleOpenAddProductSheet() {
    // Get the current pathname and query parameters
    const { pathname, search } = location;

    // Create the new URL by appending "/add" to the current pathname
    const newUrl = `${pathname}/add${search}`;

    // Use the `history` object to navigate to the new URL
    navigate(newUrl, {
      state: { addProduct: true },
    });
    modal.onOpen();
  }

  function handleAddedProductSuccess() {
    handleAddProductSheetCloseCallback();
    allProductsController.paginatedList.refetch();
  }

  function handleAddProductSheetCloseCallback() {
    const { search } = location;

    navigate(`/products${search}`, {
      replace: true,
      state: { addProduct: false },
    });
  }

  function handleTabSelect(index: number, last: number, event: Event) {
    if (index === 0 && last !== index) {
      allProductsController.paginatedList.refetch();
    }
  }

  return (
    <Page title="Products" titleDescription="Manage your products.">
      <Paper variant="outlined">
        {toolbarMarkup}
        <Tabs onSelect={handleTabSelect}>
          <TabList>
            <Tab>All Products</Tab>
            <Tab>Inactive Products</Tab>
          </TabList>
          <TabPanel>
            <AllProductsTabView {...{ allProductsController }} />
          </TabPanel>
          <TabPanel>
            <InactiveProductsTabView />
          </TabPanel>
        </Tabs>
      </Paper>
      <CreateProductSheet
        {...modal}
        onSubmitSuccess={handleAddedProductSuccess}
        onSheetClose={handleAddProductSheetCloseCallback}
      />
    </Page>
  );
};

export const ProductListPage = (props: ProductListPageProps) => {
  const search = useSearch();

  return (
    <SearchContext.Provider value={search}>
      <ProductListPageInner {...props} />
    </SearchContext.Provider>
  );
};
