import { CreateProductInput } from "@towersystems/roam-common/lib/generated-types";
import {
  ProductFormValues,
  ProductLocationPricingValues,
} from "../../modules/products";

export function productFormValuesToInput(
  formValues: ProductFormValues
): CreateProductInput {
  return {
    active: formValues.active,
    categoryId: formValues.categoryId,
    departmentId: formValues.departmentId,
    description: formValues.description,
    locationInventoryPricing: locationInventoryPricingToInput(
      formValues.locationInventoryPricing
    ),
    loyaltyPointEarnValue: undefined,
    loyaltyPointRedeemValue: undefined,
    name: formValues.name,
    priceLookup: formValues.priceLookup,
    serialNumbersArr: formValues.serialNumbersArr,
    serialNumbersSale: formValues.serialNumbersSale,
    stockCode: formValues.stockCode,
    stockNumber: formValues.stockNumber,
    supplierId: formValues.supplierId,
    supplierStockCode: formValues.supplierStockCode,
    tags: formValues.tags,
    variantParentId: undefined,
    webPrice: undefined,
    weight: formValues.weight,
    UOM: formValues.UOM,
  };
}

export function locationInventoryPricingToInput(
  locationInventoryPricing?: ProductLocationPricingValues[]
) {
  return locationInventoryPricing?.map((locationValues) =>
    productLocationPricingValuesToInput(locationValues)
  );
}

function productLocationPricingValuesToInput(
  locationFormValues: ProductLocationPricingValues
) {
  return {
    id: locationFormValues.id,
    retailerLocationKey: locationFormValues.retailerLocationKey,
    stockOnHand: locationFormValues.stockOnHand,
    costPrice: locationFormValues.costPriceEx,
    retailPrice: locationFormValues.retailPrice,
    rrPrice: locationFormValues.rrPrice,
    taxGroupId: locationFormValues.taxGroupId,
    serialNumbers: locationFormValues.serialNumbers,
  };
}
