import { Box, FormControl, InputLabel } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React, { useId, useRef, useState } from "react";
import { useIsDarkMode } from "../../utilities/theme";

export interface RichTextFieldProps {
  label?: string;
  value?: string;
  defaultValue?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const RichTextField = ({
  value,
  label,
  onChange,
  defaultValue,
}: RichTextFieldProps) => {
  const id = useId();

  const isDarkMode = useIsDarkMode();

  // this is to stop the form from being dirty on every input
  const [initialContent, setInitialContent] = useState(defaultValue);
  const editorRef = useRef(null);

  let additionalConfig = isDarkMode
    ? {
        skin: "oxide-dark",
        content_css: "dark",
      }
    : {};

  const handleChange = (event: any) => {
    onChange && onChange(event);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <InputLabel sx={{ position: "absolute", left: 0, top: 0 }}>
        {label}
      </InputLabel>
      <Box sx={{ mt: "1.6em" }}>
        <Editor
          key={`${id}-${isDarkMode ? "dark" : "light"}`}
          apiKey="6lmz7shpnivln3qi1fxuly9ge1fw5vodvcu221uigjek3bre"
          onEditorChange={handleChange}
          onInit={(evt: any, editor: any) => {
            setInitialContent(editor.getContent());
            editorRef.current = editor;
          }}
          value={value}
          initialValue={initialContent}
          init={{
            height: 300,
            menubar: true,
            force_br_newlines: false,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "fullscreen",
              "insertdatetime",
              "table",
              "help",
              "wordcount",
            ],
            selector: "textarea" as any, // types are wrong
            toolbar:
              " undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            ...additionalConfig,
          }}
        />
      </Box>
    </Box>
  );
};
