import { defer } from "react-router-dom";
import { RouteObjectWithPermissions } from "../../routes";
import { queryClient } from "../../utilities/query-client";
import { ListSuppliersPage, ViewSupplierPage } from "./pages";
import { QUERY_SUPPLIER } from "./utils/constants";

export const routes: RouteObjectWithPermissions[] = [
  {
    path: "suppliers",
    element: <ListSuppliersPage />,
    permissions: ["ManageSuppliers"],
  },
  {
    path: "suppliers/view/:id",
    element: <ViewSupplierPage />,
    permissions: ["ManageSuppliers"],
    loader: async ({ params }) => {
      const f = await queryClient({
        query: QUERY_SUPPLIER,
        variables: { id: params.id },
      })
        .then((res) => res.data.supplier)
        .catch((err) => {
          throw new Response("Not Found", { status: 404 });
        });

      return defer({
        data: Promise.all([f]),
      });
    },
  },
];
