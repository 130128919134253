import { gql } from "@apollo/client";
import {
  ProductFoundResultDeepNestingFragment,
  ProductInfoDeepNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const QUERY_LOOKUP_BARCODE = gql`
  ${ProductInfoDeepNestingFragment}
  ${ProductFoundResultDeepNestingFragment}
  query LookupBarcode($input: BarcodeLookupInput!) {
    lookupBarcode(input: $input) {
      ... on ProductFoundResult {
        ...ProductFoundResultDeepNesting
      }
      ... on ProductNotFoundResult {
        result
      }
    }
  }
`;
