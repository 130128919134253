import { Box, Divider, Typography } from "@mui/material";
import { Page, Stack, VerifiedMfaListItem } from "../../../../components";
import { useActiveUserProvider } from "../../../../utilities/active-user";
import { SettingCard } from "../../../settings/components";
import { SetupNative2faModal } from "../../components";
import { Remove2faContext, SetupNative2faContext } from "../../utils";
import { useRemove2fa, useSetupNative2fa } from "../../utils/2fa/hooks";
import { Button } from "../../../../components/Button";
import { MfaCodeStrategy } from "@towersystems/roam-common/lib/generated-types";
import { Remove2faModal } from "../../components/Remove2faModal";
import { userIsAdmin } from "../../../../utilities/user-is-admin";
import { UpdateCredentialsSettingsCard } from "./components";

export interface UpdateMePageProps {}

export const UpdateMePage = ({}: UpdateMePageProps) => {
  const { activeUser } = useActiveUserProvider();
  const setupNative2faContext = useSetupNative2fa();
  const remove2faContext = useRemove2fa();

  const mfaEnabled = Boolean(activeUser.enabled2fa);

  const securityTitle = mfaEnabled
    ? "Account Security"
    : "Setup Account Security";

  const securityDescription = mfaEnabled
    ? "Manage your 2fa."
    : "Setup your 2fa";

  const activeMfaMarkup = mfaEnabled ? (
    <SettingCard
      wrapped
      title={securityTitle}
      description={securityDescription}
    >
      <Typography variant="h6" fontWeight={"bold"}>
        Your setup MFA methods:
      </Typography>
      <Stack direction={"column"} spacing={3}>
        {activeUser.mfaMethods
          .filter((i) => i.verified)
          .map((i) => {
            return (
              <VerifiedMfaListItem
                onClick={() =>
                  remove2faContext.modal.onOpen(i.strategy as MfaCodeStrategy)
                }
                key={i.id}
                mfaMethod={i}
              />
            );
          })}
        <Box>
          <Button onClick={setupNative2faContext.modal.onOpen}>
            Setup new MFA method
          </Button>
        </Box>
      </Stack>
    </SettingCard>
  ) : (
    <SettingCard
      wrapped
      title={securityTitle}
      description={securityDescription}
    >
      <Typography variant="h6" fontWeight={"bold"}>
        Setup MFA to secure your account:
      </Typography>
      <Stack direction={"column"} spacing={3}>
        <Box>
          <Button onClick={setupNative2faContext.modal.onOpen}>
            Setup new MFA method
          </Button>
        </Box>
      </Stack>
    </SettingCard>
  );

  const credentialsSectionMarkup =
    false === userIsAdmin(activeUser) ? (
      <UpdateCredentialsSettingsCard user={activeUser} />
    ) : undefined;

  return (
    <SetupNative2faContext.Provider value={setupNative2faContext}>
      <Remove2faContext.Provider value={remove2faContext}>
        <Page
          title="My Details"
          titleDescription="Manage your account settings."
        >
          <Stack spacing={5} divider={<Divider />}>
            {activeMfaMarkup}
            {credentialsSectionMarkup}
          </Stack>
        </Page>
        <SetupNative2faModal />
        <Remove2faModal />
      </Remove2faContext.Provider>
    </SetupNative2faContext.Provider>
  );
};
