import { defer } from "react-router-dom";
import { RouteObjectWithPermissions } from "../../routes";
import { queryClient } from "../../utilities/query-client";
import { QUERY_LOCATIONS_LIST } from "../settings/utils/locations";
import { ProductListPage, ProductsRootPage } from "./pages";
import { EditProductPage } from "./pages/EditProductPage";
import { EditProductDetailsSubPage } from "./pages/EditProductPage/pages/EditProductDetailsSubPage";
import { EditProductVariantsSubPage } from "./pages/EditProductPage/pages/EditProductVariantsSubPage/EditProductVariantsSubPage";
import { ViewProductPage } from "./pages/ViewProductPage";
import { QUERY_PRODUCT, QUERY_TAX_GROUP_LIST } from "./utils";
import { EditProductPricingSubPage } from "./pages/EditProductPage/pages/EditProductPricingSubPage";
import { EditProductInventorySubPage } from "./pages/EditProductPage/pages/EditProductInventorySubPage";
import { EditProductSerialNumbersSubPage } from "./pages/EditProductPage/pages/EditProductSerialNumbersSubPage";

export const routes: RouteObjectWithPermissions[] = [
  {
    path: "products",
    element: <ProductsRootPage />,
    permissions: ["ManageProducts"],
    loader: async () => {
      const taxGroups = queryClient({
        query: QUERY_TAX_GROUP_LIST,
        variables: {
          limit: 999,
          sort: { name: "ASC" },
        },
      }).then((res) => res.data.taxGroups.items);

      const locations = queryClient({
        query: QUERY_LOCATIONS_LIST,
        variables: {
          limit: 999,
          sort: { name: "ASC" },
        },
      }).then((res) => res.data.locations.items);

      return defer({
        data: Promise.all([taxGroups, locations]),
      });
    },
    children: [
      {
        path: "",
        element: <ProductListPage />,
      },
      {
        path: "view/:id",
        element: <ViewProductPage />,
        loader: async ({ params }) => {
          const f = queryClient({
            query: QUERY_PRODUCT,
            variables: { id: params.id },
          })
            .then((res) => res.data.product)
            .catch((err) => {
              throw new Response("Not Found", { status: 404 });
            });

          return defer({
            data: Promise.all([f]),
          });
        },
      },
      /*
      {
        path: "view/:id/edit",
        element: <EditProductPage />,
        children: [
          {
            path: "details",
            element: <EditProductDetailsSubPage />,
          },
          {
            path: "variants",
            element: <EditProductVariantsSubPage />,
          },
          {
            path: "pricing",
            element: <EditProductPricingSubPage />,
          },
          {
            path: "inventory",
            element: <EditProductInventorySubPage />,
          },
          {
            path: "serial-numbers",
            element: <EditProductSerialNumbersSubPage />,
          },
        ],
      },
      {
        path: "add",
        element: <ProductListPage addProduct={true} />,
      },

      */
    ],
  },
];
