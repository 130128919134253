const tintColorLight = "#F5E5DB";
const tintColorDark = "#000";
const primaryColor = "#F37121";

export const lightColors = {
  cartCover: "#dadbdd",
  primary: primaryColor,
  text: "#262625",
  textOnPrimary: "#fff",
  iconOnPrimary: "hsl(210, 76%, 90%)",
  iconOnSecondary: "hsl(0, 0%, 58%)",
  background: "#fff",
  tint: tintColorLight,
  iconSubdued: "#9d9c9c",
  surfaceSelected: "rgba(10, 132, 255, 0.08)",
  surfaceProminent: "rgb(218, 219, 221)",
  tabIconDefault: "#ccc",
  tabIconSelected: tintColorLight,
  neutralButton: "#dadbdd",
  input: "rgba(255,255,255,0.2)",
  inputSearch: "#dadbdd",
  inputBorderColor: "#dadbdd",
  surface: "#f0f0f0",
  surface2: "#ccc",
  inputFocused: "#fff",
  elevatedSurface1: "#f0f0f0",
  navbar: "#fff",
  borderColor: "#dadbdd",
  destructive: "#ff3b30",
  action: `hsl(210, 76%, 58%)`,
  quickKeyText: "#555555",
  quickKeyAction: `hsl(210, 76%, 58%)`,
  quickKeyActionIcon: "#fff",
  quickKeyActionText: "#94B5FB",
  quickKeyDestruction: "#ff3b30",
  cardBackgroundColor: "#ff3b30",
  modifierAction: "#9f842b",
  surcharge: "#9f842b",
  quickKeyModifer: "#DFB93C",
  quickKeyBackground: "#bfbfbf",
  statusClosed: "#b9e2d1",
  statusVoid: "#f1c8c6",
  statusOpen: "#d8d9db",
  statusSaved: "#9cdce6",
  statusCancelled: "#f1c8c6",
  quickKeyModiferText: "#9f842b",
  tyroRefund: "#ff542c",
  quickKeyDestructionText: "#FFA09B",
  success: "#00b300",
  tyro: "#2b32b2",
  backgroundWarning: "#fcf0d4",
  borderWarning: "#b77e0b",
  textWarning: "#b77e0b",
  backgroundInfo: "hsl(210, 76%, 85%)",
  borderInfo: "hsl(210, 76%, 58%)",
  textInfo: primaryColor,
  quickKeyLightgreenBackground: "#2f6246",
  quickKeyLightgreenText: "   #7dd49f",
};

export const darkColors = {
  ...lightColors,
  cartCover: "#202224",
  neutralButton: "#454948",
  text: "#98a3ad",
  quickKeyText: "#98a3ad",
  background: "#000",
  tint: tintColorDark,
  tabIconDefault: "#ccc",
  tabIconSelected: tintColorDark,
  surfaceSelected: "rgba(10, 132, 255, 0.08)",
  surface: "#151718",
  surface2: "#151718",
  elevatedSurface1: "#202224",
  navbar: "#363a3d",
  input: "#000",
  inputFocused: "#000",
  inputSearch: "#000",
  borderColor: "#2c2c2c",
  inputBorderColor: "#2c2c2c",
  tyro: "#000",
  destructive: "#ff3b30",
  quickKeyBackground: "#454948",
  quickKeyDestruction: "#A13933",
  quickKeyDestructionText: "#FFA09B",
  quickKeyModifer: "#9D863A",
  quickKeyModiferText: "#FDD963",
  quickKeyAction: "#144372",
  quickKeyActionText: "#94B5FB",
  cardBackgroundColor: "transaprent",
  quickKeyLightgreenBackground: "#2f6246",
};
