import {
  Category,
  UpdateCategoryInput,
} from "@towersystems/roam-common/lib/generated-types";
import { toTitleCase } from "@towersystems/roam-common/lib/shared-utils";
import React from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { ProductImageThumbnail } from "../../../../components/ProductImageThumbnail";
import { useModal } from "../../../../utilities/use-modal";
import { Page, Stack } from "../../../../components";
import { UploadImageDialogue } from "../../../../components/UploadImageDialogue";
import { useMutationUpdateCategory } from "../../../../utilities/categories";
import { Button } from "../../../../components/Button";
import { CardDetailList } from "../../../../components/CardDetailList";
import { MetaDetailsCard } from "../../../../components/MetaDetailsCard";

export const ViewCategoryPage = () => {
  const { data }: any = useLoaderData();
  const navigate = useNavigate();
  const imageModal = useModal();
  const { mutation: updateCategoryMutation } = useMutationUpdateCategory();

  return (
    <React.Suspense fallback={<div>loading</div>}>
      <Await resolve={data}>
        {([category]: [Category]) => {
          if (!category) {
            return null;
          }
          const handleUpdateDeparmtent = async (
            input: Partial<UpdateCategoryInput>
          ) => {
            return updateCategoryMutation({
              id: category.id,
              ...input,
            })
              .then((res) => {
                navigate(".", { replace: true });
                return true;
              })
              .catch((err) => false);
          };

          const handleOnDeleteImage = async () => {
            return handleUpdateDeparmtent({
              bannerImage: null,
            });
          };

          const handleOnUploadImage = async (file: File) => {
            return updateCategoryMutation({
              id: category.id,
              bannerImage: file,
            })
              .then((res) => {
                navigate(".", { replace: true });
                return true;
              })
              .catch((err) => false);
          };

          let buttons = [];
          if (category.active) {
            buttons.push(
              <Button
                variant="posTile"
                color="warning"
                onClick={() =>
                  handleUpdateDeparmtent({
                    active: false,
                  })
                }
              >
                Disable
              </Button>
            );
          } else {
            buttons.push(
              <Button
                variant="posTile"
                color="primary"
                onClick={() =>
                  handleUpdateDeparmtent({
                    active: true,
                  })
                }
              >
                Enable
              </Button>
            );
          }

          const title = toTitleCase(category.name);

          const subtitle = `${category.numberOfProducts} products`;

          return (
            <Page
              title={title}
              titleDescription={subtitle}
              goBack={{
                onAction: () =>
                  navigate(`/departments/view/${category.departmentId}`),
              }}
              adornment={
                <ProductImageThumbnail
                  variant={category.bannerImageUrl ? "default" : "upload"}
                  size="large"
                  src={category.bannerImageUrl}
                  onClick={imageModal.onOpen}
                />
              }
            >
              <Stack spacing={8}>
                {buttons.length >= 1 ? (
                  <Stack direction="row" spacing={4}>
                    {buttons}
                  </Stack>
                ) : undefined}
                <CardDetailList
                  title="Description"
                  items={[
                    {
                      key: "name",
                      title: "Name",
                      value: category.name,
                    },
                  ]}
                ></CardDetailList>
                <MetaDetailsCard resource={category} />
                {imageModal.open && (
                  <UploadImageDialogue
                    onDelete={handleOnDeleteImage}
                    showDelete={Boolean(category.bannerImageUrl)}
                    onSubmit={handleOnUploadImage}
                    deleteThumbnailSrc={category.bannerImageUrl}
                    {...imageModal}
                  />
                )}
              </Stack>
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
