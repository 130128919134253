import {
  Supplier,
  UpdateSupplierInput,
} from "@towersystems/roam-common/lib/generated-types";
import { toTitleCase } from "@towersystems/roam-common/lib/shared-utils";
import React from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { ProductImageThumbnail } from "../../../../components/ProductImageThumbnail";
import { useModal } from "../../../../utilities/use-modal";
import { Page, Stack } from "../../../../components";
import { UploadImageDialogue } from "../../../../components/UploadImageDialogue";
import { useMutationUpdateSupplier } from "../../utils";
import { Button } from "../../../../components/Button";
import { CardDetailList } from "../../../../components/CardDetailList";
import { MetaDetailsCard } from "../../../../components/MetaDetailsCard";

export const ViewSupplierPage = () => {
  const { data }: any = useLoaderData();
  const navigate = useNavigate();
  const imageModal = useModal();
  const { mutation: updateSupplierMutation } = useMutationUpdateSupplier();

  return (
    <React.Suspense fallback={<div>loading</div>}>
      <Await resolve={data}>
        {([supplier]: [Supplier]) => {
          if (!supplier) {
            return null;
          }

          const handleUpdateSupplier = async (
            input: Partial<UpdateSupplierInput>
          ) => {
            return updateSupplierMutation({
              id: supplier.id,
              ...input,
            })
              .then((res) => {
                navigate(".", { replace: true });
                return true;
              })
              .catch((err) => false);
          };

          const handleOnDeleteImage = async () => {
            return handleUpdateSupplier({
              bannerImage: null,
            });
          };

          const handleOnUploadImage = async (file: File) => {
            return updateSupplierMutation({
              id: supplier.id,
              bannerImage: file,
            })
              .then((res) => {
                navigate(".", { replace: true });
                return true;
              })
              .catch((err) => false);
          };

          let buttons = [];
          if (supplier.active) {
            buttons.push(
              <Button
                key="disable"
                variant="posTile"
                color="warning"
                onClick={() =>
                  handleUpdateSupplier({
                    active: false,
                  })
                }
              >
                Disable
              </Button>
            );
          } else {
            buttons.push(
              <Button
                key="enable"
                variant="posTile"
                color="primary"
                onClick={() =>
                  handleUpdateSupplier({
                    active: true,
                  })
                }
              >
                Enable
              </Button>
            );
          }

          const title = toTitleCase(supplier.name);

          return (
            <Page
              title={title}
              goBack={{ onAction: () => navigate("/suppliers") }}
              adornment={
                <ProductImageThumbnail
                  variant={supplier.bannerImageUrl ? "default" : "upload"}
                  size="large"
                  src={supplier.bannerImageUrl}
                  onClick={imageModal.onOpen}
                />
              }
            >
              <Stack spacing={8}>
                {buttons.length >= 1 ? (
                  <Stack direction="row" spacing={4}>
                    {buttons}
                  </Stack>
                ) : undefined}
                <CardDetailList
                  title="Description"
                  items={[
                    {
                      key: "name",
                      title: "Name",
                      value: supplier.name,
                    },
                  ]}
                ></CardDetailList>
                <MetaDetailsCard resource={supplier} />
                {imageModal.open && (
                  <UploadImageDialogue
                    onDelete={handleOnDeleteImage}
                    showDelete={Boolean(supplier.bannerImageUrl)}
                    onSubmit={handleOnUploadImage}
                    deleteThumbnailSrc={supplier.bannerImageUrl}
                    {...imageModal}
                  />
                )}
              </Stack>
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
