import LockIcon from "@mui/icons-material/Lock";
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";
import { NumericFormat, NumericFormatProps } from "react-number-format";

export type CurrencyFieldProps = NumericFormatProps &
  Pick<TextFieldProps, "label" | "error" | "fullWidth"> & {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    locked?: boolean;
    onLockPress?: () => void;
    endAdornment?: JSX.Element;
  };

/**
 * CurrencyField is a react-hook-form compliant textfield that formats
 * the value as currency
 * @param props - react-hook-form register props
 * @returns
 */
export function CurrencyField(props: CurrencyFieldProps) {
  // const [key, updateKey] = useReducer((x) => x + 1, 0);

  function handleBlur(e: any) {
    if (props.onBlur) {
      props.onBlur(e);
    }
    if (e.target.value === "") {
      props.onValueChange?.(
        { floatValue: 0, formattedValue: "0", value: "0" },
        { source: "event" } as any
      );
    }
  }

  return (
    <NumericFormat
      // key={key}
      allowNegative={false}
      decimalScale={2}
      thousandSeparator
      onBlur={handleBlur}
      {...props}
      customInput={TextField}
    />
  );
}

function TextField(props: any) {
  const { locked, onLockPress, endAdornment, ...rest } = props;

  return (
    <MuiTextField
      {...rest}
      id={rest.name}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        ...(rest.InputProps || {}),
        ...((locked || endAdornment) && {
          endAdornment: (
            <>
              {locked && (
                <IconButton onClick={onLockPress}>
                  <LockIcon />
                </IconButton>
              )}
              {endAdornment}
            </>
          ),
        }),
      }}
    />
  );
}
