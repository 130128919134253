import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { Product } from "@towersystems/roam-common/lib/generated-types";
import { Accordion, Sheet, Stack } from "components";
import { Suspense, useCallback, useEffect } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { useAccordionController } from "../../../../utilities/accordion";
import { useFlashMessages } from "../../../../utilities/flash-messages";
import { productFormValuesToInput } from "../../../../utilities/product/utils";
import { UseModalReturn } from "../../../../utilities/use-modal";
import {
  ProductFormSections,
  ProductFormValues,
  createProductFormSchema,
  defaultCreateProductFormValues,
  defaultLocationInventoryPricingFormValues,
  defaultPricingFields,
  useMutationCreateProduct,
  useProductsRootProvider,
} from "../../utils/products";
import { InventoryForm } from "../InventoryForm";
import { PricingForm } from "../PricingForm";
import { PricingFormProvider } from "../PricingFormProvider";
import { ProductDetailsForm } from "../ProductDetailsForm";

export type CreateProductSheetProps = UseModalReturn & {
  onSubmitSuccess?(product: Product): void;
  onSheetClose?(): void;
};

const defaultAccordionOpenSections = [
  ProductFormSections.DETAILS,
  ProductFormSections.PRICING,
];

const DEFAULT_CREATE_PRODUCT_ERROR_MESSAGE =
  "Unexpected error creating product";

export function CreateProductSheet({
  onSubmitSuccess,
  onSheetClose,
  ...drawerController
}: CreateProductSheetProps) {
  const { taxGroups, locations } = useProductsRootProvider();

  const methods = useForm<ProductFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      ...defaultCreateProductFormValues,
      global: defaultPricingFields,
    },
    resolver: yupResolver(createProductFormSchema),
  });

  const locationsFieldArray = useFieldArray({
    control: methods.control,
    name: "locationInventoryPricing",
    keyName: "localId",
  });

  const { getAccordionState } = useAccordionController(
    defaultAccordionOpenSections
  );

  const { control, handleSubmit } = methods;

  const { createProduct } = useMutationCreateProduct();
  const { showMessage } = useFlashMessages();

  function handleCreateProductError(message?: string) {
    showMessage({
      severity: "error",
      message: message || DEFAULT_CREATE_PRODUCT_ERROR_MESSAGE,
    });
  }

  const handleOnSubmit = useCallback(
    async ({ global, ...formValues }: ProductFormValues) => {
      try {
        const controlledLocationInventoryPricing =
          formValues.locationInventoryPricing?.map((lip) => {
            return {
              ...lip,
              ...(global || {}),
            };
          });

        const controlledFormValues = {
          ...formValues,
          locationInventoryPricing: controlledLocationInventoryPricing,
        };

        const createProductInput =
          productFormValuesToInput(controlledFormValues);

        const result = await createProduct(createProductInput);

        if (result) {
          drawerController.onClose();
          onSubmitSuccess && onSubmitSuccess(result);
        } else {
          handleCreateProductError();
        }
      } catch (error: any) {
        console.error(error);
        handleCreateProductError(error.toString());
      }
    },
    [createProduct]
  );

  function handleClose() {
    drawerController.onClose();
    onSheetClose?.();
  }

  useEffect(() => {
    // initialise locationInventoryPricing field array
    for (const location of locations) {
      const formRetailerLocationKeys = locationsFieldArray.fields.map(
        (field) => field.retailerLocationKey
      );
      if (!formRetailerLocationKeys.includes(location.retailerLocationKey)) {
        locationsFieldArray.append({
          retailerLocationKey: location.retailerLocationKey,
          ...defaultLocationInventoryPricingFormValues,
        });
      }
    }
  }, [locations]);

  const taxable = useWatch({ control, name: "taxable" });
  const categoryId = useWatch({ control, name: "categoryId" });
  const departmentId = useWatch({ control, name: "departmentId" });

  const primaryAction = {
    content: "Add New Product",
    onAction: handleSubmit(handleOnSubmit, console.log),
  };

  const secondaryAction = {
    content: "Cancel",
    onAction: handleClose,
  };

  const commonFormProps = {
    variant: "create" as const,
  };

  return (
    <Sheet
      title="Add Product"
      {...{ primaryAction, secondaryAction }}
      onTransitionEnd={methods.reset}
      {...drawerController}
      onClose={handleClose}
    >
      <FormProvider {...methods}>
        <Stack fullWidth spacing={2}>
          <Accordion {...getAccordionState(ProductFormSections.DETAILS)}>
            <Accordion.Section>
              <ProductDetailsForm {...commonFormProps} />
            </Accordion.Section>
          </Accordion>

          <Accordion
            {...getAccordionState(ProductFormSections.PRICING)}
            title="Pricing (Global)"
          >
            <Accordion.Section>
              <Suspense fallback={<CircularProgress />}>
                <PricingFormProvider {...{ categoryId, departmentId }}>
                  <PricingForm
                    {...commonFormProps}
                    taxable={taxable}
                    taxGroups={taxGroups}
                    locations={locations}
                  />
                </PricingFormProvider>
              </Suspense>
            </Accordion.Section>
          </Accordion>

          <Accordion {...getAccordionState(ProductFormSections.INVENTORY)}>
            <Accordion.Section>
              <InventoryForm {...commonFormProps} locations={locations} />
            </Accordion.Section>
          </Accordion>

          {/* <Accordion {...getAccordionState(ProductFormSections.SERIAL_NUMBERS)}>
            <Accordion.Section>
              <SerialNumberForm {...commonFormProps} locations={locations} />
            </Accordion.Section>
          </Accordion> */}
        </Stack>
      </FormProvider>
    </Sheet>
  );
}
