import { Decimal } from "decimal.js";

/**
 * Predicate with type guard, used to filter out null or undefined values
 * in a filter operation.
 */
export function notNullOrUndefined<T>(val: T | undefined | null): val is T {
  return val !== undefined && val !== null;
}

/**
 * Used in exhaustiveness checks to assert a codepath should never be reached.
 */
export function assertNever(value: never): never {
  throw new Error(
    `Expected never, got ${typeof value} (${JSON.stringify(value)})`
  );
}

/**
 * Simple object check.
 * From https://stackoverflow.com/a/34749873/772859
 */
export function isObject(item: any): item is object {
  return item && typeof item === "object" && !Array.isArray(item);
}

export function isClassInstance(item: any): boolean {
  return isObject(item) && item.constructor.name !== "Object";
}

type NumericPropsOf<T> = {
  [K in keyof T]: T[K] extends number ? K : never;
}[keyof T];

type OnlyNumerics<T> = {
  [K in NumericPropsOf<T>]: T[K];
};

/**
 * Adds up all the values of a given numeric property of a list of objects.
 */
export function summate<T extends OnlyNumerics<T>>(
  items: T[] | undefined | null,
  prop: keyof OnlyNumerics<T>
): number {
  return (items || []).reduce((sum, i) => sum + i[prop], 0);
}

/**
 * Given an array of option arrays `[['red, 'blue'], ['small', 'large']]`, this method returns a new array
 * containing all the combinations of those options:
 *
 * @example
 * ```
 * generateAllCombinations([['red, 'blue'], ['small', 'large']]);
 * // =>
 * // [
 * //  ['red', 'small'],
 * //  ['red', 'large'],
 * //  ['blue', 'small'],
 * //  ['blue', 'large'],
 * // ]
 */
export function generateAllCombinations<T>(
  optionGroups: T[][],
  combination: T[] = [],
  k: number = 0,
  output: T[][] = []
): T[][] {
  if (k === 0) {
    optionGroups = optionGroups.filter((g) => 0 < g.length);
  }
  if (k === optionGroups.length) {
    output.push(combination);
    return [];
  } else {
    // tslint:disable:prefer-for-of
    for (let i = 0; i < optionGroups[k].length; i++) {
      generateAllCombinations(
        optionGroups,
        combination.concat(optionGroups[k][i]),
        k + 1,
        output
      );
    }
    // tslint:enable:prefer-for-of
    return output;
  }
}

/**
 * @description
 * Returns the input field name of a custom field, taking into account that "relation" type custom
 * field inputs are suffixed with "Id" or "Ids".
 */
export function getGraphQlInputName(config: {
  name: string;
  type: string;
  list?: boolean;
}): string {
  if (config.type === "relation") {
    return config.list === true ? `${config.name}Ids` : `${config.name}Id`;
  } else {
    return config.name;
  }
}

/**
 * @description
 * Returns parameters for node shifting, given the current and target positions.
 */
export function getNodeShiftParams(
  currentPosition: number,
  targetPosition: number
) {
  if (currentPosition < targetPosition) {
    return {
      start: currentPosition + 1,
      end: targetPosition,
      offset: -1,
    };
  } else if (currentPosition > targetPosition) {
    return {
      start: targetPosition,
      end: currentPosition - 1,
      offset: 1,
    };
  } else {
    return {
      start: currentPosition,
      end: currentPosition,
      offset: 0,
    };
  }
}

export function formatCurrency(
  amount: number | string,
  country: string,
  currencyCode: string
): string {
  var formatter = new Intl.NumberFormat(country, {
    style: "currency",
    currency: currencyCode,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(
    typeof amount === "string" ? parseFloat(amount) : amount
  );
}

export function formatPercentString(value: string) {
  const result = parseFloat(value);
  if (isNaN(result)) {
    return "-";
  }
  return `${result.toFixed(2)}%`;
}

export interface Payment {
  id: string;
  amount: number;
  paymentTypeId: string;
  paymentDate: Date;
}

// the change payment will always be type cash and have a negative amount
// the change payment will always be the last payment in the list
// as it is created 1 second after the other payments
export const guessChangePayment = (payments: Payment[]) => {
  // filter out the change payment
  // sort the payments by date
  let sortedPayments: Payment[] = payments
    .filter((p) => p.paymentTypeId != "2")
    .sort((p1, p2) => {
      // @ts-ignore
      return p1.paymentDate - p2.paymentDate;
    });

  let maybeChangePayment: Payment | undefined =
    sortedPayments[sortedPayments.length - 1];

  if (
    maybeChangePayment &&
    (maybeChangePayment.paymentTypeId !== "1" || maybeChangePayment.amount > 0)
  ) {
    maybeChangePayment = undefined;
  }

  return maybeChangePayment;
};

export function makeNegative(num: number): number {
  return num > 0 ? -num : num;
}

export function bankersRound(num: number, decimalPlaces: number = 2): number {
  const factor = Math.pow(10, decimalPlaces);
  const roundedNum = Math.round(num * factor);
  const remainder = roundedNum % 2;
  if (remainder === 0) {
    return roundedNum / factor;
  } else if (num > 0) {
    return (roundedNum - 1) / factor;
  } else {
    return (roundedNum + 1) / factor;
  }
}

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { Markup, MarkupType } from "./generated-types";
import { Markups } from "./shared-types";
dayjs.extend(calendar);

export const formatDateToCalendar = (
  dateString: string,
  format = "DD/MM/YYYY"
) => {
  try {
    const date = dayjs(dateString);
    return date.format(format);
  } catch {}
  return "";
};

export const fullDateTimeFormat = "ddd MMM D YYYY h:mm A";

export const fullDateTimeFormatSuccinct = "D MMM YYYY h:mm A";

export const HTML5_FMT = {
  DATETIME_LOCAL: "YYYY-MM-DDTHH:mm", // <input type="datetime-local" />
  DATETIME_LOCAL_SECONDS: "YYYY-MM-DDTHH:mm:ss", // <input type="datetime-local" step="1" />
  DATETIME_LOCAL_MS: "YYYY-MM-DDTHH:mm:ss.SSS", // <input type="datetime-local" step="0.001" />
  DATE: "YYYY-MM-DD", // <input type="date" />
  TIME: "HH:mm", // <input type="time" />
  TIME_SECONDS: "HH:mm:ss", // <input type="time" step="1" />
  TIME_MS: "HH:mm:ss.SSS", // <input type="time" step="0.001" />
  WEEK: "GGGG-[W]WW", // <input type="week" />
  MONTH: "YYYY-MM", // <input type="month" />
};

function roundup(num: number, dec: number = 2) {
  const d = new Decimal(num).toFixed(2);
  return d;
}

export const valueAsMoney = (value: string) => {
  return roundup(Number(value));
};

export const moneyToFormattedString = (
  value?: string | number,
  prefix = "$ ",
  errorText = "-"
): string => {
  value = value || value === 0 ? value?.toString() : "";

  const result = parseFloat(value);

  if (isNaN(result)) {
    return errorText;
  }
  return `${prefix}${valueAsMoney(value)}`;
};

export function getMarkupPercentage(markup?: Markup) {
  return (markup?.markupPercentage || 0) / 100;
}

export function getApplicableMarkupByType(
  markups: Partial<Markups>,
  markupType: MarkupType
) {
  const categoryMarkup = markups.categoryMarkups?.find(
    (markup) => markup.markupType === markupType
  );

  if (categoryMarkup?.markupPercentage) {
    return categoryMarkup;
  }

  const departmentMarkup = markups.departmentMarkups?.find(
    (markup) => markup.markupType === markupType
  );

  if (departmentMarkup?.markupPercentage) {
    return departmentMarkup;
  }
}
