import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Uom } from "@towersystems/roam-common/lib/generated-types";
import {
  CategoryComboBox,
  ChipListInput,
  DepartmentComboBox,
  FormLayout,
  RichTextField,
  SupplierComboBox,
} from "components";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ProductFormValues, useProductFormBarcode } from "../../utils";
import { BarcodeGenerator } from "../BarcodeGenerator";
import { ProductSettingsFields } from "../ProductSettingsFields";

export interface ProductDetailsFormProps {
  variant: "edit" | "create";
}

export function ProductDetailsForm({ variant }: ProductDetailsFormProps) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<ProductFormValues>();

  const { handleBarcodeLookup, loadingLookup } = useProductFormBarcode();

  const stockNumber = useWatch({ control, name: "stockNumber" });
  const departmentId = useWatch({ control, name: "departmentId" });
  const uom = useWatch({ control, name: "UOM" });

  const isLookupDisabled = !stockNumber || loadingLookup;

  return (
    <FormLayout>
      <FormLayout.Group>
        <Controller
          control={control}
          name="stockNumber"
          render={({ field, fieldState: { error } }) => {
            return (
              <TextField
                label="Barcode"
                fullWidth
                value={field.value}
                onChange={field.onChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleBarcodeLookup();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      <BarcodeGenerator onSuccess={field.onChange} />
                      <Tooltip title="Lookup Barcode">
                        <span>
                          <IconButton
                            disabled={isLookupDisabled}
                            onClick={handleBarcodeLookup}
                          >
                            <SearchIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  ),
                }}
                error={Boolean(error)}
                helperText={error?.message}
              />
            );
          }}
        />
      </FormLayout.Group>

      <TextField
        label="Name"
        fullWidth
        {...register("name")}
        error={Boolean(errors.name)}
        helperText={errors.name?.message}
      />

      <Controller
        control={control}
        name="description"
        render={({ field: { onChange, value } }) => (
          <RichTextField
            label="Description"
            value={value}
            onChange={onChange}
          />
        )}
      />

      <FormLayout.Group>
        <TextField
          label="Stock Code"
          fullWidth
          {...register("stockCode")}
          error={Boolean(errors.stockCode)}
          helperText={errors.stockCode?.message}
        />

        <TextField
          label="PLU"
          fullWidth
          {...register("priceLookup")}
          placeholder="Start with a letter..."
          error={Boolean(errors.priceLookup)}
          helperText={errors.priceLookup?.message}
        />
      </FormLayout.Group>

      <FormLayout.Group>
        <Controller
          control={control}
          name="departmentId"
          render={({ field: { name, onChange, value } }) => (
            <DepartmentComboBox
              id={name}
              label="Department"
              value={value}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="categoryId"
          render={({ field: { name, onChange, value } }) => (
            <CategoryComboBox
              id={name}
              label="Category"
              disabled={!departmentId}
              departmentId={departmentId}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </FormLayout.Group>

      <FormLayout.Group>
        <Controller
          name="tags"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <ChipListInput
              id={name}
              label="Tags"
              onChange={onChange}
              value={value}
              placeholder="Separate options with commas"
            />
          )}
        />
      </FormLayout.Group>

      <FormLayout.Group>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextField
            label="Weight"
            fullWidth
            type="number"
            InputProps={{
              style: {
                display: "flex",
                flexGrow: 1,
              },
            }}
            placeholder={`Weight in ${uom}`}
            {...register("weight", {
              valueAsNumber: true,
            })}
            error={Boolean(errors.weight)}
            helperText={errors.weight?.message}
          />
          <Controller
            control={control}
            name="UOM"
            render={({ field: { name, onChange, value } }) => (
              <Autocomplete
                id={name}
                disableClearable
                onChange={(event, newValue) => onChange(newValue)}
                value={value}
                options={Object.values(Uom)}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} label="Units" />
                )}
              />
            )}
          />
        </Box>
        <Box />
      </FormLayout.Group>

      <FormLayout.Group>
        <Controller
          control={control}
          name="supplierId"
          render={({ field: { name, onChange, value } }) => (
            <SupplierComboBox
              id={name}
              label="Supplier"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <TextField
          label="Supplier Stock Code"
          fullWidth
          {...register("supplierStockCode")}
          error={Boolean(errors.supplierStockCode)}
          helperText={errors.supplierStockCode?.message}
        />
      </FormLayout.Group>

      {variant === "create" && <ProductSettingsFields />}
    </FormLayout>
  );
}
