import { gql } from "@apollo/client";
import {
  DepartmentDeepNestingFragment,
  DepartmentNoNestingFragment,
  MarkupDeepNestingFragment,
  PaginatedListMetaNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const QUERY_DEPARTMENT = gql`
  ${DepartmentNoNestingFragment}
  query QueryDpartment($id: ID!) {
    department(id: $id) {
      ...DepartmentNoNesting
    }
  }
`;

export const QUERY_DEPARTMENT_DEEP_NESTING = gql`
  ${MarkupDeepNestingFragment}
  ${DepartmentDeepNestingFragment}
  query QueryDepartmentDeepNesting($id: ID!) {
    department(id: $id) {
      ...DepartmentDeepNesting
    }
  }
`;

export const QUERY_DEPARTMENT_LIST = gql`
  ${PaginatedListMetaNoNestingFragment}
  ${DepartmentNoNestingFragment}
  query QueryDepartmentList($options: DepartmentListOptions) {
    departments(options: $options) {
      items {
        ...DepartmentNoNesting
      }
      meta {
        ...PaginatedListMetaNoNesting
      }
    }
  }
`;

export const QUERY_DEPARTMENT_OPTIONS_LIST = gql`
  ${PaginatedListMetaNoNestingFragment}
  query QueryDepartmentOptionsList($options: DepartmentListOptions) {
    departments(options: $options) {
      items {
        id
        name
      }
      meta {
        ...PaginatedListMetaNoNesting
      }
    }
  }
`;

export const MUTATION_UPDATE_DEPARTMENT = gql`
  ${DepartmentNoNestingFragment}
  mutation UpdateDepartment($input: UpdateDepartmentInput!) {
    updateDepartment(input: $input) {
      ...DepartmentNoNesting
    }
  }
`;
