export function joinArrayWithAnd(array: string[]) {
  if (!array || array.length === 0) {
    return "";
  }

  if (array.length === 1) {
    return array[0];
  }

  const lastElement = array.pop();
  const joined = array.join(", ") + " and " + lastElement;
  return joined;
}
