import { Box, Stack, Typography } from "@mui/material";
import {
  Action,
  DisableableAction,
  LoadableAction,
} from "@towersystems/roam-common/lib/shared-types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "../../../Button/Button";
import React from "react";

interface PrimaryAction extends LoadableAction, DisableableAction {}

export interface HeaderProps {
  title?: string;
  titleDescription?: string | React.ReactNode;
  primaryAction?: PrimaryAction;
  goBack?: Action;
  badges?: React.ReactNode | React.ReactNode[] | null;
  adornment?: React.ReactNode;
}

export const Header = ({
  adornment,
  title,
  primaryAction,
  titleDescription,
  goBack,
  badges,
}: HeaderProps) => {
  const badgesMarkup = badges ? (
    <Box sx={{ display: "flex", alignItems: "center" }}>{badges}</Box>
  ) : undefined;

  const bodyMarkup = titleDescription ? (
    React.isValidElement(titleDescription) ? (
      <Box>{titleDescription}</Box>
    ) : (
      <Typography variant="body1">{titleDescription}</Typography>
    )
  ) : undefined;

  const primaryActionMarkup = primaryAction ? (
    <Button
      color="primary"
      variant="tile"
      size="large"
      onClick={primaryAction.onAction}
      disabled={primaryAction.disabled}
    >
      {primaryAction.content}
    </Button>
  ) : undefined;

  const goBackMarkup = goBack ? (
    <Box>
      <Button
        color="primary"
        onClick={goBack.onAction}
        variant="text"
        compact={true}
        startIcon={<ArrowBackIcon />}
      >
        Back
      </Button>
    </Box>
  ) : undefined;

  const adormentMarkup = adornment ? (
    <Box marginRight={4}>{adornment}</Box>
  ) : undefined;

  return (
    <Box style={{ display: "flex" }} flexDirection={"row"}>
      {adormentMarkup}
      <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography fontWeight={"bold"} variant="h1">
            {title}
          </Typography>
          <Stack direction={"column"} spacing={1}>
            {bodyMarkup}
            {badgesMarkup}
            {goBackMarkup}
          </Stack>
        </Box>
        <Box>{primaryActionMarkup}</Box>
      </Box>
    </Box>
  );
};
