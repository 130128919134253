import { Chip, TextField, useTheme } from "@mui/material";
import { useState } from "react";

export interface ChipListInputProps {
  id?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string[];
  spacing?: number | string;
  onChange?: (value: string[]) => void;
}

export function ChipListInput({
  id,
  label,
  disabled,
  placeholder,
  value,
  spacing: customSpacing,
  onChange,
}: ChipListInputProps) {
  const theme = useTheme();
  const spacing =
    customSpacing === undefined ? theme.spacing(1) : customSpacing;

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(value);
  };

  const handleKeyDown = (e: any) => {
    switch (e.key) {
      case "Enter":
      case ",":
        e.preventDefault();
        e.stopPropagation();
        handleAddChip();
        break;
      case "Backspace":
        handleRemoveChip();
        break;
    }
  };

  function handleAddChip() {
    onChange?.([...(value || []), inputValue]);
    resetInput();
  }

  function handleRemoveChip() {
    if (!inputValue) {
      onChange?.(value?.slice(0, -1) || []);
    }
  }

  function handleRemoveChipByValue(valueToRemove: string) {
    onChange?.(value?.filter((v) => v !== valueToRemove) || []);
  }

  function resetInput() {
    setInputValue("");
  }

  return (
    <TextField
      id={id}
      fullWidth
      label={label}
      value={inputValue}
      disabled={disabled}
      onChange={handleInputChange}
      multiline
      placeholder={value?.length ? undefined : placeholder}
      onKeyDown={handleKeyDown}
      InputProps={{
        inputProps: {
          style: value?.length ? { width: "unset" } : undefined,
        },
        style: {
          flexWrap: "wrap",
          gap: spacing,
        },
        startAdornment: value?.map((item) => (
          <Chip
            key={item}
            label={item}
            onDelete={() => {
              handleRemoveChipByValue(item);
            }}
          />
        )),
      }}
    />
  );
}
