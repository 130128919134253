import { createContext } from "react";
import { EditProductContextType, ProductsRootContextType } from "./types";

export const ProductsRootContext = createContext<
  ProductsRootContextType | undefined
>(undefined);

export const EditProductContext = createContext<
  EditProductContextType | undefined
>(undefined);
