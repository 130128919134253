import ClearIcon from "@mui/icons-material/Clear";
import {
  IconButton,
  Autocomplete as MuiAutocomplete,
  TextField,
} from "@mui/material";
import { useCallback, useState } from "react";
import { ComboBoxBaseProps } from "../../utilities/combo-box";

export type AutocompleteProps = ComboBoxBaseProps & {
  onInputChange: (event: any, newInputValue: string) => void;
  initialised: boolean;
  options: { label: string; value: string }[];
  loading: boolean;
  executedQuery?: string;
};

export const Autocomplete = ({
  id,
  initialised,
  label,
  value,
  disabled,
  options,
  loading,
  onChange,
  onInputChange,
  error,
}: AutocompleteProps) => {
  const [term, setTerm] = useState("");
  const [hovered, setHovered] = useState(false);

  function handleOnInputChange(event: any, newInputValue: string) {
    setTerm(newInputValue);
    onInputChange(event, newInputValue);
  }

  const hover = useCallback(() => {
    setHovered(true);
  }, [setHovered]);

  const unhover = useCallback(() => {
    setHovered(false);
  }, [setHovered]);

  return (
    <MuiAutocomplete
      {...{ id }}
      disabled={disabled}
      disableClearable
      onChange={(event, newValue) => onChange(newValue?.value)}
      filterOptions={(x) => x} // disable filtering
      value={value as any}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return options.find((o) => o.value === option)?.label || "";
        }
        return option.label;
      }}
      options={options}
      loading={loading}
      sx={{ width: "100%" }}
      onInputChange={handleOnInputChange}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            onMouseEnter={hover}
            onMouseLeave={unhover}
            value={term}
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                value: term,
              },
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {!!params.inputProps.value && hovered && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleOnInputChange(null, "");
                        onChange(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              ...(!initialised && { value: "Loading..." }),
            }}
          />
        );
      }}
    />
  );
};
