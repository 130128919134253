import {
  Markup,
  MarkupStyle,
  MarkupType,
} from "@towersystems/roam-common/lib/generated-types";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { merge } from "lodash";
import {
  LockableValue,
  ReactivePricingCalculatorState,
  UseReactivePricingCalculatorReturn,
} from "./types";
import {
  getApplicableMarkupByType,
  getMarkupPercentage,
} from "@towersystems/roam-common/lib/shared-utils";

const defaultState = {
  taxRatePercentage: 0,
  departmentMarkups: [],
  categoryMarkups: [],
  costPriceInc: { value: 0, locked: false },
  costPriceEx: { value: 0, locked: false },
  retailPrice: { value: 0, locked: false },
  tradePrice: { value: 0, locked: false },
  webPrice: { value: 0, locked: false },
};

/**
 * Performs calculations on pricing fields and returns the new state
 * forms can just focus on updating
 */
export const useReactivePricingCalculator = (
  props: Partial<ReactivePricingCalculatorState>
): UseReactivePricingCalculatorReturn => {
  const memodState = useMemo(() => merge({}, defaultState, props), []);

  // refs preferred over state as calculations involve multiple fields
  const stateRef = useRef<ReactivePricingCalculatorState>(memodState);

  function applyInitialisationLogic() {
    if (stateRef.current.costPriceEx) {
      updateCostPriceEx(stateRef.current.costPriceEx);
    } else if (stateRef.current.costPriceInc) {
      updateCostPriceInc(stateRef.current.costPriceInc);
    } else if (stateRef.current.retailPrice) {
      updateRetailPrice(stateRef.current.retailPrice);
    }
  }

  // state is used to trigger a re-render
  const [state, setState] = useState(stateRef.current);

  /**
   * Update the tax rate percentage and recalculate all fields
   * @param newTaxRatePercentage
   */
  function setTaxRatePercentage(newTaxRatePercentage: number) {
    stateRef.current.taxRatePercentage = newTaxRatePercentage;
    applyState();
  }

  function updateTaxRatePercentage(newTaxRatePercentage: number) {
    stateRef.current.taxRatePercentage = newTaxRatePercentage;
    if (!stateRef.current.costPriceInc.locked) {
      stateRef.current.costPriceInc = calculateCostPriceIncFromCostPriceEx();
    }
    if (!stateRef.current.retailPrice.locked) {
      stateRef.current.retailPrice = calculateRetailPriceFromCostPriceInc();
    }
    recalculateTradeAndWebPrice();
    return applyState();
  }

  function updateDepartmentMarkups(newDepartmentMarkups: Markup[]) {
    stateRef.current.departmentMarkups = newDepartmentMarkups;
    if (!stateRef.current.retailPrice.locked) {
      stateRef.current.retailPrice = calculateRetailPriceFromCostPriceInc();
    }
    recalculateTradeAndWebPrice();
    return applyState();
  }

  function updateCategoryMarkups(newCategoryMarkups: Markup[]) {
    stateRef.current.categoryMarkups = newCategoryMarkups;
    if (!stateRef.current.retailPrice.locked) {
      stateRef.current.retailPrice = calculateRetailPriceFromCostPriceInc();
    }
    recalculateTradeAndWebPrice();
    return applyState();
  }

  /**
   * Update the cost price ex and recalculate all fields accordingly
   * @param newCostPriceEx
   */
  function updateCostPriceEx(newCostPriceEx: Partial<LockableValue> | number) {
    stateRef.current.costPriceEx = {
      ...stateRef.current.costPriceEx,
      ...toPartialLockableValue(newCostPriceEx),
    };

    stateRef.current.costPriceInc = calculateCostPriceIncFromCostPriceEx();

    if (!stateRef.current.retailPrice.locked) {
      stateRef.current.retailPrice = calculateRetailPriceFromCostPriceInc();
    }
    recalculateTradeAndWebPrice();

    return applyState();
  }

  /**
   * Update the cost price inc and recalculate all fields accordingly
   * @param newCostPriceInc
   */
  function updateCostPriceInc(
    newCostPriceInc: Partial<LockableValue> | number
  ) {
    stateRef.current.costPriceInc = {
      ...stateRef.current.costPriceInc,
      ...toPartialLockableValue(newCostPriceInc),
    };

    stateRef.current.costPriceEx = calculateCostPriceExFromCostPriceInc();

    if (!stateRef.current.retailPrice.locked) {
      stateRef.current.retailPrice = calculateRetailPriceFromCostPriceInc();
    }
    recalculateTradeAndWebPrice();
    return applyState();
  }

  /**
   * Update the retail price and recalculate all fields accordingly
   * @param newRetailPrice
   */
  function updateRetailPrice(newRetailPrice: Partial<LockableValue> | number) {
    stateRef.current.retailPrice = {
      ...stateRef.current.retailPrice,
      ...toPartialLockableValue(newRetailPrice),
    };
    if (!stateRef.current.costPriceInc.locked) {
      stateRef.current.costPriceInc = calculateCostPriceIncFromRetailPrice();
    }

    if (!stateRef.current.costPriceEx.locked) {
      stateRef.current.costPriceEx = calculateCostPriceExFromCostPriceInc();
    }
    recalculateTradeAndWebPrice();
    return applyState();
  }

  function updateTradePrice(newTradePrice: Partial<LockableValue> | number) {
    stateRef.current.tradePrice = {
      ...stateRef.current.tradePrice,
      ...toPartialLockableValue(newTradePrice),
    };
    return applyState();
  }

  function updateWebPrice(newWebPrice: Partial<LockableValue> | number) {
    stateRef.current.webPrice = {
      ...stateRef.current.webPrice,
      ...toPartialLockableValue(newWebPrice),
    };
    return applyState();
  }

  function recalculateTradeAndWebPrice() {
    if (!stateRef.current.tradePrice.locked) {
      stateRef.current.tradePrice = {
        locked: false,
        value: getMarkedUpPrice(MarkupType.TRADE),
      };
    }
    if (!stateRef.current.webPrice.locked) {
      stateRef.current.webPrice = {
        locked: false,
        value: getMarkedUpPrice(MarkupType.WEB),
      };
    }
  }

  function getMarkedUpPrice(markupType: MarkupType) {
    const markups = {
      departmentMarkups: stateRef.current.departmentMarkups,
      categoryMarkups: stateRef.current.categoryMarkups,
    };

    const markup = getApplicableMarkupByType(markups, markupType);

    if (!markup || !markup.markupPercentage) {
      return stateRef.current.retailPrice.value;
    }

    if (markup?.markupStyle === MarkupStyle.OFF_RETAIL) {
      return (
        stateRef.current.retailPrice.value -
        stateRef.current.retailPrice.value * (markup.markupPercentage / 100)
      );
    } else {
      return (
        stateRef.current.costPriceInc.value +
        stateRef.current.costPriceInc.value * (markup.markupPercentage / 100)
      );
    }
  }

  function calculateCostPriceIncFromRetailPrice() {
    const markups = {
      departmentMarkups: stateRef.current.departmentMarkups,
      categoryMarkups: stateRef.current.categoryMarkups,
    };

    return {
      ...stateRef.current.costPriceInc,
      value:
        stateRef.current.retailPrice.value /
        (1 +
          getMarkupPercentage(
            getApplicableMarkupByType(markups, MarkupType.RETAIL)
          )),
    };
  }

  function calculateCostPriceIncFromCostPriceEx() {
    return {
      ...stateRef.current.costPriceInc,
      value:
        stateRef.current.costPriceEx.value +
        stateRef.current.costPriceEx.value *
          (stateRef.current.taxRatePercentage / 100),
    };
  }

  function calculateRetailPriceFromCostPriceInc() {
    const markups = {
      departmentMarkups: stateRef.current.departmentMarkups,
      categoryMarkups: stateRef.current.categoryMarkups,
    };

    return {
      ...stateRef.current.retailPrice,
      value:
        stateRef.current.costPriceInc.value +
        stateRef.current.costPriceInc.value *
          getMarkupPercentage(
            getApplicableMarkupByType(markups, MarkupType.RETAIL)
          ),
    };
  }

  function calculateCostPriceExFromCostPriceInc() {
    return {
      ...stateRef.current.costPriceEx,
      value:
        stateRef.current.costPriceInc.value /
        (1 + stateRef.current.taxRatePercentage / 100),
    };
  }

  function applyState() {
    const frozenState = JSON.parse(JSON.stringify(stateRef.current));
    setState(frozenState);
    return frozenState;
  }

  function getValues() {
    return JSON.parse(JSON.stringify(stateRef.current));
  }

  function resetLocks(newLockState: boolean) {
    stateRef.current.costPriceInc.locked = newLockState;
    stateRef.current.costPriceEx.locked = newLockState;
    stateRef.current.retailPrice.locked = newLockState;
    stateRef.current.tradePrice.locked = newLockState;
    stateRef.current.webPrice.locked = newLockState;
  }

  function reset(newValues: Partial<ReactivePricingCalculatorState>) {
    stateRef.current = merge({}, defaultState, newValues);

    applyInitialisationLogic();
    return applyState();
  }

  useLayoutEffect(() => {
    reset(props);
  }, []);

  return {
    state,
    getValues,
    reset,
    actions: {
      setTaxRatePercentage,
      resetLocks,
      updateTaxRatePercentage,
      updateDepartmentMarkups,
      updateCategoryMarkups,
      updateCostPriceEx,
      updateCostPriceInc,
      updateRetailPrice,
      updateTradePrice,
      updateWebPrice,
    },
  };
};

function toPartialLockableValue(
  valueOrLockableValue: Partial<LockableValue> | number
): Partial<LockableValue> {
  if (typeof valueOrLockableValue === "number") {
    return { value: valueOrLockableValue };
  }
  return valueOrLockableValue;
}
