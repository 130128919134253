import { Box, InputAdornment, Paper, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Page } from "../../../../components";
import { AllSuppliersTabView } from "./components";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useSearch } from "../../../../utilities/search/hooks";
import { SearchContext } from "../../../../utilities/search/context";
import { InactiveSuppliersTabView } from "./components/InactiveSuppliersTabView";

export const ListSuppliersPage = () => {
  const search = useSearch();

  const toolbarMarkup = (
    <Box>
      <TextField
        value={search.query}
        onChange={(e) => search.setQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search suppliers..."
        fullWidth
        className="CardSearchInput"
        variant="standard"
      ></TextField>
    </Box>
  );

  return (
    <SearchContext.Provider value={search}>
      <Page title="Suppliers" titleDescription="Manage your suppliers.">
        <Paper variant="outlined">
          {toolbarMarkup}
          <Tabs>
            <TabList>
              <Tab>All Suppliers</Tab>
              <Tab>Inactive Suppliers</Tab>
            </TabList>
            <TabPanel>
              <AllSuppliersTabView />
            </TabPanel>
            <TabPanel>
              <InactiveSuppliersTabView />
            </TabPanel>
          </Tabs>
        </Paper>
      </Page>
    </SearchContext.Provider>
  );
};
